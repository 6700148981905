<template>
  <div>
    <div class="column align-content-center mb-2 chat-list-table">
      <div class="col-sm-3 text-left">
        <strong>거래 내역 *</strong>
      </div>
      <div class="card-body">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>거래번호</th>
              <th>코인명</th>
              <th>작성자</th>
              <th>거래 신청자</th>
              <th>거래 일시</th>
              <th>거래 상태</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(trading, index) in tradings" :key="index">
              <td>{{ trading.tradeIdx }}</td>
              <td>{{ trading.coin.includes('UNLISTED') ? `${trading.coin.split('-')[1]}(직접거래)` : trading.coin }}</td>
              <td>{{ trading.writerNickname }}</td>
              <td>{{ trading.applicantNickname }}</td>
              <td>{{ trading.createdAt | moment('YYYY-MM-DD HH:mm:ss') }}</td>
              <td>{{ E_P2PState[trading.tradeState] }}</td>
            </tr>
            <tr v-if="tradings.length === 0">
              <td colspan="7" class="text-center">거래 내역이 없습니다.</td>
            </tr>
          </tbody>
        </table>
        <div class="mt-2">
          <paginationTrading ref="PaginationTrading" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PaginationTrading from "../common/PaginationTrading";

export default {
  name: "OtcSuggestionTrading",
  components: {
    PaginationTrading,
  },
  data() {
    return {
      idx: 0,
      tradings: [],
      size: '10',
      tradingPage: 1,
      tradingTotal: 0,
    }
  },
  created() {
    this.idx = this.$route.params.idx



    let page = this.$route.query.tradingPage
    if(typeof(page) === 'undefined') {
      page = 1
    }

    let size = this.$route.query.tradingSize
    if(typeof(size) !== 'undefined') {
      this.size = size
    }

    this.getTradingList(this.tradingPage)
  },
  methods: {
    getTradingList(tradingPage) { // 거래 내역 받아오기
      let params = new URLSearchParams()
      if(typeof tradingPage === 'undefined') tradingPage = 1
      params.append('page', tradingPage)
      params.append('limit', this.size)

      this.$get(`${this.$OTC_SUGGESTION_DETAIL_TRADING_LIST.replace('?', this.idx)}?${params.toString()}`, this.$options.name + '_getTradingList', (result) => {
        this.tradingPage = tradingPage
        this.tradingTotal = result.total

        try {
          this.$refs.PaginationTrading.init(this.$options.name, {}, Number(this.tradingPage), this.tradingTotal, Number(this.size))
        } catch (e) {
          console.log(e)
        }

        this.tradings = result.data
      }, (result) => {
        this.httpError(result)
      })
    },
  },
  watch: {
    '$route.query.tradingPage'() {
      this.getTradingList(this.$route.query.tradingPage)
    }
  }
}
</script>

<style>
.chat-list-table {
  margin-left: auto;
  margin-right: auto;
  width: 65%;
}


</style>

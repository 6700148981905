<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">도구</h4>
            <span> 사이트를 관리하기 위한 각종 도구를 제공합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">데이터 관리 > 지갑 거래내역</h5>
                <div class="row float-right" id="dataTableButtons">
                </div>
              </div><!-- /.card-header -->
              <div class="card-body">
                <table id="dataTable" class="table table-bordered">
                  <thead>
                  <tr>
                    <th>관리번호</th>
                    <th>구분</th>
                    <th>코인</th>
                    <th>트랜젝션 발생자</th>
                    <th>수량</th>
                    <th>수수료</th>
                    <th>내부전송 여부</th>
                    <th>내부전송 상대방 아이디</th>
                    <th>입출금 일자</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, index) in items" :key="index">
                    <td>{{ item.transferIdx }}</td>
                    <td>{{ item.isOut === true ? '출금' : '입금' }}</td>
                    <td>{{ item.primary }}</td>
                    <td>{{ item.senderEmail }}</td>
                    <td>{{ item.amount }}</td>
                    <td>{{ item.feeAmount }}</td>
                    <td>{{ item.isInternal === true ? '내부전송' : '외부전송' }}</td>
                    <td v-if="item.takerEmail">{{ item.takerEmail }}</td>
                    <td v-else>{{ `-` }}</td>
                    <td>{{ item.createdAt | moment('YYYY-MM-DD HH:mm:ss') }}</td>
                  </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix">
                <pagination ref="Pagination"></pagination>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>
import Pagination from "../../common/Pagination";

export default {
  name: 'DataWalletHistory',
  components: {
    Pagination,
  },
  data() {
    return {
      size: '10',
      page: 1,
      total: 0,
      selectedPrimary: '',
      selectedIsOut: '',
      selectedCriteria: '',
      selectedKeyword: '',
      selectedStart: '',
      selectedEnd: '',

      //data
      items: [],
      isInternal: '',
    }
  },
  created() {
    let page = this.$route.query.page
    if(typeof(page) === 'undefined') {
      page = 1
    }

    let size = this.$route.query.size
    if(typeof(size) !== 'undefined') {
      this.size = size
    }

    this.selectedPrimary = this.$route.params.primary
    this.selectedIsOut = this.$route.params.isOut
    this.selectedCriteria = this.$route.params.criteria
    this.selectedKeyword = this.$route.params.keyword
    this.selectedStart = new Date(this.$route.params.start).getTime()
    this.selectedEnd = new Date(this.$route.params.end).getTime()

    this.getWalletHistory(page)
  },
  methods: {
    getWalletHistory(page) {
      let params = new URLSearchParams()
      params.append('primary', this.selectedPrimary)
      params.append('isOut', this.selectedIsOut)
      params.append('criteria', this.selectedCriteria)
      params.append('keyword', this.selectedKeyword)
      params.append('start', this.selectedStart)
      params.append('end', this.selectedEnd)
      if(typeof page === 'undefined') page = 1
      params.append('page', page)
      params.append('limit', this.size)

      this.$get(this.$TOOLS_WALLET_LOG + '?' + params.toString(), this.$options.name + '_getWalletHistory', (result) => {
        this.page = page
        this.total = result.total

        try {
          this.$refs.Pagination.init(this.$options.name, {}, Number(this.page), this.total, Number(this.size))
        } catch (e) {
          console.log(e)
        }
        this.items = result.data

        this.$createTables()
        this.$destroyTables()
      }, (result) => {
        this.httpError(result)
      })
    },
  },
  watch: {
    'this.items'() {
      this.$createTables()
    },
    '$route.query.page'() {
      this.getWalletHistory(this.$route.query.page)
    },
  }
}
</script>

<style>
</style>

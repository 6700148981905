import big from "big.js";

export default Object.freeze({
    install(Vue) {
        Vue.prototype.$big = big;

        // 토스트 스타일 알림
        Vue.prototype.notify = function (type, msg, is_alert=false) {
            if(is_alert) {
                alert(msg)
            } else {
                if(type === 'error') {
                    window.$('#alert-wrap').append("<div class=\"alert-content\"><i class=\"icon-alert-fail\"></i> <span>"+msg+"</span></div>")
                } else {
                    window.$('#alert-wrap').append("<div class=\"alert-content\"><i class=\"icon-check\"></i> <span>"+msg+"</span></div>")
                }
                setTimeout(function() {
                    window.$('#alert-wrap div:first-child').remove()
                }, 3000)
            }
        }

        // 기본 HTTP 에러 처리
        Vue.prototype.httpError = function (data) {
            console.log(data)
            if(data.state === 401) {
                this.notify('error', data.data.message, false)

                this.$router.push({ name: 'Login' }).catch(() => {})
            } else {
                if(data.data.message !== '') {
                    this.notify('error', data.data.message, false)
                }

                for(let i=0; i<data.data.error.length; i++) {
                    if(data.data.error[i]) {
                        this.notify('error', data.data.error[i].message, false)
                    }
                }
            }
        }

        // 토큰 갱신
        Vue.prototype.refreshTokenRate = 5*60*1000
        Vue.prototype.refreshToken = function () {
            let refreshTokan = sessionStorage.getItem('refresh_token')
            if(!refreshTokan) {
                return
            }

            let formData = new FormData()
            formData.append('refreshToken', refreshTokan)

            this.$put(this.$REFRESHTOKEN, 'refreshToken', formData, (result) => {
                sessionStorage.setItem('token', result.data.accessToken)
                sessionStorage.setItem('refresh_token', result.data.refreshToken)

                setTimeout(() => {
                    this.refreshToken()
                }, this.refreshTokenRate)
            }, (result) => {
                this.httpError(result)
            })
        }

        Vue.prototype.getFormatData = function (data, result) {
            for (const key of Object.keys(data)) {
                if(typeof result.data[key] === 'number') {
                    data[key] = result.data[key].toString()
                } else if(typeof result.data[key] === 'boolean') {
                    data[key] = result.data[key] ? true : false
                } else if(typeof result.data[key] === 'undefined') {
                    data[key] = ''
                } else if(result.data[key] === null) {
                    data[key] = ''
                } else {
                    data[key] = result.data[key]
                }
            }
        }

        // img
        Vue.prototype.$fileDownload = function (response){
            let filename = response.headers['content-disposition'].split('filename=')[1].split('"')[1]
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', filename)
            link.innerText = '다운로드'
            return [link, filename]
        }

        Vue.prototype.$downloadExcelData = function (tableId, filename) {
            if (filename == null || typeof filename == undefined)
                filename = tableId;
            filename += ".csv";

            let BOM = "\uFEFF";

            let table = document.getElementById(tableId);
            let csvString = BOM;
            for (let rowCnt = 0; rowCnt < table.rows.length; rowCnt++) {
                let rowData = table.rows[rowCnt].cells;
                for (let colCnt = 0; colCnt < rowData.length; colCnt++) {
                    let columnData = rowData[colCnt].textContent;
                    if (columnData == null || columnData.length === 0) {
                        if(colCnt === 0) {
                            continue
                        } else {
                            columnData = "".replace(/"/g, '""');
                        }
                        // columnData = "".replace(/"/g, '""');
                    }
                    else {
                        columnData = columnData.toString().replace(/"/g, '""'); // escape double quotes
                    }
                    csvString = csvString + '"' + columnData + '",';
                }
                csvString = csvString.substring(0, csvString.length - 1);
                csvString = csvString + "\r\n";
            }
            csvString = csvString.substring(0, csvString.length - 1);

            // IE 10, 11, Edge Run
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                let blob = new Blob([decodeURIComponent(csvString)], {
                    type: 'text/csv;charset=utf8'
                });
                window.navigator.msSaveOrOpenBlob(blob, filename);
            } else if (window.Blob && window.URL) {
                // HTML5 Blob
                let blob = new Blob([csvString], { type: 'text/csv;charset=utf8' });
                let csvUrl = URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.setAttribute('style', 'display:none');
                a.setAttribute('href', csvUrl);
                a.setAttribute('download', filename);
                document.body.appendChild(a);
                a.click()
                a.remove();
            } else {
                // Data URI
                let csvData = 'data:application/csv;charset=utf-8,' + encodeURIComponent(csvString);
                let blob = new Blob([csvString], { type: 'text/csv;charset=utf8' });
                let csvUrl = URL.createObjectURL(blob);
                console.log(csvUrl)
                let a = document.createElement('a');
                a.setAttribute('style', 'display:none');
                a.setAttribute('target', '_blank');
                a.setAttribute('href', csvData);
                a.setAttribute('download', filename);
                document.body.appendChild(a);
                a.click()
                a.remove();
            }
        }

        Vue.prototype.$createTables = function () {
            this.$nextTick(() => {
                window.$("#dataTable").DataTable({
                    "responsive": true,
                    "lengthChange": true,
                    "autoWidth": false,
                    "searching": false,
                    "ordering": true,
                    "paging": false,
                    "buttons": [
                        "copy",
                        {
                            extend : 'csv',
                            charset : 'UTF-8',
                            bom : true,
                        },
                        "excel",
                        "pdf",
                        "print",
                        "colvis"
                    ]
                }).buttons().container().appendTo('#dataTableButtons')
            })
        }

        Vue.prototype.$destroyTables = function () {
            window.$("#dataTable").DataTable().destroy()
        }

        Vue.prototype.$getMaxDate = function (_startDate) {
            if (_startDate === '' || typeof _startDate === 'undefined') return ''

            let startDate = new Date(_startDate)
            let maxDate = this.$moment(startDate).add(3, 'month').format('YYYY-MM-DD')
            return maxDate
        }

        Vue.prototype.$watchStartDate = function (_startDate) {
            let startTmpDate = ''
            if(_startDate) {
                let date = new Date(_startDate)
                date.setHours(0)
                date.setMinutes(0)
                date.setSeconds(0)
                startTmpDate = +date
            } else {
                startTmpDate = ''
            }
            this.$getMaxDate(_startDate)
            return startTmpDate
        }

        Vue.prototype.$watchEndDate = function (_endDate) {
            let endTmpDate = ''
            if(_endDate) {
                let date = new Date(_endDate)
                date.setHours(23)
                date.setMinutes(59)
                date.setSeconds(59)
                endTmpDate = +date
            } else {
                endTmpDate = ''
            }
            return endTmpDate
        }

        Vue.prototype.$roundToDecimal = function (number, decimal) {
            if(number === '') number = 0
            return +(Math.round(`${number}` + `e+${decimal}`)  + `e-${decimal}`)
        }

        Vue.prototype.E_ProcessState = {
            PROCESSING: '진행중',
            PENDING: '대기',
            DONE: '완료',
            DECLINED: '거절',
            ERROR: '실패',
        }

        Vue.prototype.E_TransferType = {
            HOUSE_IN: '내부입금',
            HOUSE_OUT: '내부출금',
            TX_IN: '외부입금',
            TX_OUT: '외부출금',
            ESCROW_FEE: '에스크로',
            ESCROW_IN: '에스크로 입금',
            ESCROW_OUT: '에스크로 출금',
            ADMIN_IN: '내부 입금',
            ADMIN_OUT: '출금',

        }

        Vue.prototype.E_P2PState = {
            WAITING: '거래대기',
            TIMEOUT: '시간초과',
            DEPOSITED: '입금완료',
            DELAYED: '거래지연',
            TRADED: '거래완료',

            OPEN: '거래시작',
            ACCEPT_READY: '입금대기',
            TRADING: '거래중',
            SOLDOUT: '거래완료',
            CANCEL: '거래취소',
            DELETED: '거래삭제',
            DECLINED: '거래거절',
            // CHATTING: '채팅중',
        }
    }
})

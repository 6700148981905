<template>
  <div class="m-3">
    <div class="card-header border-bottom-0">
      <h5 class="card-title mr-3 mt-2">마켓 기록</h5>
      <button class="badge btn btn-primary ml-2" @click="goMore()">더보기</button>
    </div><!-- /.card-header -->
    <div class="row align-items-center">
      <table class="table table-bordered">
        <thead>
        <tr>
          <th>마켓</th>
          <th>구분</th>
          <th>체결가</th>
          <th>수량</th>
          <th>수수료</th>
          <th>일자</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>{{ item.primaryTicker }}-{{ item.secondaryTicker }}</td>
          <td>{{ item.buy ? '매수' : '매도' }}</td>
          <td>{{ item.price }}</td>
          <td>{{ item.amount }}</td>
          <td>{{ item.feeAmount }}</td>
          <td>{{ item.createdAt | moment('YYYY-MM-DD HH:mm:ss') }}</td>
        </tr>
        <tr v-if="items.length === 0">
          <td colspan="6" style="text-align: center;">
            <span v-if="isLoading">{{ this.startDate }} ~ {{ this.endDate }} / 내역을 가져오는 중 입니다...</span>
            <span v-else>{{ this.startDate }} ~ {{ this.endDate }} / 내역이 없습니다.</span>
          </td>
        </tr>
        <tr v-if="items.length === 0">
          <td colspan="6" style="text-align: center;">
            <button type="button" @click="getData()" class="btn btn-primary" :class="{disabled: isLoading}">이전 데이터 가져오기</button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserDetailMarketHistory",
  props: [
    'userIdx',
  ],

  data() {
    return {
      items: [],
      startDate: '',
      endDate: '',
      startTmpDate: '',
      endTmpDate: '',
      timestamp: new Date().getTime(),
      isLoading: false,
    }
  },
  created() {
    this.getData()
  },

  methods: {
    getTodayDates() {
      this.startDate = this.$moment(this.timestamp).subtract(3, 'day').format('YYYY-MM-DD')
      this.endDate = this.$moment(this.timestamp).format('YYYY-MM-DD')

      let startDate = new Date(this.startDate)
      startDate.setHours(0)
      startDate.setMinutes(0)
      startDate.setSeconds(0)
      this.startTmpDate = +startDate

      let endDate = new Date(this.endDate)
      endDate.setHours(23)
      endDate.setMinutes(59)
      endDate.setSeconds(59)
      this.endTmpDate = +endDate
    },
    getData() {
      if(this.isLoading === true) {
        return
      }
      this.isLoading = true

      this.getTodayDates()

      let params = new URLSearchParams()
      params.append('page', 1)
      params.append('limit', 10)
      params.append('isBuy', '')
      params.append('primary', '')
      params.append('secondary', '')
      params.append('criteria', "user_idx")
      params.append('keyword', this.userIdx)
      params.append('start', this.startTmpDate)
      params.append('end', this.endTmpDate)

      this.$get(this.$MARKET_TRADE_HISTORY + '?' + params.toString(), this.$options.name + '_getList', (result) => {
        this.items = result.data

        this.timestamp = new Date(this.$moment(this.timestamp).subtract(3, 'day').format('YYYY-MM-DD')).getTime()

        this.isLoading = false
      }, (result) => {
        this.httpError(result)

        this.isLoading = false
      })
    },
    goMore() {
      this.$router.push({
        name: 'MarketConcludeHistory',
        query: {
          st: 'user_idx',
          sk: this.userIdx
        }
      })
    },
  }
}
</script>

<style scoped>

</style>

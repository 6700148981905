<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">도구</h4>
            <span>사이트를 관리하기 위한 각종 도구를 제공합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">정산</h5>
                <div class="row">
                  <select class="form-control w-auto ml-auto mr-1" v-model="selectedCurrency">
                    <option v-for="(currency, index) in currencyList" :key="index" :value="currency.ticker">{{ currency.name }}</option>
                  </select>
                  <select class="form-control w-auto mr-1" v-model="selectedYear">
                    <option v-for="(year, index) in yearList" :key="index" :value="year">{{ year }}</option>
                  </select>
                  <select class="form-control w-auto mr-1" v-model="selectedAgency">
                    <option value="">대리점별 보기</option>
                    <option v-for="(agency, index) in agencyList" :key="index" :value="agency.recruitingCode">{{ agency.nickName }} ({{ agency.recruitingCode }})</option>
                  </select>
                  <button class="mr-auto btn btn-warning" @click="getList(page)">계산</button>
                </div>
              </div><!-- /.card-header -->
              <div class="card-body">
                <table class="table table-bordered">
                  <thead>
                  <tr>
                    <th rowspan="2">통화</th>
                    <th rowspan="2">월</th>
                    <th colspan="5">수익</th>
                    <th rowspan="2" colspan="2">도구</th>
                  </tr>
                  <tr>
                    <th>마켓 수수료 수익</th>
                    <th>전송 수수료</th>
<!--                    <th>수익 합계</th>-->
                    <th>출금액</th>
                    <th colspan="2">총계</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td rowspan="14">{{ nowCurrency }}</td>
                  </tr>
                  <tr v-for="(item, index) in items" :key="index">
                    <td>{{ item.month }}</td>
                    <td>{{ item.marketFee }}</td>
                    <td>{{ item.transferFee }}</td>
<!--                    <td>{{ item.marketFee + item.transferFee }}</td>--> <!-- 수익 합계 -->
                    <td>{{ item.adminWithdraw }}</td> <!-- 출금액 -->
                    <td colspan="2">{{ item.marketFee - item.adminWithdraw }}</td> <!-- 총계 -->
                    <td class="text-center">
                      <span
                        v-if="searchYear < currentYear
                        ? selectedMonth === ''
                        : currentMonth > item.month && selectedAgency === ''"
                        class="badge btn btn-danger"
                        data-toggle="modal"
                        data-target="#modal-MarketSettleWithdrawPopup"
                        @click="goWithdrawPopup(item.month, (item.marketFee - item.adminWithdraw))">출금
                      </span>
                      <span v-else>-</span>
                    </td>
                    <td class="text-center">
                      <span class="badge btn btn-primary mr-1" @click="goDetailPage(selectedCurrency, selectedYear, item.month)">월별 상세</span>
                      <span class="badge btn btn-success" @click="goExcelPage(selectedCurrency, selectedYear, item.month)">excel</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <table class="mt-5 table table-bordered">
                  <tbody>
                  <tr>
                    <th>API 잔액</th>
                    <td>{{ data.result }}</td>
                    <th>캐시 잔액</th>
                    <td>{{ data.walletTotal }}</td>
                    <th>DB 잔액</th>
                    <td>{{ $big(data.deposit).minus($big(data.withdraw)) }}</td>
                    <th>오차</th>
                    <td>{{ $big(data.result).minus($big(data.deposit).minus($big(data.withdraw))) }}</td>
                    <td>
                      <button type="button" class="w-100 btn btn-warning" @click="getData()">새로고침</button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
    <MarketSettleWithdrawPopup
      v-if="showPopup.MarketSettleWithdrawPopup"
      :amount="withdrawAmount"
      :ticker="selectedCurrency"
      :year="selectedYear"
      :month="selectedMonth"
      :recruitingCode="selectedAgency"
      @close="closePopup()"
    />
  </div>
</template>

<script>
import MarketSettleWithdrawPopup from "@/components/setting/marketSettle/marketSettleSub/MarketSettleWithdrawPopup"

import Vue from 'vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
Vue.use(Loading)
import Loader from '@/components/Loader'

export default {
  name: 'MarketSettleList',
  components: {
    MarketSettleWithdrawPopup
  },
  data() {
    return {
      nowCurrency: '',
      //search filter
      agencyList: [],
      currencyList: [],
      yearList: [],

      selectedAgency: '',
      selectedCurrency: 'BTC',
      selectedYear: '2023',
      selectedMonth: '',
      size: '10',
      page: 0,
      total: 0,

      searchCurrency: 'BTC',
      searchYear: '2023',

      //popup
      withdrawAmount: '',
      showPopup: {
        MarketSettleWithdrawPopup: false,
      },

      //data
      items: [],
      data: {
        deposit: 0,
        result: 0,
        walletTotal: 0,
        withdraw: 0,
      },

      loader: null,
      currentYear: '',
      currentMonth: '',
    }
  },

  beforeDestroy() {
    this.loader.hide()
  },

  created() {
    this.$root.$emit(this.$EVENT_LNB_CHANGE, 'setting', this.$options.name)

    let page = this.$route.query.page
    if(typeof(page) === 'undefined') {
      page = 1
    }

    let size = this.$route.query.size
    if(typeof(size) !== 'undefined') {
      this.size = size
    }

    let ag = this.$route.query.ag
    if(typeof(ag) !== 'undefined') {
      this.selectedAgency = ag
    }

    let cu = this.$route.query.cu
    if(typeof(cu) !== 'undefined') {
      this.selectedCurrency = cu
    }

    let yr = this.$route.query.yr
    if(typeof(yr) !== 'undefined') {
      this.selectedYear = yr
    }

    this.getCurrentDate()

    this.getFilterData(() => {
      //this.getList(page)
    })
  },
  methods: {
    getFilterData(callback) {
      let startYear = 2021
      for (let i = startYear; i <= (new Date()).getFullYear(); i++) {
        this.yearList.push(i)
      }

      this.$get(this.$PROFIT_FILTER, this.$options.name + '_getFilterData', (result) => {
        this.agencyList = result.data.agency
        this.currencyList = result.data.currency

        callback()
      }, (result) => {
        this.httpError(result)
      })
    },

    getList(page, useCache = false) {
      this.loader = this.$loading.show({
        backgroundColor: '#000000',
        opacity: 0.6,
        zIndex: 999,
      }, {
        default: this.$createElement(Loader)
      })

      let cache = null
      if(useCache === true && this.selectedAgency === '') {
        cache = localStorage.getItem('adminProfitList_' + this.selectedCurrency + '_' + this.selectedYear)
        cache = JSON.parse(cache)
      }

      if(cache === null) {
        let params = new URLSearchParams()
        params.append('page', page)
        params.append('limit', this.size)
        params.append('recruitingCode', this.selectedAgency)
        params.append('ticker', this.selectedCurrency)
        params.append('year', this.selectedYear)

        this.$get(this.$PROFIT + '?' + params.toString(), this.$options.name + '_getList', (result) => {
          this.page = page
          this.total = result.total

          this.nowCurrency = result.data.ticker
          this.items = result.data.monthRows

          if(this.searchCurrency !== this.nowCurrency) {
            this.data.walletTotal = 0
            this.data.result = 0
            this.data.deposit = 0
            this.data.withdraw = 0
          }

          this.searchCurrency = result.data.ticker
          this.searchYear = this.selectedYear

          if(this.selectedAgency === '') {
            localStorage.setItem('adminProfitList_' + this.selectedCurrency + '_' + this.selectedYear, JSON.stringify(result))
          }

          this.loader.hide()
        }, () => {
          this.loader.hide()
          this.notify('error', '데이터 가져오기 실패')
        })
      } else {
        this.page = page
        this.total = cache.total

        this.nowCurrency = cache.data.ticker
        this.items = cache.data.monthRows

        if(this.searchCurrency !== this.nowCurrency) {
          this.data.walletTotal = 0
          this.data.result = 0
        }

        this.searchCurrency = cache.data.ticker
        this.searchYear = this.selectedYear

        this.loader.hide()
      }
    },
    getData() {
      this.loader = this.$loading.show({
        backgroundColor: '#000000',
        opacity: 0.6,
        zIndex: 999,
      }, {
        default: this.$createElement(Loader)
      })

      let coinIdx = 0
      for (let i = 0; i < this.currencyList.length; i++) {
        if(this.currencyList[i].ticker === this.selectedCurrency) {
          coinIdx = this.currencyList[i].idx
        }
      }

      if(coinIdx === 0) {
        return
      }

      this.$get(this.$COIN_BALANCE.replace('?', coinIdx), this.$options.name + '_getData', (result) => {
        this.getFormatData(this.data, result)
        this.loader.hide()
      }, () => {
        this.loader.hide()
        this.notify('error', '데이터 가져오기 실패')
      })
    },
    getCurrentDate() {
      let date = new Date()
      this.currentYear = date.getFullYear()
      this.currentMonth = date.getMonth() + 1
    },
    closePopup() {
      window.$('.modal').modal('hide')
      this.showPopup.MarketSettleWithdrawPopup = false
    },
    goWithdrawPopup(_month, _amount) {
      this.selectedMonth = _month
      this.withdrawAmount = _amount
      this.closePopup()
      this.showPopup.MarketSettleWithdrawPopup = true
    },
    goDetailPage(_currency, _year, _month) {
      this.$router.push({name:'MarketSettleCalendar', params:{currency: _currency, year: _year, month: _month}})
    },
    goExcelPage(_currency, _year, _month) {
      this.$router.push({name:'MarketSettleExcel', params:{currency: _currency, year: _year, month: _month, agency: this.selectedAgency}})
    },
  },
  watch: {
    '$route.query.page'() {
      this.getList(this.$route.query.page)
    },
  },
}
</script>

<style>
</style>

<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">코인</h4>
            <span> 데이터베이스에 기록된 코인 관련 항목을 확인합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->
        <div class="row mb-2">
          <div class="col-sm-12">
            <div class="row">
              <div class="ml-auto">
                <button class="btn btn-success" @click="goRegistCurrency()">새 통화 추가</button>
              </div>
            </div>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">통화</h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th rowspan="2">ID</th>
                      <th rowspan="2">티커</th>
                      <th rowspan="2">이름</th>
                      <th rowspan="2">심볼</th>
                      <th rowspan="2">소숫점</th>
                      <th rowspan="2">화면표시</th>
                      <th colspan="2">출금</th>
                      <th rowspan="2">수수료</th>
                      <th rowspan="2">상태</th>
                      <th rowspan="2">정렬순서</th>
                      <th rowspan="2">액션</th>
                    </tr>
                    <tr>
                      <th>최소</th>
                      <th>최대</th>
                    </tr>
                  </thead>
                  <tbody v-if="items.length > 0">
                    <tr v-for="(item, index) in items" :key="index">
                      <td>{{ item.idx }}</td>
                      <td>{{ item.ticker }}</td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.symbol }}</td>
                      <td>{{ item.maxDecimalFacto }}</td>
                      <td>{{ item.maxDecimalView }}</td>
                      <td>{{ item.minWithdrawAmount }}</td>
                      <td>{{ item.maxWithdrawAmount }}</td>
                      <td>{{ item.fee }} {{ item.isFeePercentage ? '%' : item.ticker }}</td>
                      <td>{{ item.status ? '사용' : '미사용' }}</td>
                      <td>{{ item.ordering }}</td>
                      <td class="text-center">
                        <span class="badge btn btn-outline-warning mr-1" @click="goDetail(item.idx)">상세보기</span>
                        <span class="badge btn btn-primary mr-1" @click="goCheckPopup(item.idx)">Check!</span>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="12" style="text-align: center;">관련된 내역이 없습니다.</td>
                    </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix">
                <pagination ref="Pagination"></pagination>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
    <currencyCheckPopup ref="CurrencyCheckPopup"></currencyCheckPopup>
  </div>
</template>

<script>

import Pagination from "../common/Pagination"
import CurrencyCheckPopup from "../currency/currencySub/CurrencyCheckPopup"

export default {
  name: 'CurrencyList',
  components: {
    Pagination,
    CurrencyCheckPopup,
  },
  data() {
    return {
      size: '10',
      page: 0,
      total: 0,

      //data
      items: []
    }
  },
  created() {
    this.$root.$emit(this.$EVENT_LNB_CHANGE, 'currency', this.$options.name)

    let page = this.$route.query.page
    if(typeof(page) === 'undefined') {
      page = 1
    }

    let size = this.$route.query.size
    if(typeof(size) !== 'undefined') {
      this.size = size
    }

    this.getData(page)
  },
  methods: {
    getData(page) {
      let params = new URLSearchParams()
      if(typeof page === 'undefined') page = 1
      params.append('page', page)
      params.append('limit', this.size)

      this.$get(this.$COIN + '?' + params.toString(), this.$options.name + '_getList', (result) => {
        this.page = page
        this.total = result.total

        try {
          this.$refs.Pagination.init(this.$options.name, {
          }, Number(this.page), this.total, Number(this.size))
        } catch (e) {
          console.log(e)
        }

        this.items = result.data
      }, (result) => {
        this.httpError(result)
      })
    },
    goRegistCurrency() {
      this.$router.push({
        name: 'CurrencyRegist'
      })
    },
    goDetail(_item) {
      this.$router.push({
        name: 'CurrencyEdit',
        params: {
          currencyIdx: _item
        }
      })
    },
    goCheckPopup(idx) {
      this.$refs.CurrencyCheckPopup.init(idx)
    },
  },
  watch: {
    '$route.query.page'() {
      this.getData(this.$route.query.page)
    },
  },
}
</script>

<style>
</style>

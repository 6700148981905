<template>
  <div class="modal fade" id="modal-OtcDetailTradePopup">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">거래내역</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <table class="table table-bordered mb-0" style="table-layout: fixed;">
            <thead>
            <tr>
              <th>클레임번호</th>
              <th>거래번호</th>
              <th colspan="2">거래 신청자</th>
              <th colspan="2">거래 시작 날짜</th>
              <th>거래코인</th>
              <th>거래수량</th>
              <th>거래상태</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(trade, index) in filteredTradeList" :key="index">
              <td>{{ trade.claimIdx }}</td>
              <td>{{ trade.tradeIdx }}</td>
              <td colspan="2">{{ trade.applicantEmail }}</td>
              <td colspan="2">{{ trade.tradeCreatedAt | moment('YYYY-MM-DD HH:mm:ss') }}</td>
              <td>{{ trade.ticker }}</td>
              <td>{{ trade.amount }}
              <td>{{ E_P2PState[trade.tradeState] }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
  <!-- /.modal -->
</template>

<script>
export default {
  name: "OtcDetailTradePopup",
  props: ['tradeList'],
  data() {
    return {
      idx: 0,
      filteredTradeList: [],
    }
  },
  methods: {
    init(idx) {
      this.idx = idx
      this.getFilteredTradeList()
      window.$('#modal-OtcDetailTradePopup').modal('show')
    },
    getFilteredTradeList() {
      this.filteredTradeList = this.tradeList.filter(trade => trade.tradeIdx === this.idx)
    }
  },
}
</script>

<style>
.dark-mode .table td {
  word-break: break-all;
}
</style>

<template>
  <div id="app" class="wrapper">
    <vue-header v-show="!$route.meta.hideLNBHeader"></vue-header>
    <vue-lnb v-show="!$route.meta.hideLNBHeader"></vue-lnb>
    <div :class="{'content-wrapper' : !$route.meta.hideLNBHeader}">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </div>
    <div id="alert-wrap" class="alert-wrap"></div>
  </div>
</template>

<script>
import LNB from "@/components/common/LNB"
import Header from "@/components/common/Header"

export default {
  name: 'App',
  components: {
    'vue-lnb': LNB,
    'vue-header': Header,
  },
  created() {
    let token = sessionStorage.getItem('token')
    if(token) {
      this.refreshToken()
    }
  },
  // beforeCreate() {
  //   let console=(function(oldCons){
  //     return {
  //       log: function(){
  //         if (process.env.VUE_APP_DEBUG === "1") {
  //           oldCons.log.apply(console, arguments)
  //         }
  //       }
  //     }
  //   }(window.console))
  //   window.console = console
  // }
}
</script>

<style>
/* alert */
.alert-wrap {
  position: fixed;
  z-index: 1000;
  top: calc(100vh / 2);
  left: 50%;
  width: 100%;
  max-width: 400px;
  padding: 0 20px;
  transform: translate(-50%, -50%);
  text-align: center;
}

.alert-content {
  padding: 12px;
  font-size: 14px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.7);
}

.alert-content + .alert-content {
  margin-top: 10px;
}

.alert-content i {
  position: relative;
  top: 1px;
  margin-right: 5px;
  color: #fff;
}

.alert-content span {
  color: #fff;
  line-height: 20px;
}
.text-break-all {
  word-break: break-all;
}
</style>

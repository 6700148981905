<template>
  <div class="modal fade" id="modal-OtcDetailChatPopup">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header align-items-center">
          <i class="fas fa-comment"></i><label class="modal-title ml-2">채팅 상세내역</label>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="chatting scroll">
            <div class="msg_balls">
              <p v-if="msgAll.length <= 0" class="msg text-center">아직 채팅을 시작하지 않았습니다.</p>
              <div class="msg_sent" v-for="(chat, chatIdx) in msgAll" :key="chatIdx" :class="{ flex_end: chat.writerIdx === writerIdx, flex_start: chat.writerIdx !== writerIdx}">
                <p class="msg" v-show="chat.comment">{{ chat.comment }}</p>
                <img v-show="chat.fileHash" :src="chat.fileLink" :alt="chat.filename" :key="rerenderkey"/>
                <div v-show="chat.fileHash" class="mt-4 mb-2" style="width: 100%"></div>
<!--                <img v-show="chat.imgUrl" :src="chat.imgUrl"/>-->
<!--                <div v-show="!chat.imgUrl && chat.fileHash">(확인되지 않은 파일입니다. 다른 방법을 통해 확인해주세요.)</div>-->
                <p class="fs12" :class="{ time_r: chat.writerIdx === writerIdx, time_l: chat.writerIdx !== writerIdx}">{{ chat.timestamp | moment('YYYY-MM-DD HH:mm:ss') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
  <!-- /.modal -->
</template>

<script>
export default {
  name: "OtcDetailChatPopup",
  data() {
    return {
      chatroomIdx: 0,
      writerIdx: 0,
      isDetail: false,
      chats: [],

      lastTimestamp: 0,
      msgAll: [],
      tmpMsg: [],
      rerenderkey: 0,

      fileName: '',
      msg: '',
      imgFile: null,


    }
  },

  methods: {
    init(chatRoomIdx, isDetail, writerIdx) { // 부모 데이터 받고 모달 띄우기
      this.chatroomIdx = chatRoomIdx
      this.isDetail = isDetail
      this.writerIdx = writerIdx
      window.$('#modal-OtcDetailChatPopup').modal('show')
      this.tmpMsg = ''
      this.msgAll = ''
      this.getChatMessage()

    },
    getChatMessage() { // 채팅 메시지 받아오기
      this.$get(this.$OTC_SUGGESTION_DETAIL_CHAT_LOG.replace('?', this.chatroomIdx), this.$options.name + '_getChatMessage', (result) => {
        let msgList = result.data
        if(msgList.length) {
          this.modifyChatData(msgList)
        }
      }, (result) => {
        this.httpError(result)
      })
    },

    modifyChatData(msgList) {
      this.tmpMsg = [...msgList]
      this.msgAll = [...this.tmpMsg]

      this.msgAll.forEach((chat, idx) => {
        if(chat.fileHash) {
          if(!chat.fileLink) {
            this.$download(`${this.$OTC_SUGGESTION_DETAIL_CHAT_IMAGE}${chat.fileHash}`, this.$options.name + '_getChatImg' + Math.random(), true, (result) => {
              chat.fileLink = this.$fileDownload(result)[0]
              chat.filename = this.$fileDownload(result)[1]
              if(!document.getElementsByClassName('msg_sent')[idx].querySelector('div').hasChildNodes()) {
                document.getElementsByClassName('msg_sent')[idx].querySelector('div').appendChild(chat.fileLink)
              }
              this.rerenderkey += 1

            }, (result) => {
              this.notify('error', result.message)
            })
          }
        }
      })
      this.msgAll.sort(function(a, b) {
        return a.timestamp - b.timestamp
      })

      this.lastTimestamp = this.msgAll[this.msgAll.length - 1].timestamp
    },

  },
}
</script>

<style scoped>
.chatting {
  height: 500px;
  padding: 15px;
  overflow: auto;
}

.chatting .msg_balls {
  display: flex;
  flex-direction: column;
}

.chatting .msg_balls .time_r {
  width: 180px;
  position: absolute;
  bottom: -35px;
  right: 0;
  text-align: end;
  font-size: 14px;
  color: #E0E0E0;
}

.chatting .msg_balls .time_l {
  width: 180px;
  position: absolute;
  bottom: -35px;
  left: 0;
  font-size: 14px;
  color: #E0E0E0;
}

.chatting .msg_sent {
  max-width: 70%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  background-color: #65686F;
  border-radius: 10px;
  padding: 15px;
  margin: 20px 0px;
  line-height: 10px;
}

.chatting .msg_sent.flex_end:after {
  content: '';
  position: absolute;
  right: 0;
  top: 100%;
  width: 0;
  height: 0;
  border: 15px solid transparent;
  border-left-color: #65686F;
  border-right: 0;
  margin-top: -36px;
  margin-right: -6px;
}

.chatting .msg_sent.flex_start:after {
  content: '';
  position: absolute;
  left: 0;
  top: 100%;
  width: 0;
  height: 0;
  border: 15px solid transparent;
  border-right-color: #65686F;
  border-left: 0;
  margin-top: -36px;
  margin-left: -6px;
}

.chatting .msg_sent.flex_end {
  align-self: flex-end;
}

.chatting .msg_sent.flex_start {
  align-self: flex-start;
}

.chatting .msg_sent img {
  max-width: 200px;
}

.chatting .msg_sent .msg {
  word-break: break-word;
  color: #fff;
  margin: 0px;
}

.chatting .msg_sent div a {
  background-color: #E0E0E0;
  color: #4F4F4F;
  padding: 5px 10px;
}
</style>

<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">사용자</h4>
            <span> 데이터베이스에 기록된 사용자 목록을 확인합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->
        <div class="row mb-2">
          <div class="col-sm-12">
            <h5 class="mb-2">필터</h5>
            <div class="row">
              <select class="form-control w-auto mr-1" v-model="selectedAgency">
                <option value="">대리점</option>
                <option v-for="(agency, index) in agencyList" :key="index" :value="agency.recruitingCode">{{ agency.nickName }} ({{ agency.recruitingCode }})</option>
              </select>
              <select class="form-control w-auto mr-1" v-model="selectedLevel">
                <option value="">인증등급</option>
                <option v-for="(authLevel, index) in authLevelList" :key="index" :value="authLevel">Lv.{{ authLevel }}</option>
              </select>
              <select class="form-control w-auto mr-1" v-model="selectedSearchType">
                <option value="email">이메일</option>
                <option value="phone">휴대폰</option>
                <option value="nickname">닉네임</option>
              </select>
              <input type="text" class="form-control w-auto mr-1" placeholder="검색어" v-model="searchKeyword">
              <select class="form-control w-auto mr-1" v-model="size">
                <option value="10">10줄</option>
                <option value="20">20줄</option>
                <option value="50">50줄</option>
                <option value="100">100줄</option>
                <option value="500">500줄</option>
                <option value="1000">1000줄</option>
              </select>
              <button class="btn btn-primary" @click="getList(page)">검색</button>
              <div class="ml-auto">
                <button class="btn btn-primary mr-1" data-toggle="modal" data-target="#modal-UserSendSmsPopup" @click="goSmsPopup()">SMS</button>
                <button class="btn btn-secondary mr-1" @click="goExcelDownload()">Excel Download</button>
                <button class="btn btn-success mr-1" @click="goRegistUser()">새 사용자 추가</button>
                <button type="button" class="btn btn-danger" @click="deleteUser">사용자 삭제</button>
              </div>
            </div>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">일반 사용자</h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <table class="table table-bordered" id="excelData">
                  <thead>
                  <tr>
                    <th class="text-center">
                      <input type="checkbox" v-model="checkAll" @change="checkAllChange()">
                    </th>
                    <th>회원번호</th>
                    <th>인증</th>
                    <th>이메일</th>
                    <th>휴대폰</th>
                    <th>닉네임</th>
                    <th>자산현황</th>
                    <th>특이사항</th>
                    <th>관리자메모</th>
                    <th>상세정보</th>
                  </tr>
                  </thead>
                  <tbody v-if="items.length > 0">
                    <tr v-for="(item, index) in items" :key="index">
                      <td class="text-center"><input type="checkbox" v-model="item.isChecked"></td>
                      <td>{{ item.idx }}</td>
                      <td>Lv.{{ item.authLevel }}</td>
                      <td>{{ item.email }}</td>
                      <td>{{ item.phone }}</td>
                      <td>{{ item.nickName }}</td>
                      <td class="text-center">
                        <span class="badge btn btn-outline-warning" @click="goAssetPopup(item.idx)">상세보기</span>
                      </td>
                      <td class="text-center">
                        <span class="badge btn btn-outline-warning" @click="goRemarkPopup(item.idx)">상세보기</span>
                      </td>
                      <td class="text-center">
                        <span class="badge btn btn-outline-warning" @click="goMemoPopup(item.idx)">상세보기</span>
                      </td>
                      <td class="text-center">
                        <span class="badge btn btn-outline-warning" @click="goDetailPage(item.idx, item.email)">상세보기</span>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="10" style="text-align: center;">관련된 내역이 없습니다.</td>
                    </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix">
                <pagination ref="Pagination"></pagination>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
    <userAssetPopup ref="UserAssetPopup"></userAssetPopup>
    <userRemarkPopup ref="UserRemarkPopup"></userRemarkPopup>
    <userMemoPopup ref="UserMemoPopup"></userMemoPopup>
    <userSendSmsPopup ref="UserSendSmsPopup"></userSendSmsPopup>
  </div>
</template>

<script>
import UserAssetPopup from "@/components/user/userSub/UserAssetPopup"
import UserMemoPopup from "@/components/user/userSub/UserMemoPopup"
import UserRemarkPopup from "@/components/user/userSub/UserRemarkPopup"
import UserSendSmsPopup from "@/components/user/userSub/UserSendSmsPopup"
import Pagination from "../common/Pagination"

export default {
  name: 'UserManage',
  components: {
    Pagination,
    UserAssetPopup,
    UserMemoPopup,
    UserRemarkPopup,
    UserSendSmsPopup,
  },
  data() {
    return {
      //search filter
      agencyList: [],
      authLevelList: [],

      selectedAgency: '',
      selectedLevel: '',
      selectedSearchType: 'email',
      searchKeyword: '',
      size: '10',
      page: 0,
      total: 0,

      checkAll: false,

      //data
      items: [],
      date: '',
      deleteUserIdx: []
    }
  },
  created() {
    this.$root.$emit(this.$EVENT_LNB_CHANGE, 'user', this.$options.name)
    this.date = this.$moment(new Date()).format('YYYYMMDD_hhmmss')

    let page = this.$route.query.page
    if(typeof(page) === 'undefined') {
      page = 1
    }

    let size = this.$route.query.size
    if(typeof(size) !== 'undefined') {
      this.size = size
    }

    let ag = this.$route.query.ag
    if(typeof(ag) !== 'undefined') {
      this.selectedAgency = ag
    }

    let lv = this.$route.query.lv
    if(typeof(lv) !== 'undefined') {
      this.selectedLevel = lv
    }

    let st = this.$route.query.st
    if(typeof(st) !== 'undefined') {
      this.selectedSearchType = st
    }

    let sk = this.$route.query.sk
    if(typeof(sk) !== 'undefined') {
      this.searchKeyword = sk
    }

    this.getFilterData(() => {
      this.getList(page)
    })
  },
  methods: {
    deleteUser() {
      for (let i=0; i<this.items.length; i++) {
        if (this.items[i].isChecked) {
          this.deleteUserIdx.push(this.items[i].idx)
        }
        this.items[i].isChecked = false;
      }

      this.$delete(this.$USER_DELETE + '?userIds=' + this.deleteUserIdx, this.$options.name + '_deleteUser', (result) => {
        this.notify('success', result.message, false)
        this.getList(1)
      }, (result) => {
        this.httpError(result)
      })

      this.deleteUserIdx = []
      document.querySelectorAll('input[type="checkbox"]')
      .forEach(el => el.checked = false)
    },

    getFilterData(callback) {
      this.$get(this.$USER_FILTER, this.$options.name + '_getFilterData', (result) => {
        this.agencyList = result.data.agency
        this.authLevelList = result.data.authLevel

        callback()
      }, (result) => {
        this.httpError(result)
      })
    },
    getList(page) {
      let params = new URLSearchParams()
      if(typeof page === 'undefined') page = 1
      params.append('page', page)
      params.append('limit', this.size)
      params.append('agency', this.selectedAgency)
      params.append('level', this.selectedLevel)
      params.append('criteria', this.selectedSearchType)
      params.append('keyword', this.searchKeyword)

      this.$get(this.$USER + '?' + params.toString(), this.$options.name + '_getList', (result) => {
        this.page = page
        this.total = result.total

        try {
          this.$refs.Pagination.init(this.$options.name, {
            ag: this.selectedAgency,
            lv: this.selectedLevel,
            st: this.selectedSearchType,
            sk: this.searchKeyword,
          }, Number(this.page), this.total, Number(this.size))
        } catch (e) {
          console.log(e)
        }

        this.items = result.data
        for (let i=0;i<this.items.length;i++) {
          this.items[i].isChecked = false
        }
      }, (result) => {
        this.httpError(result)
      })
    },
    checkAllChange() {
      for (let i=0;i<this.items.length;i++) {
        let item = this.items[i]
        item.isChecked = this.checkAll
      }
    },
    getCheckedItem() {
      let checkedItems = []
      for (let i=0;i<this.items.length;i++) {
        let item = this.items[i]
        if (item.isChecked) {
          checkedItems.push(item)
        }
      }
      return checkedItems
    },
    goAssetPopup(idx) {
      this.$refs.UserAssetPopup.init(idx)
    },
    goRemarkPopup(idx) {
      this.$refs.UserRemarkPopup.init(idx)
    },
    goMemoPopup(idx) {
      this.$refs.UserMemoPopup.init(idx)
    },
    goDetailPage(idx, email) {
      this.$router.push({
        name: 'UserDetail',
        params: {
          userIdx: idx,
          userEmail: email,
        }
      })
    },
    goSmsPopup() {
      this.$refs.UserSendSmsPopup.init(this.getCheckedItem())
    },
    goExcelDownload() {
      this.$downloadExcelData('excelData', `${this.$options.name}_${this.date}_excel`)
    },
    goRegistUser() {
      this.$router.push({
        name: 'UserRegist'
      })
    }
  },
  watch: {
    '$route.query.page'() {
      this.getList(this.$route.query.page)
    },
  },
}
</script>

<style>
</style>

<template>
  <div>
    <div class="column align-content-center mb-2 chat-list-table">
      <div class="col-sm-3 text-left">
        <strong>채팅 목록 *</strong>
      </div>
      <div class="card-body">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>채팅방 번호</th>
              <th>채팅 신청자</th>
              <th>비고</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(chat, index) in chats" :key="index">
              <td>{{ chat.idx }}</td>
              <td>{{ chat.applicantEmail }}</td>
              <td class="text-center">
                <span class="badge btn btn-success" @click="goDetailChat(chat.idx, true, chat.writerIdx)">채팅 상세내역</span>
              </td>
            </tr>
          <tr v-if="chats.length === 0">
            <td colspan="3" class="text-center">채팅 내역이 없습니다.</td>
          </tr>
          </tbody>
        </table>
        <div class="mt-2">
          <paginationChat ref="PaginationChat" />
        </div>
      </div>
    </div>
    <otcDetailChatPopup ref="OtcDetailChatPopup"></otcDetailChatPopup>
  </div>
</template>

<script>
import OtcDetailChatPopup from "./otcSuggestionSub/OtcDetailChatPopup";
import PaginationChat from "../common/PaginationChat";

export default {
  name: "OtcSuggestionChat",
  components: {
    OtcDetailChatPopup,
    PaginationChat,
  },
  data() {
    return {
      idx: 0,
      chats: [],
      size: '10',
      chatPage: 1,
      chatTotal: 0,
    }
  },
  created() {
    this.idx = this.$route.params.idx

    let page = this.$route.query.chatPage
    if(typeof(page) === 'undefined') {
      page = 1
    }

    let size = this.$route.query.chatSize
    if(typeof(size) !== 'undefined') {
      this.size = size
    }

    this.getChatList(this.chatPage)
  },
  methods: {
    getChatList(chatPage) { // 채팅 리스트 받아오기
      let params = new URLSearchParams()
      if(typeof chatPage === 'undefined') chatPage = 1
      params.append('page', chatPage)
      params.append('limit', this.size)

      this.$get(`${this.$OTC_SUGGESTION_DETAIL_CHAT_LIST.replace('?', this.idx)}?${params.toString()}`, this.$options.name + '_getChatList', (result) => {
        this.chatPage = chatPage
        this.chatTotal = result.total

        try {
          this.$refs.PaginationChat.init(this.$options.name, {}, Number(this.chatPage), this.chatTotal, Number(this.size))
        } catch (e) {
          console.log(e)
        }

        this.chats = result.data
      }, (result) => {
        this.httpError(result)
      })
    },
    goDetailChat(chatRoomIdx, isDetail, writerIdx) { // 채팅 내역 받아오기
      this.$refs.OtcDetailChatPopup.init(chatRoomIdx, isDetail, writerIdx)
    },
  },
  watch: {
    '$route.query.chatPage'() {
      this.getChatList(this.$route.query.chatPage)
    }
  }
}
</script>

<style>
.chat-list-table {
  margin-left: auto;
  margin-right: auto;
  width: 65%;
}
</style>

<template>
  <div class="modal fade" id="modal-UserRemarkPopup">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">특이사항</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <table class="table table-bordered mb-0">
            <thead>
              <tr>
                <th class="w-20 text-center">출금 기능</th>
                <th class="w-20 text-center">거래 기능</th>
                <th class="w-20 text-center">로그인 기능</th>
                <th class="w-20 text-center">전체 기능</th>
                <th class="w-20 text-center">활성화 기능</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="w-20 text-center">{{ data.withdraw === true ? '허용' : '제한' }}</td>
                <td class="w-20 text-center">{{ data.trade === true ? '허용' : '제한' }}</td>
                <td class="w-20 text-center">{{ data.login === true ? '허용' : '제한' }}</td>
                <td class="w-20 text-center">{{ data.total === true ? '허용' : '제한' }}</td>
                <td class="w-20 text-center">{{ data.active === true ? '허용' : '제한' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal" aria-label="Close">닫기</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
  <!-- /.modal -->
</template>

<script>
export default {
  name: 'UserRemarkPopup',
  data() {
    return {
      data: {
        withdraw: false,
        trade: false,
        login: false,
        total: false,
        active: true,
      }
    }
  },
  methods: {
    init(idx) {
      this.$get(this.$USER_STATUS.replace('?', idx), this.$options.name + '_init', (result) => {
        this.getFormatData(this.data, result)
        window.$('#modal-UserRemarkPopup').modal('show')
      }, (result) => {
        this.httpError(result)
      })
    },
  },
}
</script>

<style>
</style>

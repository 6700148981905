<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h5 class="m-0">현재 접속자수</h5>
          </div><!-- /.col -->
          <div class="col-sm-6">
            <h5 class="m-0">전체 회원수</h5>
          </div><!-- /.col -->
        </div><!-- /.row -->
        <div class="row mb-2">
          <div class="col-sm-6">
            <span class="m-0">{{ data.currentConnectUser }} 명</span>
          </div><!-- /.col -->
          <div class="col-sm-6">
            <span class="m-0">전체 : {{ data.allMemberCount }} 명 | 오늘 : {{ data.todayMemberCount }} 명</span>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">코인 출금 최신 현황</h5>
                <div class="card-tools">
                  <router-link :to="{ name: 'WalletTransferList', params: { isDeposit: '0' } }" class="btn btn-tool" data-card-widget="collapse">
                    코인 출금 더보기
                  </router-link>
                </div>
              </div><!-- /.card-header -->
              <div class="card-body">
                <table class="table table-bordered">
                  <thead>
                  <tr>
                    <th>관리번호</th>
                    <th>통화</th>
                    <th>신청일자</th>
                    <th>트랜잭션 넘버</th>
                    <th>타입</th>
                    <th>사용자</th>
                    <th>거래자</th>
                    <th>수량</th>
                    <th>수수료</th>
                    <th>액션</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, index) in data.coinStatus.withdraw" :key="index">
                    <td>{{ item.idx }}</td>
                    <td>{{ item.tickerName }}</td>
                    <td>{{ item.createdAt | moment('YYYY-MM-DD HH:mm:ss') }}</td>
                    <td>{{ item.tx }}</td>
                    <td>{{ E_TransferType[item.type] }}</td>
                    <td class="text-center">
                      <span class="badge btn btn-success">{{ item.owner }}</span>
                    </td>
                    <td>{{ item.opposite ? item.opposite : '-' }}</td>
                    <td>{{ item.amount }}</td>
                    <td>{{ item.feeAmount }}</td>
                    <td>{{ E_ProcessState[item.processState] }}</td>
                  </tr>
                  <tr v-if="data.coinStatus.withdraw.length === 0">
                    <td colspan="11" style="text-align: center;">출금 내역이 없습니다.</td>
                  </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
            </div><!-- /.card -->
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">코인 입금 최신 현황</h5>
                <div class="card-tools">
                  <router-link :to="{ name: 'WalletTransferList', params: { isDeposit: '1' }  }" class="btn btn-tool" data-card-widget="collapse">
                    코인 입금 더보기
                  </router-link>
                </div>
              </div><!-- /.card-header -->
              <div class="card-body">
                <table class="table table-bordered">
                  <thead>
                  <tr>
                    <th>관리번호</th>
                    <th>통화</th>
                    <th>신청일자</th>
                    <th>트랜잭션 넘버</th>
                    <th>타입</th>
                    <th>사용자</th>
                    <th>거래자</th>
                    <th>수량</th>
                    <th>수수료</th>
                    <th>액션</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, index) in data.coinStatus.deposit" :key="index">
                    <td>{{ item.idx }}</td>
                    <td>{{ item.tickerName }}</td>
                    <td>{{ item.createdAt | moment('YYYY-MM-DD HH:mm:ss') }}</td>
                    <td class="text-break-all">{{ item.tx }}</td>
                    <td>{{ E_TransferType[item.type] }}</td>
                    <td class="text-center">
                      <span class="badge btn btn-success">{{ item.owner }}</span>
                    </td>
                    <td>{{ item.opposite ? item.opposite : '-' }}</td>
                    <td>{{ item.amount }}</td>
                    <td>{{ item.feeAmount }}</td>
                    <td>{{ E_ProcessState[item.processState] }}</td>
                  </tr>
                  <tr v-if="data.coinStatus.deposit.length === 0">
                    <td colspan="11" style="text-align: center;">입금 내역이 없습니다.</td>
                  </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Dashboard',
  data() {
    return {
      data: {
        allMemberCount:0,
        authUpwardRequestCount:0,
        coinStatus: {
          deposit:[],
          withdraw:[],
        },
        currentConnectUser:0,
        qnaCount:0,
        todayMemberCount:1,
        transferCount:0,
      },
    }
  },
  created() {
    this.$root.$emit(this.$EVENT_LNB_CHANGE, null, null)
  },
  mounted() {
    this.getDashBoard()
  },
  methods: {
    getDashBoard() {
      this.$get(this.$DASHBOARD, this.$options.name + '_getDashBoard', (result) => {
        this.data = result.data
      }, (result) => {
        this.httpError(result)
      })
    }
  }
}
</script>

<style>
</style>

<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">도구</h4>
            <span> 사이트를 관리하기 위한 각종 도구를 제공합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">{{ boardData.boardType.boardTitle }}</h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <div class="row align-items-center mb-2">
                  <div class="col-sm-2 text-right">
                    <strong>제목 *</strong>
                  </div>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" v-model="formData.title"/>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-sm-2 text-right">
                    <strong>내용 *</strong><br/>
                    <button class="btn btn-sm btn-primary" @click="changeEditor()">에디터 전환</button>
                  </div>
                  <div v-show="!guiEditor" class="col-sm-8">
                    <textarea type="text" class="form-control" v-model="formData.contents" rows="24"></textarea>
                  </div>
                  <div v-show="guiEditor" class="col-sm-8 bg-white rounded p-1">
                    <div id="editor"></div>
                  </div>
                </div>
                <div v-for="(item, index) in boardItems" :key="index">
                  <div v-if="item.type === 'text'" class="row align-items-center mb-2">
                    <div class="col-sm-2 text-right">
                      <strong>{{ item.name }} *</strong>
                    </div>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" v-model="formData[item.name]"/>
                    </div>
                  </div>
                  <div v-else-if="item.type === 'multipart'" class="row mb-2">
                    <div class="col-sm-2 text-right">
                      <strong>첨부파일</strong>
                    </div>
                    <div class="col-sm-8">
                      <input type="file" class="form-control" @change="fileUpload(item.name, $event)" accept="image/*"/>
                    </div>
                  </div>
                </div>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix text-center row">
                <div class="col-sm-2">
                </div>
                <div class="col-sm-8">
                  <button type="button" class="btn btn-primary mr-1" @click="newData()">저장</button>
                  <button type="button" class="btn btn-secondary" @click="$router.back()">목록</button>
                </div>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'BoardContentRegist',
  data() {
    return {
      guiEditor: false,

      boardIdx: 0,

      boardData: {
        boardType: {
          boardTitle: '',
          boardCode: '',
          boardLanguage: 'KO-KR'
        },
        title: {
          type: 'text',
          length: 255,
          require: true,
        },
        contents: {
          type: 'text',
          length: 1024,
          require: true
        },
      },
      boardItems: [],

      formData: {
        title: '',
        contents: '',
      },

      fileData: [],

      editor: null,
    }
  },
  created() {
    this.boardIdx = this.$route.params.boardIdx

    this.getFilter(() => {})
  },
  mounted() {
    window.CKEDITOR.ClassicEditor.create(document.getElementById("editor"), {
      toolbar: {
        items: [
          'findAndReplace', 'selectAll', '|',
          'heading', '|',
          'bold', 'italic', 'strikethrough', 'underline', 'code', 'subscript', 'superscript', 'removeFormat', '|',
          'bulletedList', 'numberedList', 'todoList', '|',
          'outdent', 'indent', '|',
          'undo', 'redo',
          '-',
          'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor', '|',
          'alignment', '|',
          'link', 'insertImage', 'blockQuote', 'insertTable', 'mediaEmbed', 'codeBlock', '|',
          'specialCharacters', 'horizontalLine', '|',
          'sourceEditing'
        ],
        shouldNotGroupWhenFull: true
      },
      list: {
        properties: {
          styles: true,
          startIndex: true,
          reversed: true
        }
      },
      heading: {
        options: [
          { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
          { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
          { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
          { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
          { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
          { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
          { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
        ]
      },
      placeholder: '',
      fontFamily: {
        options: [
          'default',
          'Arial, Helvetica, sans-serif',
          'Courier New, Courier, monospace',
          'Georgia, serif',
          'Lucida Sans Unicode, Lucida Grande, sans-serif',
          'Tahoma, Geneva, sans-serif',
          'Times New Roman, Times, serif',
          'Trebuchet MS, Helvetica, sans-serif',
          'Verdana, Geneva, sans-serif'
        ],
        supportAllValues: true
      },
      fontSize: {
        options: [ 10, 12, 14, 'default', 18, 20, 22 ],
        supportAllValues: true
      },
      htmlSupport: {
        allow: [
          {
            name: /.*/,
            attributes: true,
            classes: true,
            styles: true
          }
        ]
      },
      htmlEmbed: {
        showPreviews: true
      },
      link: {
        decorators: {
          addTargetToExternalLinks: true,
          defaultProtocol: 'https://',
          toggleDownloadable: {
            mode: 'manual',
            label: 'Downloadable',
            attributes: {
              download: 'file'
            }
          }
        }
      },
      removePlugins: [
        'CKBox',
        'CKFinder',
        'EasyImage',
        'RealTimeCollaborativeComments',
        'RealTimeCollaborativeTrackChanges',
        'RealTimeCollaborativeRevisionHistory',
        'PresenceList',
        'Comments',
        'TrackChanges',
        'TrackChangesData',
        'RevisionHistory',
        'Pagination',
        'WProofreader',
        'MathType'
      ]
    }).then( newEditor => {
      this.editor = newEditor;
    }).catch( error => {
      console.error( error );
    });
  },
  methods: {
    changeEditor() {
      if(!this.guiEditor) {
        let tmp = this.formData.contents.replaceAll('\r', '').split('\n')
        let content = ''
        for (let i = 0; i < tmp.length; i++) {
          content += '<p>' + tmp[i] + '</p>'
        }
        this.editor.setData(content);

        this.guiEditor = true
      } else {
        if(confirm('에디터 전환시 작성된 HTML 태그가 제거됩니다. 계속하시겠습니까?')) {
          let tmp = this.editor.getData().replaceAll('<br>', '').replaceAll('<p>', '').replaceAll('</p>', '\n');
          this.formData.contents = tmp.replace(/<[^>]*>?/gm, '').replaceAll('\r', '').replace(/\n$/, '')

          this.guiEditor = false
        }
      }
    },
    getFilter(callback) {
      this.$get(this.$TOOLS_BOARD_DETAIL.replace('?', this.boardIdx), this.$options.name + '_getFilter', (result) => {
        this.getFormatData(this.boardData, result)

        this.boardItems = []
        for (const [key, value] of Object.entries(result.data)) {
          if(key === 'boardType' || key === 'title' || key === 'contents') {
            continue
          }

          if(value.type === 'text') {
            this.formData[key] = ''
          } else if(value.type === 'multipart') {
            this.fileData[key] = {
              text: '',
              data: ''
            }
          }

          this.boardItems.push({
            name: key,
            type: value.type,
            length: value.length,
            require: value.require
          })
        }

        callback()
      }, (result) => {
        this.httpError(result)
      })
    },
    newData() {
      if(this.guiEditor) {
        this.formData.contents = this.editor.getData();
      }

      let formData = new FormData()
      Object.entries(this.fileData).forEach((entry) => {
        let [key, value] = entry
        if(value.data !== '') {
          formData.append(key, value.data)
        }
      })
      formData.append('request', new Blob([JSON.stringify(this.formData)], {type: "application/json"}))

      this.$post(this.$TOOLS_BOARD_CONTENTS.replace('?', this.boardIdx), this.$options.name + '_newData', formData, (result) => {
        this.notify('success', result.message, false)

        this.$router.push({
          name: 'BoardContentList',
          params: {
            boardIdx: this.boardIdx
          }
        })
      }, (result) => {
        this.httpError(result)
      })
    },
    fileUpload(key, event) {
      let file = event.target.files
      if(!file.length) {
        return
      }
      this.fileData[key].data = file[0]
    }
  }
}
</script>

<style>
</style>

<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">BOT</h4>
            <span>봇 관련 항목을 확인합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->
        <div class="row mb-2">
          <div class="col-sm-12">
            <h5 class="mb-2">필터</h5>
            <div class="row">
              <select class="form-control w-auto mr-1" v-model="selectedPrimary">
                <option value="">전체</option>
                <option v-for="(primary, index) in primaryList" :key="index" :value="primary">{{ primary }}</option>
              </select>
              <select class="form-control w-auto mr-1" v-model="selectedSecondary">
                <option value="">--</option>
                <option v-for="(secondary, index) in secondaryList" :key="index" :value="secondary">{{ secondary }}</option>
              </select>
              <select class="form-control w-auto mr-1" v-model="selectedSearchType">
                <option value="idx">봇 번호</option>
                <option value="nickname">봇 닉네임</option>
              </select>
              <input type="text" class="form-control w-auto mr-1" placeholder="검색어" v-model="searchKeyword">
              <select class="form-control w-auto mr-1" v-model="size">
                <option value="10">10줄</option>
                <option value="20">20줄</option>
                <option value="50">50줄</option>
                <option value="100">100줄</option>
              </select>
              <input type="date" class="form-control w-auto" v-model="selectedStart"/>
              <span class="m-1">~</span>
              <input type="date" class="form-control w-auto mr-1" v-model="selectedEnd"/>
              <button class="btn btn-primary" @click="getList(page)">검색</button>
            </div>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">거래 - 히스토리</h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>관리번호</th>
                      <th>마켓</th>
                      <th>봇 번호</th>
                      <th>봇 닉네임</th>
                      <th>봇 타입</th>
                      <th>체결가격</th>
                      <th>체결수량</th>
                      <th>날짜</th>
                    </tr>
                  </thead>
                  <tbody v-if="items.length > 0">
                    <tr v-for="(item, index) in items" :key="index">
                      <td>{{ item.tradeHistoryIdx }}</td>
                      <td>{{ item.primary }} ⇄ {{ item.secondary }}</td>
                      <td>{{ item.botIdx }}</td>
                      <td>{{ item.botName }}</td>
                      <td>{{ item.botType === 'ORDER' ? '호가봇' : '체결봇' }}</td>
                      <td>{{ item.price }} {{ item.secondary }}</td>
                      <td>{{ item.amount }} {{ item.primary }}</td>
                      <td>{{ item.createdAt | moment('YYYY-MM-DD HH:mm:ss') }}</td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="8" style="text-align: center;">관련된 내역이 없습니다.</td>
                    </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix">
                <pagination ref="Pagination"></pagination>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>
import Pagination from "../common/Pagination"
import Vue from 'vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
Vue.use(Loading)
import Loader from '@/components/common/Loader'

export default {
  name: "BotTradeList",
  components: {
    Pagination,
  },
  data() {
    return {
      //search filter
      marketPairList: [],
      currencyList: [],

      primaryList: [],
      secondaryList: [],

      selectedPrimary: '',
      selectedSecondary: '',
      selectedSearchType: 'idx',
      searchKeyword: '',
      selectedStart: '',
      selectedEnd: '',

      selectedTmpStart: '',
      selectedTmpEnd: '',

      size: '10',
      page: 0,
      total: 0,

      //data
      items: [],

      loader: null,
    }
  },
  created() {
    this.$root.$emit(this.$EVENT_LNB_CHANGE, 'bot', this.$options.name)

    let page = this.$route.query.page
    if(typeof(page) === 'undefined') {
      page = 1
    }

    let size = this.$route.query.size
    if(typeof(size) !== 'undefined') {
      this.size = size
    }

    let pc = this.$route.query.pc
    if(typeof(pc) !== 'undefined') {
      this.selectedPrimary = pc
    }

    let sc = this.$route.query.sc
    if(typeof(sc) !== 'undefined') {
      this.selectedSecondary = sc
    }

    let st = this.$route.query.st
    if(typeof(st) !== 'undefined') {
      this.selectedSearchType = st
    }

    let sk = this.$route.query.sk
    if(typeof(sk) !== 'undefined') {
      this.searchKeyword = sk
    }

    this.getFilterData(() => {
      this.getList(page)
    })

    this.setDefaultDate()
  },
  beforeDestroy() {
    this.loader.hide()
  },
  methods: {
    getFilterData(callback) {
      this.$get(this.$MARKET_FILTER, this.$options.name + '_getFilterData', (result) => {
        this.marketPairList = result.data.marketPair
        this.currencyList = result.data.currency

        this.primaryList = []
        for(let i=0; i<this.marketPairList.length; i++) {
          if(this.primaryList.lastIndexOf(this.marketPairList[i].primary) !== -1) {
            continue
          }
          this.primaryList.push(this.marketPairList[i].primary)
        }

        callback()
      }, (result) => {
        this.httpError(result)
      })
    },
    getList(page) {
      this.loader = this.$loading.show({
        backgroundColor: '#65686F',
        opacity: 0.5,
        zIndex: 999,
      }, {
        default: this.$createElement(Loader)
      })

      let formData = new FormData()
      if(typeof page === 'undefined') page = 1
      formData.append('page', page)
      formData.append('limit', this.size)
      formData.append('primary', this.selectedPrimary)
      formData.append('secondary', this.selectedSecondary)
      formData.append('criteria', this.selectedSearchType)
      formData.append('keyword', this.searchKeyword)
      if(!this.selectedStart) {
        this.notify('error', '검색 시작일을 확인해주세요.', false)
        return
      }
      formData.append('start', this.selectedTmpStart)
      if(!this.selectedEnd) {
        this.notify('error', '검색 종료일을 확인해주세요.', false)
        return
      }
      formData.append('end', this.selectedTmpEnd)

      this.$post(this.$BOT_TRADE_LOG, this.$options.name + '_getList', formData,(result) => {
        this.loader.hide()
        this.page = page
        this.total = result.total

        try {
          this.$refs.Pagination.init(this.$options.name, {
            pc: this.selectedPrimary,
            sc: this.selectedSecondary,
            st: this.selectedSearchType,
            sk: this.searchKeyword,
          }, Number(this.page), this.total, Number(this.size))
        } catch (e) {
          console.log(e)
        }

        this.items = result.data
      }, (result) => {
        this.httpError(result)
      })
    },
    setDefaultDate() {
      let today = new Date()
      this.selectedEnd = this.$moment(today).utcOffset(9).format('YYYY-MM-DD')
      this.selectedStart = this.$moment(today).subtract(7, 'days').utcOffset(9).format('YYYY-MM-DD')
    },
  },
  watch: {
    '$route.query.page'() {
      this.getList(this.$route.query.page)
    },
    'selectedPrimary'() {
      this.selectedSecondary = ''
      this.secondaryList = []
      if(this.selectedPrimary === '') {
        return
      }
      for(let i=0; i<this.marketPairList.length; i++) {
        if(this.marketPairList[i].primary !== this.selectedPrimary) {
          continue
        }
        if(this.secondaryList.indexOf(this.marketPairList[i].secondary) === -1) {
          this.secondaryList.push(this.marketPairList[i].secondary)
        }
      }
    },
    'selectedStart'() {
      if(this.selectedStart) {
        let date = new Date(this.selectedStart)
        date.setHours(0)
        date.setMinutes(0)
        date.setSeconds(0)
        this.selectedTmpStart = +date
      } else {
        this.selectedTmpStart = ''
      }
    },
    'selectedEnd'() {
      if(this.selectedEnd) {
        let date = new Date(this.selectedEnd)
        date.setHours(23)
        date.setMinutes(59)
        date.setSeconds(59)
        this.selectedTmpEnd = +date
      } else {
        this.selectedTmpEnd = ''
      }
    }
  },
}
</script>

<style scoped>

</style>
<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">도구</h4>
            <span>사이트를 관리하기 위한 각종 도구를 제공합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">{{ selectedAgency === '' ? '-' : selectedAgency }} / {{ selectedYear }} / {{ (selectedMonth.length === 1 ? '0' : '') + selectedMonth }} 정산</h5>
                <div class="row float-right" id="dataTableButtons">
                </div>
              </div><!-- /.card-header -->
              <div class="card-body">
                <table id="dataTable" class="table table-bordered">
                  <thead>
                  <tr>
                    <th>일</th>
                    <th>매도</th>
                    <th>매수</th>
                    <th>출금</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(value, key, index) in items" :key="index">
                    <td>{{ key }}</td>
                    <td>{{ value.sell }}</td>
                    <td>{{ String(value.buy) }}</td>
                    <td>{{ value.withdraw }}</td>
                  </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'MarketSettleExcel',
  data() {
    return {
      agencyList: [],
      currencyList: [],
      yearList: [],

      selectedAgency: 'asdf@adsf.asdf',
      selectedCurrency: 'BitCoin',
      selectedYear: '',
      selectedMonth: '',

      items: [],
    }
  },
  created() {
    this.selectedCurrency = this.$route.params.currency
    this.selectedYear = this.$route.params.year
    this.selectedMonth = this.$route.params.month
    this.selectedAgency = this.$route.params.agency

    this.getFilterData(() => {
      this.getData()
    })
  },
  methods: {
    getFilterData(callback) {
      let startYear = 2021
      for (let i = startYear; i <= (new Date()).getFullYear(); i++) {
        this.yearList.push(i)
      }

      this.$get(this.$PROFIT_FILTER, this.$options.name + '_getFilterData', (result) => {
        this.agencyList = result.data.agency
        this.currencyList = result.data.currency

        callback()
      }, (result) => {
        this.httpError(result)
      })
    },
    getData() {
      let formData = new FormData()
      formData.append('recruitingCode', this.selectedAgency)
      formData.append('ticker', this.selectedCurrency)
      formData.append('year', this.selectedYear)
      formData.append('month', this.selectedMonth)

      this.$post(this.$PROFIT_MONTH, this.$options.name + '_getList', formData, (result) => {
        this.items = result.data
        this.$createTables()
        this.$destroyTables()
      }, (result) => {
        this.httpError(result)
      })
    },
  },
  watch: {
    'this.items'() {
      this.$createTables()
    },
  }
}
</script>

<style>
</style>

<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">사용자</h4>
            <span>데이터베이스에 기록된 사용자 목록을 확인합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">일반 사용자</h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>이메일 *</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="email" class="form-control" v-model="formData.email"/>
                  </div>
                  <div class="col-sm-4">
                    <span>사용자 정보에 기록되는 이메일입니다.</span>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>닉네임 *</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="text" class="form-control" v-model="formData.nickName"/>
                  </div>
                  <div class="col-sm-4">
                    <span>사용자의 닉네임입니다.</span>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>비밀번호 *</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="password" class="form-control" v-model="formData.password"/>
                  </div>
                  <div class="col-sm-4">
                    <span>사용자 로그인시 사용되는 비밀번호입니다.</span>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>비밀번호 확인 *</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="password" class="form-control" v-model="formData.passwordConfirm "/>
                  </div>
                  <div class="col-sm-4">
                    <span>비밀번호 확인을 위해 한번 더 입력하세요.</span>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>휴대폰 *</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="tel" class="form-control" v-model="formData.phone"/>
                  </div>
                  <div class="col-sm-4">
                    <span>사용자 정보에 기록되는 휴대폰입니다. 예) +821012345678</span>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>대리점 코드</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="text" class="form-control" v-model="formData.recruiter"/>
                  </div>
                  <div class="col-sm-4">
                    <span>사용자 정보에 기록되는 대리점 코드입니다.</span>
                  </div>
                </div>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix text-center row">
                <div class="col-sm-3">
                </div>
                <div class="col-sm-5">
                  <button type="button" class="btn btn-primary mr-1" @click="newData()">사용자 추가</button>
                  <button type="button" class="btn btn-secondary" @click="$router.back()">목록</button>
                </div>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'UserRegist',
  data() {
    return {
      formData: {
        email: '',
        nickName : '',
        password: '',
        passwordConfirm : '',
        phone: '',
        recruiter: '',
      },
    }
  },
  methods: {
    newData() {
      let formData = new FormData()
      Object.entries(this.formData).forEach((entry) => {
        let [key, value] = entry
        formData.append(key, (value+'').trim())
      })

      this.$post(this.$USER, this.$options.name + '_newData', formData, (result) => {
        this.notify('success', result.message, false)

        this.$router.push({
          name: 'UserManage'
        })
      }, (result) => {
        this.httpError(result)
      })
    }
  }
}
</script>

<style>
</style>

<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">코인</h4>
            <span> 데이터베이스에 기록된 코인 관련 항목을 확인합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">코인 관련 세부 항목 수정합니다.</h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>라벨 *</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="text" class="form-control" v-model="formData.ticker" disabled />
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>닉네임 *</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="text" class="form-control" v-model="formData.name" disabled />
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>단위 *</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="text" class="form-control" v-model="formData.symbol" disabled />
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>소숫점</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="text" class="form-control" v-model="formData.maxDecimalFacto" disabled />
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>소숫점 표시</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="text" class="form-control" v-model="formData.maxDecimalView"/>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>출금 수수료</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="text" class="form-control" v-model="formData.fee"/>
                  </div>
                </div>
<!--                <div class="row align-items-center mb-2">-->
<!--                  <div class="col-sm-3 text-right">-->
<!--                    <strong>출금 수수료 형식</strong>-->
<!--                  </div>-->
<!--                  <div class="col-sm-5">-->
<!--                    <select class="form-control" v-model="formData.isFeePercentage">-->
<!--                      <option value="0">고정값</option>-->
<!--                      <option value="1">퍼센트</option>-->
<!--                    </select>-->
<!--                  </div>-->
<!--                </div>-->
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>최소 출금 수량</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="text" class="form-control" v-model="formData.minWithdrawAmount"/>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>최대 출금 수량</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="text" class="form-control" v-model="formData.maxWithdrawAmount"/>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>상태 *</strong>
                  </div>
                  <div class="col-sm-5">
                    <select class="form-control" v-model="formData.status">
                      <option value="0">미사용</option>
                      <option value="1">사용</option>
                    </select>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>정렬순서 *</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="text" class="form-control" v-model="formData.ordering"/>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>안전출금 *</strong>
                  </div>
                  <div class="col-sm-5">
                    <select class="form-control" v-model="formData.safeWithdraw">
                      <option value="0">미사용</option>
                      <option value="1">사용</option>
                    </select>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>안전출금 대기시간</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="text" class="form-control" v-model="formData.safePendingHour"/>
                  </div>
                </div>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix text-center row">
                <div class="col-sm-3">
                </div>
                <div class="col-sm-5">
                  <button type="button" class="btn btn-primary mr-1" @click="setData()">수정</button>
                  <button type="button" class="btn btn-secondary" @click="$router.back()">목록</button>
                </div>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'CurrencyEdit',
  data() {
    return {
      currencyIdx: 0,
      formData: {
        name: '',
        ticker: '',
        symbol: '',
        maxDecimalFacto: '0',
        maxDecimalView: '0',
        minWithdrawAmount: '0',
        maxWithdrawAmount: '0',
        ordering: '1',
        safeWithdraw: '0',
        safePendingHour: '0',
        fee: '0',
        isFeePercentage: '0',
        status: '0',
      },
    }
  },
  created() {
    this.currencyIdx = this.$route.params.currencyIdx

    this.getData()
  },
  methods: {
    getData() {
      this.$get(this.$COIN_DETAIL.replace('?', this.currencyIdx), this.$options.name + '_getData', (result) => {
        this.getFormatData(this.formData, result)
        // this.formData.isFeePercentage = this.formData.isFeePercentage ? '1' : '0'
        this.formData.isFeePercentage = '0'
        this.formData.status = this.formData.status ? '1' : '0'
        this.formData.safeWithdraw = this.formData.safeWithdraw ? '1' : '0'
      }, (result) => {
        this.httpError(result)
      })
    },
    setData() {
      let formData = new FormData()
      Object.entries(this.formData).forEach((entry) => {
        let [key, value] = entry
        formData.append(key, (value+'').trim())
      })

      this.$put(this.$COIN_DETAIL.replace('?', this.currencyIdx), this.$options.name + '_setData', formData, (result) => {
        this.notify('success', result.message, false)

        this.$router.push({
          name: 'CurrencyList'
        })
      }, (result) => {
        this.httpError(result)
      })
    }
  }
}
</script>

<style>
</style>

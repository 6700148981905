<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">도구</h4>
            <span>사이트를 관리하기 위한 각종 도구를 제공합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">데이터 관리</h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <div class="row">
                  <div class="col-6">
                    <div class="m-3">
                      <div class="card-header border-bottom-0">
                        <h5 class="card-title">회원 일괄등록</h5>
                      </div><!-- /.card-header -->
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 text-right">
                          <strong>파일(xlsx, xls, csv)</strong>
                        </div>
                        <div class="col-sm-6">
                          <input type="file" class="form-control" @change="fileUpload($event)"/>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 text-right">
                        </div>
                        <div class="col-sm-6">
                          <button type="button" class="btn btn-success mr-1" @click="registerUser">등록</button>
                          <button type="button" class="btn btn-primary" @click="downloadSample">샘플 다운로드</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-6">
                    <div class="m-3">
                      <div class="card-header border-bottom-0">
                        <h5 class="card-title">회원 잔액보유량</h5>
                      </div><!-- /.card-header -->
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 text-right">
                          <strong>검색 옵션 *</strong>
                        </div>
                        <div class="col-sm-6">
                          <select class="form-control" v-model="userBalanceSelectedSearchType">
                            <option v-for="(balanceOption, index) in balanceOptionsSearch" :key="index" :value="balanceOption">{{ balanceOption | searchOptionFilter }}</option>
                          </select>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 text-right">
                          <strong>검색 내용 *</strong>
                        </div>
                        <div class="col-sm-6">
                          <input
                            :type="userBalanceSelectedSearchType === 'user_idx'
                            ? 'number'
                            : 'text'"
                            class="form-control" v-model="userBalanceSearchKeyword"/>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 text-right">
                        </div>
                        <div class="col-sm-6">
                          <button type="button" class="btn btn-success mr-1" @click="goUserBalance()">조회</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="m-3">
                      <div class="card-header border-bottom-0">
                        <h5 class="card-title">마켓 거래내역</h5>
                      </div><!-- /.card-header -->
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 text-right">
                          <strong>수집기간 *</strong>
                        </div>
                        <div class="col-sm-9 row">
                          <input type="date" class="form-control w-auto" v-model="marketHistory.startDate" @change="marketHistory.maxDate = $getMaxDate(marketHistory.startDate)"/>
                          <span class="m-2">~</span>
                          <input type="date" class="form-control w-auto" v-model="marketHistory.endDate" :min="marketHistory.startDate" :max="marketHistory.maxDate"/>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 text-right">
                        </div>
                        <div class="col-sm-6">
                          <button type="button" class="btn btn-success mr-1" @click="goMarketHistory()">조회</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-6">
                    <div class="m-3">
                      <div class="card-header border-bottom-0">
                        <h5 class="card-title">지갑 거래내역</h5>
                      </div><!-- /.card-header -->
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 text-right">
                          <strong>통화</strong>
                        </div>
                        <div class="col-sm-6">
                          <select class="form-control" v-model="walletHistory.currency">
                            <option value="">전체</option>
                            <option v-for="(currency, index) in currencies" :key="index" :value="currency.ticker">{{ currency.name }}</option>
                          </select>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 text-right">
                          <strong>구분</strong>
                        </div>
                        <div class="col-sm-6">
                          <select class="form-control" v-model="walletHistory.type">
                            <option value="">전체</option>
                            <option :value="true">출금</option>
                            <option :value="false">입금</option>
                          </select>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 text-right">
                          <strong>검색 옵션</strong>
                        </div>
                        <div class="col-sm-6">
                          <select class="form-control" v-model="walletHistory.selectedSearchType">
                            <option value="">없음</option>
                            <option v-for="(walletOption, index) in walletOptionsSearch" :key="index" :value="walletOption">{{ walletOption | walletHistoryOptionFilter }}</option>
                          </select>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 text-right">
                          <strong>검색 내용</strong>
                        </div>
                        <div class="col-sm-6">
                          <input type="text" class="form-control" v-model="walletHistory.searchKeyword"/>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 text-right">
                          <strong>수집기간 *</strong>
                        </div>
                        <div class="col-sm-9 row">
                          <input type="date" class="form-control w-auto" v-model="walletHistory.startDate" @change="walletHistory.maxDate = $getMaxDate(walletHistory.startDate)"/>
                          <span class="m-2">~</span>
                          <input type="date" class="form-control w-auto" v-model="walletHistory.endDate" :min="walletHistory.startDate" :max="walletHistory.maxDate"/>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 text-right">
                        </div>
                        <div class="col-sm-6">
                          <button type="button" class="btn btn-success mr-1" @click="goWalletHistory">조회</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="m-3">
                      <div class="card-header border-bottom-0">
                        <h5 class="card-title">OTC 거래내역</h5>
                      </div><!-- /.card-header -->
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 text-right">
                          <strong>수집기간 *</strong>
                        </div>
                        <div class="col-sm-9 row">
                          <input type="date" class="form-control w-auto" v-model="otcHistory.startDate" @change="otcHistory.maxDate = $getMaxDate(otcHistory.startDate)"/>
                          <span class="m-2">~</span>
                          <input type="date" class="form-control w-auto" v-model="otcHistory.endDate" :min="otcHistory.startDate" :max="otcHistory.maxDate"/>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 text-right">
                        </div>
                        <div class="col-sm-6">
                          <button type="button" class="btn btn-success mr-1" @click="goOtcHistory()">조회</button>
                        </div>
                      </div>
                    </div>
                    <div class="m-3">
                      <div class="card-header border-bottom-0">
                        <h5 class="card-title">마켓 사용자별 수수료 내역</h5>
                      </div><!-- /.card-header -->
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 text-right">
                          <strong>수집기간 *</strong>
                        </div>
                        <div class="col-sm-9 row">
                          <input type="date" class="form-control w-auto" v-model="marketUserFee.startDate" @change="marketUserFee.maxDate = $getMaxDate(marketUserFee.startDate)"/>
                          <span class="m-2">~</span>
                          <input type="date" class="form-control w-auto" v-model="marketUserFee.endDate" :min="marketUserFee.startDate" :max="marketUserFee.maxDate"/>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 text-right">
                        </div>
                        <div class="col-sm-6">
                          <button type="button" class="btn btn-success mr-1" @click="goMarketUserFee()">조회</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="m-3">
                      <div class="card-header border-bottom-0">
                        <h5 class="card-title">OTC 사용자별 수수료 내역</h5>
                      </div><!-- /.card-header -->
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 text-right">
                          <strong>수집기간 *</strong>
                        </div>
                        <div class="col-sm-9 row">
                          <input type="date" class="form-control w-auto" v-model="otcUserFee.startDate" @change="otcUserFee.maxDate = $getMaxDate(otcUserFee.startDate)"/>
                          <span class="m-2">~</span>
                          <input type="date" class="form-control w-auto" v-model="otcUserFee.endDate" :min="otcUserFee.startDate" :max="otcUserFee.maxDate"/>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 text-right">
                        </div>
                        <div class="col-sm-6">
                          <button type="button" class="btn btn-success mr-1" @click="goOtcUserFee()">조회</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div><!-- ./card-body -->
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'DataManagement',
  data() {
    return {
      userBalanceSelectedSearchType: 'user_idx',
      userBalanceSearchKeyword: '',

      fileData : [],

      balanceOptionsSearch: [], // 회원잔액 보유량 검색 옵션
      walletOptionsSearch: [], // 지갑 거래내역 검색 옵션

      currencies: [],

      marketHistory: {
        startDate: '',
        endDate: '',
        startTmpDate: '',
        endTmpDate: '',
        maxDate: '',
      },

      walletHistory: {
        currency: '',
        type: '',
        selectedSearchType: '',
        searchKeyword: '',
        startDate: '',
        endDate: '',
        startTmpDate: '',
        endTmpDate: '',
        maxDate: '',
      },

      otcHistory: {
        startDate: '',
        endDate: '',
        startTmpDate: '',
        endTmpDate: '',
        maxDate: '',
      },

      otcUserFee: {
        startDate: '',
        endDate: '',
        startTmpDate: '',
        endTmpDate: '',
        maxDate: '',
      },

      marketUserFee: {
        startDate: '',
        endDate: '',
        startTmpDate: '',
        endTmpDate: '',
        maxDate: '',
      }
    }
  },
  created() {
    this.$root.$emit(this.$EVENT_LNB_CHANGE, 'setting', this.$options.name)
    this.init()

  },
  methods: {
    init() {
      this.getOptionsSearch()
      this.marketHistory.maxDate = this.$getMaxDate(this.marketHistory.startDate)
      this.marketUserFee.maxDate = this.$getMaxDate(this.marketUserFee.startDate)
      this.walletHistory.maxDate = this.$getMaxDate(this.walletHistory.startDate)
      this.marketUserFee.maxDate = this.$getMaxDate(this.marketUserFee.startDate)
      this.walletHistory.maxDate = this.$getMaxDate(this.walletHistory.startDate)
      this.getCurrency()
    },
    getCurrency() {
      this.$get(this.$USER_FILTER, this.$options.name + '_getCurrency', (result) => {
        this.currencies = result.data.currency

      }, (result) => {
        this.httpError(result)
      })
    },
    getOptionsSearch() { // 검색 옵션
      this.$get(this.$TOOLS_OPTIONS_SEARCH, this.$options.name + '_getOptionsSearch', (result) => {
        this.balanceOptionsSearch = result.data.balance

        for(const value of result.data.wallet) {
          if(value === 'nullable') {
            continue
          }
          this.walletOptionsSearch.push(value)
        }

      }, (result) => {
        this.httpError(result)
      })
    },
    goUserBalance() { // 회원 잔액보유량
      if(this.userBalanceSelectedSearchType === '') {
        this.notify('error', '검색 옵션을 입력해주세요.', false)
        return
      }
      if(this.userBalanceSearchKeyword === '') {
        this.notify('error', '검색 내용을 입력해주세요.', false)
        return
      }

      this.$router.push({name: 'DataUserBalance', params: {criteria: this.userBalanceSelectedSearchType, keyword: this.userBalanceSearchKeyword }})
    },
    goMarketHistory() { // 마켓 거래내역
      if(this.marketHistory.startDate === '') {
        this.notify('error', '시작일을 입력해주세요.', false)
        return
      }
      if(this.marketHistory.endDate === '') {
        this.notify('error', '종료일을 입력해주세요.', false)
        return
      }

      this.$router.push({name: 'DataMarketHistory', params: {start: this.marketHistory.startTmpDate, end: this.marketHistory.endTmpDate }})
    },
    goWalletHistory() { // 지갑 거래내역
      if(this.walletHistory.selectedSearchType !== '' && this.walletHistory.searchKeyword === '') {
        this.notify('error', '검색 내용을 입력해주세요.', false)
        return
      }
      if(this.walletHistory.selectedSearchType !== '' && this.walletHistory.searchKeyword.length < 4) {
        this.notify('error', '검색 내용은 네 글자 이상 입력해주세요.', false)
        return
      }
      if(this.walletHistory.startDate === '') {
        this.notify('error', '시작일을 입력해주세요.', false)
        return
      }
      if(this.walletHistory.endDate === '') {
        this.notify('error', '종료일을 입력해주세요.', false)
        return
      }

      this.$router.push({
        name: 'DataWalletHistory',
        params: {
          primary: this.walletHistory.currency,
          isOut: this.walletHistory.type,
          criteria: this.walletHistory.selectedSearchType,
          keyword: this.walletHistory.searchKeyword,
          start: this.walletHistory.startTmpDate,
          end: this.walletHistory.endTmpDate,
        }
      })
    },
    goOtcHistory() { // OTC 거래내역
      if(this.otcHistory.startDate === '') {
        this.notify('error', '시작일을 입력해주세요.', false)
        return
      }
      if(this.otcHistory.endDate === '') {
        this.notify('error', '종료일을 입력해주세요.', false)
        return
      }

      this.$router.push({name: 'DataOtcHistory', params: {start: this.otcHistory.startTmpDate, end: this.otcHistory.endTmpDate }})
    },
    goMarketUserFee() { // 마켓 사용자별 수수료 내역
      if(this.marketUserFee.startDate === '') {
        this.notify('error', '시작일을 입력해주세요.', false)
        return
      }
      if(this.marketUserFee.endDate === '') {
        this.notify('error', '종료일을 입력해주세요.', false)
        return
      }

      this.$router.push({name: 'DataMarketUserFee', params: {start: this.marketUserFee.startTmpDate, end: this.marketUserFee.endTmpDate }})
    },
    goOtcUserFee() { // P2P 사용자별 수수료 내역
      if(this.otcUserFee.startDate === '') {
        this.notify('error', '시작일을 입력해주세요.', false)
        return
      }
      if(this.otcUserFee.endDate === '') {
        this.notify('error', '종료일을 입력해주세요.', false)
        return
      }

      this.$router.push({name: 'DataOtcUserFee', params: {start: this.otcUserFee.startTmpDate, end: this.otcUserFee.endTmpDate }})
    },
    registerUser() {
      let formData = new FormData()
      formData.append('file', this.fileData)

      this.$post(this.$TOOLS_USER, this.$options.name + '_registerUser', formData, (result) => {
        this.fileData = []
        this.notify('success', result.message, false)
      }, (result) => {
        this.httpError(result)
      })
    },
    downloadSample() {
      axios({
        method: 'GET',
        url: `${this.$DOMAIN}${this.$TOOLS_EXAMPLE}`,
        responseType: 'blob',
        headers: {'Authorization': 'Bearer ' + sessionStorage.getItem('token')},
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data], {type: response.headers['content-type']}))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'userSample.xlsx')
        document.body.appendChild(link)
        link.click()
      }).catch((error) => {
        console.log(error)
      })
    },
    fileUpload(event) {
      let file = event.target.files
      if(file.length < 0) {
        return
      }
      this.fileData = file[0]
    },
  },
  watch: {
    'marketHistory.startDate'() {
      this.marketHistory.startTmpDate = this.$watchStartDate(this.marketHistory.startDate)
    },
    'marketHistory.endDate'() {
      this.marketHistory.endTmpDate = this.$watchEndDate(this.marketHistory.endDate)
    },

    'walletHistory.startDate'() {
      this.walletHistory.startTmpDate = this.$watchStartDate(this.walletHistory.startDate)
    },
    'walletHistory.endDate'() {
      this.walletHistory.endTmpDate = this.$watchEndDate(this.walletHistory.endDate)
    },

    'otcHistory.startDate'() {
      this.otcHistory.startTmpDate = this.$watchStartDate(this.otcHistory.startDate)
    },
    'otcHistory.endDate'() {
      this.otcHistory.endTmpDate = this.$watchEndDate(this.otcHistory.endDate)
    },

    'marketUserFee.startDate'() {
      this.marketUserFee.startTmpDate = this.$watchStartDate(this.marketUserFee.startDate)
    },
    'marketUserFee.endDate'() {
      this.marketUserFee.endTmpDate = this.$watchEndDate(this.marketUserFee.endDate)
    },

    'otcUserFee.startDate'() {
      this.otcUserFee.startTmpDate = this.$watchStartDate(this.otcUserFee.startDate)
    },
    'otcUserFee.endDate'() {
      this.otcUserFee.endTmpDate = this.$watchEndDate(this.otcUserFee.endDate)
    },
  },
  filters: {
    searchOptionFilter: function(value) {
      if(value === 'nickname') {
        return '닉네임'
      } else if(value === 'phone') {
        return '휴대폰'
      } else if(value === 'email') {
        return '이메일'
      } else if(value === 'user_idx') {
        return '회원번호'
      }
    },
    walletHistoryOptionFilter: function (value) {
      if(value === 'nullable') {
        return '없음'
      } else if(value === 'tx_number') {
        return '트랜잭션 번호'
      } else if(value === 'walletaddress') {
        return '지갑주소'
      }
    },
  }
}
</script>

<style>
</style>

<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">마켓</h4>
            <span> 데이터베이스에 기록된 마켓 관련 항목을 확인합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->
        <div class="row mb-2">
          <div class="col-sm-12">
            <div class="row">
              <div class="ml-auto">
                <button class="btn btn-success" @click="goRegistPair()">새 마켓 추가</button>
              </div>
            </div>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">OTC 마켓</h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th rowspan="2">ID</th>
                      <th rowspan="2">통화</th>
                      <th colspan="2">수수료</th>
  <!--                    <th colspan="3">제한</th>-->
                      <th rowspan="2">상태</th>
                      <th rowspan="2">정렬순서</th>
                      <th rowspan="2">액션</th>
                    </tr>
                    <tr>
                      <th>매도</th>
                      <th>매수</th>
  <!--                    <th>제한시간</th>-->
  <!--                    <th>하루판매수량</th>-->
  <!--                    <th>1회판매수량</th>-->
                    </tr>
                  </thead>
                  <tbody v-if="items.length > 0">
                    <tr v-for="(item, index) in items" :key="index">
                      <td>{{ item.idx }}</td>
                      <td>{{ item.primary }}</td>
                      <td>{{ $big(item.sellFee).mul(100).toFixed(3) }}</td>
                      <td>{{ $big(item.buyFee).mul(100).toFixed(3) }}</td>
  <!--                    <td>{{ item.openTime ? item.openTime.replace(/\-/gi, ' ~ ').replace(/\+/gi, ', ') : '제한없음' }}</td>-->
  <!--                    <td>{{ item.maxDaySellAmount ? item.maxDaySellAmount : '제한없음' }}</td>-->
  <!--                    <td>{{ item.maxOneOrderSellAmount ? item.maxOneOrderSellAmount : '제한없음' }}</td>-->
                      <td>{{ item.status ? '활성' : '비활성' }}</td>
                      <td>{{ item.ordering }}</td>
                      <td class="text-center">
                        <span class="badge btn btn-primary" @click="goDetailPage(item.idx)">수정</span>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="7" style="text-align: center;">관련된 내역이 없습니다.</td>
                    </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix">
                <pagination ref="Pagination"></pagination>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>

import Pagination from "../common/Pagination"

export default {
  name: 'OtcPairList',
  components: {
    Pagination,
  },
  data() {
    return {
      size: '10',
      page: 0,
      total: 0,

      //data
      items: [],
    }
  },
  created() {
    this.$root.$emit(this.$EVENT_LNB_CHANGE, 'marketPair', this.$options.name)

    let page = this.$route.query.page
    if(typeof(page) === 'undefined') {
      page = 1
    }

    let size = this.$route.query.size
    if(typeof(size) !== 'undefined') {
      this.size = size
    }

    this.getList(page)
  },
  methods: {
    getList(page) {
      let params = new URLSearchParams()
      if(typeof page === 'undefined') page = 1
      params.append('page', page)
      params.append('limit', this.size)
      params.append('marketType', 'OTC')

      this.$get(this.$MARKET + '?' + params.toString(), this.$options.name + '_getList', (result) => {
        this.page = page
        this.total = result.total

        try {
          this.$refs.Pagination.init(this.$options.name, {
          }, Number(this.page), this.total, Number(this.size))
        } catch (e) {
          console.log(e)
        }

        this.items = result.data
      }, (result) => {
        this.httpError(result)
      })
    },
    goRegistPair() {
      this.$router.push({
        name: 'OtcPairRegist'
      })
    },
    goDetailPage(_item) {
      this.$router.push({
        name: 'OtcPairEdit',
        params: {
          otcPairIdx: _item
        }
      })
    }
  },
  watch: {
    '$route.query.page'() {
      this.getList(this.$route.query.page)
    },
  }
}
</script>

<style>
</style>

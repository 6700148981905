<template>
  <div class="login-page dark-mode">
    <div class="login-box">
      <div class="login-logo">
        <a><b>UBIT ADMIN</b></a>
      </div>
      <!-- /.login-logo -->
      <div class="card">
        <div class="card-body login-card-body">
          <p class="login-box-msg">관리자 로그인</p>
          <div class="input-group mb-3">
            <input type="email" class="form-control" placeholder="Email" v-model="email">
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-id-badge"></span>
              </div>
            </div>
          </div>
          <div class="input-group mb-3">
            <input type="password" class="form-control" placeholder="Password" v-model="password" @keyup.enter="request2FA()">
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-lock"></span>
              </div>
            </div>
          </div>
          <div class="social-auth-links text-center mb-3" v-if="!otpOn">
            <a class="btn btn-block btn-primary" @click="request2FA()">
              2FA Code Request
            </a>
          </div>
          <div v-else>
            <div class="input-group mb-3">
              <input type="password" class="form-control" placeholder="2FA" v-model="certCode" @keyup.enter="login()">
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-lock"></span>
                </div>
              </div>
            </div>
            <div class="social-auth-links text-center mb-3">
              <a class="btn btn-block btn-primary" @click="login()">
                Sign In
              </a>
            </div>
          </div>
        </div>
        <!-- /.login-card-body -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
      otpOn: false,
      verifyCode: '',
      certCode: '',
      authMethod: '',
    }
  },
  created() {
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('refresh_token')
    sessionStorage.removeItem('my_info')
  },
  methods: {
    request2FA() {
      if (this.email === '') {
        this.notify('error', '이메일을 입력해주세요.', false)
      }
      if (this.password === '') {
        this.notify('error', '비밀번호를 입력해주세요.', false)
      }
      let formData = new FormData()
      formData.append('email ', this.email.trim())
      formData.append('password ', this.password.trim())
      this.$post(this.$AUTH_LOGIN, 'login_request2fa', formData, (result) => {
        if (result.data['authMethod'] === 'PHONE') {
          this.verifyCode = result.data['verifyCode']
          this.authMethod = result.data['authMethod']

          let formData1 = new FormData()
          formData1.append('verifyCode ', this.verifyCode)
          this.$post(this.$AUTH_LOGIN_PHONE_SEND, 'login_request2fa', formData1, (result) => {
            this.notify('success', result.message, false)
            this.otpOn = true
          }, (result) => {
            this.httpError(result)
          })
        } else if (result.data['authMethod'] === 'OTP') {
          this.verifyCode = result.data['verifyCode']
          this.authMethod = result.data['authMethod']

          this.otpOn = true
        }
      }, (result) => {
        this.httpError(result)
      })
    },
    login() {
      if (this.verifyCode !== '') {
        if (this.authMethod === 'PHONE') {
          let formData = new FormData()
          formData.append('verifyCode ', this.verifyCode)
          formData.append('certCode ', this.certCode)

          this.$post(this.$AUTH_LOGIN_PHONE_CERT, 'login_login', formData, (result) => {
            sessionStorage.setItem('token', result.data.accessToken)
            sessionStorage.setItem('refresh_token', result.data.refreshToken)
            sessionStorage.setItem('my_info', JSON.stringify(result.data.admin))

            setTimeout(() => {
              this.refreshToken()
            }, this.refreshTokenRate)

            this.$router.replace({name: 'Dashboard'})
          }, (result) => {
            this.httpError(result)
          })
        } else if (this.authMethod === 'OTP') {
          let formData = new FormData()
          formData.append('verifyCode ', this.verifyCode)
          formData.append('code ', this.certCode)

          this.$post(this.$AUTH_LOGIN_OTP_CERT, 'login_login', formData, (result) => {
            sessionStorage.setItem('token', result.data.accessToken)
            sessionStorage.setItem('refresh_token', result.data.refreshToken)
            sessionStorage.setItem('my_info', JSON.stringify(result.data.admin))

            setTimeout(() => {
              this.refreshToken()
            }, this.refreshTokenRate)

            this.$router.replace({name: 'Dashboard'})
          }, (result) => {
            this.httpError(result)
          })
        }
      }
    }
  }
}
</script>

<style>
</style>

<template>
  <div class="mt-5 m-3">
    <div class="card-header border-bottom-0">
      <h5 class="card-title">FLAG</h5>
    </div><!-- /.card-header -->
    <div class="row align-items-center mb-2">
      <div class="col-sm-3 text-right">
        <strong>출금 기능</strong>
      </div>
      <div class="col-sm-6">
        <select class="form-control" v-model="formData.withdraw">
          <option :value="true">허용</option>
          <option :value="false">제한</option>
        </select>
      </div>
    </div>
    <div class="row align-items-center mb-2">
      <div class="col-sm-3 text-right">
        <strong>거래 기능</strong>
      </div>
      <div class="col-sm-6">
        <select class="form-control" v-model="formData.trade">
          <option :value="true">허용</option>
          <option :value="false">제한</option>
        </select>
      </div>
    </div>
    <div class="row align-items-center mb-2">
      <div class="col-sm-3 text-right">
        <strong>로그인 기능</strong>
      </div>
      <div class="col-sm-6">
        <select class="form-control" v-model="formData.login">
          <option :value="true">허용</option>
          <option :value="false">제한</option>
        </select>
      </div>
    </div>
    <div class="row align-items-center mb-2">
      <div class="col-sm-3 text-right">
        <strong>전체 기능</strong>
      </div>
      <div class="col-sm-6">
        <select class="form-control" v-model="formData.total">
          <option :value="true">허용</option>
          <option :value="false">제한</option>
        </select>
      </div>
    </div>
    <div class="row align-items-center mb-2">
      <div class="col-sm-3 text-right">
        <strong>활성화 기능</strong>
      </div>
      <div class="col-sm-6">
        <select class="form-control" v-model="formData.active">
          <option :value="true">허용</option>
          <option :value="false">제한</option>
        </select>
      </div>
    </div>
    <div class="row align-items-center mb-2">
      <div class="col-sm-3 text-right">
      </div>
      <div class="col-sm-6">
        <button type="button" class="btn btn-primary" @click="setData()">FLAG 수정</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserDetailFlag",
  props: [
    'userIdx',
  ],
  data() {
    return {
      formData: {
        withdraw: false,
        trade: false,
        login: false,
        total: false,
        active: true,
      }
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$get(this.$USER_STATUS.replace('?', this.userIdx), this.$options.name + '_getData', (result) => {
        this.getFormatData(this.formData, result)
      }, (result) => {
        this.httpError(result)
      })
    },
    setData() {
      let formData = new FormData()
      Object.entries(this.formData).forEach((entry) => {
        let [key, value] = entry
        formData.append(key, (value+'').trim())
      })

      this.$put(this.$USER_STATUS.replace('?', this.userIdx), this.$options.name + '_setData', formData, () => {
        if(this.formData.total === true && this.formData.withdraw === false && this.formData.trade === false && this.formData.login === false) {
          this.notify('error', '전체 기능이 제한된 상태입니다. 다시 확인해주세요.', false)
          this.getData()
          return
        }
        this.notify('success', 'FLAG 설정이 바뀌었습니다.', false)
        this.getData()
      }, (result) => {
        this.httpError(result)
        this.getData()
      })
    },
  },
}
</script>

<style scoped>

</style>

<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">도구</h4>
            <span> 사이트를 관리하기 위한 각종 도구를 제공합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->
        <div class="row mb-2">
          <div class="col-sm-12">
            <div class="row">
              <select class="form-control w-auto mr-1" v-model="size">
                <option value="10">10줄</option>
                <option value="20">20줄</option>
                <option value="50">50줄</option>
                <option value="100">100줄</option>
              </select>
              <button class="btn btn-primary" @click="getList(page)">적용</button>
              <div class="ml-auto">
                <button class="btn btn-success" @click="goRegist()">새 게시판 추가</button>
              </div>
            </div>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">게시판 관리</h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <table class="table table-bordered">
                  <thead>
                  <tr>
                    <th>ID</th>
                    <th>언어</th>
                    <th>게시판명</th>
                    <th>게시판 코드</th>
                    <th>액션</th>
                  </tr>
                  </thead>
                  <tbody v-if="items.length > 0">
                    <tr v-for="(item, index) in items" :key="index">
                      <td>{{ item.idx }}</td>
                      <td>{{ item.language }}</td>
                      <td>{{ item.boardTitle }}</td>
                      <td>{{ item.code }}</td>
                      <td class="text-center">
                        <span class="badge btn btn-secondary mr-1" @click="goDetailPage(item.idx)">목록</span>
                        <span class="badge btn btn-primary mr-1" @click="goEdit(item.idx)">수정</span>
                        <span class="badge btn btn-outline-danger" @click="deleteData(item.idx)">삭제</span>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="5" style="text-align: center;">관련된 내역이 없습니다.</td>
                    </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix">
                <pagination ref="Pagination"></pagination>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>

import Pagination from "../../common/Pagination"

export default {
  name: 'BoardManageList',
  components: {
    Pagination,
  },
  data() {
    return {
      size: '10',
      page: 0,
      total: 0,

      //data
      items: []
    }
  },
  created() {
    this.$root.$emit(this.$EVENT_LNB_CHANGE, 'setting', this.$options.name)

    let page = this.$route.query.page
    if(typeof(page) === 'undefined') {
      page = 1
    }

    let size = this.$route.query.size
    if(typeof(size) !== 'undefined') {
      this.size = size
    }

    this.getList(page)
  },
  methods: {
    getList(page) {
      let params = new URLSearchParams()
      if(typeof page === 'undefined') page = 1
      params.append('page', page)
      params.append('limit', this.size)

      this.$get(this.$TOOLS_BOARD + '?' + params.toString(), this.$options.name + '_getList', (result) => {
        this.page = page
        this.total = result.total

        try {
          this.$refs.Pagination.init(this.$options.name, {
          }, Number(this.page), this.total, Number(this.size))
        } catch (e) {
          console.log(e)
        }

        this.items = result.data
      }, (result) => {
        this.httpError(result)
      })
    },
    deleteData(_item) {
      this.$delete(this.$TOOLS_BOARD_DETAIL.replace('?', _item), this.$options.name + '_deleteData', (result) => {
        this.notify('success', result.message, false)

        this.getList(this.page)
      }, (result) => {
        this.httpError(result)
      })
    },
    goDetailPage(_item) {
      this.$router.push({name: 'BoardContentList', params: {boardIdx: _item}})
    },
    goEdit(_item) {
      this.$router.push({name: 'BoardManageEdit', params: {boardIdx: _item}})
    },
    goRegist() {
      this.$router.push({name: 'BoardManageRegist'})
    },
  },
  watch: {
    '$route.query.page'() {
      this.getList(this.$route.query.page)
    },
  }
}
</script>

<style>
</style>

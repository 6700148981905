<template>
  <div class="m-3">
    <div class="card-header border-bottom-0">
      <h5 class="card-title mr-3 mt-2">입출금 내역</h5>
      <button class="badge btn btn-primary ml-2" @click="goMore()">더보기</button>
    </div><!-- /.card-header -->
    <div class="row align-items-center mb-2">
      <table class="table table-bordered">
        <thead>
        <tr>
          <th>통화</th>
          <th>타입</th>
          <th>수량</th>
          <th>최종일자</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>{{ item.tickerName }}</td>
          <td>{{ E_TransferType[item.type] }}</td>
          <td>{{ item.amount }}</td>
          <td>{{ item.createdAt | moment('YYYY-MM-DD HH:mm:ss') }}</td>
        </tr>
        <tr v-if="items.length === 0">
          <td colspan="4" style="text-align: center;">관련된 내역이 없습니다.</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserDetailWalletHistory",
  props: [
    'userIdx',
  ],
  data() {
    return {
      items: []
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      let params = new URLSearchParams()
      params.append('page', 1)
      params.append('limit', 10)
      params.append('ticker', '')
      params.append('isDeposit', '')
      params.append('processState', '')
      params.append('criteria', 'user_idx')
      params.append('keyword', this.userIdx)

      this.$get(this.$TRANSFER + '?' + params.toString(), this.$options.name + '_getList', (result) => {
        this.items = result.data
      }, (result) => {
        this.httpError(result)
      })
    },
    goMore() {
      this.$router.push({
        name: 'WalletTransferList',
        query: {
          st: 'user_idx',
          sk: this.userIdx
        }
      })
    },
  }
}
</script>

<style scoped>

</style>

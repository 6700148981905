<template>
  <div class="dots-loader"></div>
</template>

<script>
export default {
  name: "Loader.vue"
}
</script>

<style scoped>
/*custom loader*/
.dots-loader:not(:required){position:absolute;z-index:1000000;top:50%;left:50%;display:inline-block;overflow:hidden;width:8px;height:8px;margin-top:-4px;margin-left:-4px;transition:.3s all;transform:scale(1);transform-origin:50% 50%;animation:dots-loader 5s infinite ease-in-out;text-indent:-9999px;opacity:1;border-radius:100%;background:transparent;box-shadow:#f86 -14px -14px 0 7px, #fc6 14px -14px 0 7px, #6d7 14px 14px 0 7px, #4ae -14px 14px 0 7px;}
@keyframes dots-loader {
  0%{box-shadow:#f86 -14px -14px 0 7px, #fc6 14px -14px 0 7px, #6d7 14px 14px 0 7px, #4ae -14px 14px 0 7px;}
  8.33%{box-shadow:#f86 14px -14px 0 7px, #fc6 14px -14px 0 7px, #6d7 14px 14px 0 7px, #4ae -14px 14px 0 7px;}
  16.67%{box-shadow:#f86 14px 14px 0 7px, #fc6 14px 14px 0 7px, #6d7 14px 14px 0 7px, #4ae -14px 14px 0 7px;}
  25%{box-shadow:#f86 -14px 14px 0 7px, #fc6 -14px 14px 0 7px, #6d7 -14px 14px 0 7px, #4ae -14px 14px 0 7px;}
  33.33%{box-shadow:#f86 -14px -14px 0 7px, #fc6 -14px 14px 0 7px, #6d7 -14px -14px 0 7px, #4ae -14px -14px 0 7px;}
  41.67%{box-shadow:#f86 14px -14px 0 7px, #fc6 -14px 14px 0 7px, #6d7 -14px -14px 0 7px, #4ae 14px -14px 0 7px;}
  50%{box-shadow:#f86 14px 14px 0 7px, #fc6 -14px 14px 0 7px, #6d7 -14px -14px 0 7px, #4ae 14px -14px 0 7px;}
  58.33%{box-shadow:#f86 -14px 14px 0 7px, #fc6 -14px 14px 0 7px, #6d7 -14px -14px 0 7px, #4ae 14px -14px 0 7px;}
  66.67%{box-shadow:#f86 -14px -14px 0 7px, #fc6 -14px -14px 0 7px, #6d7 -14px -14px 0 7px, #4ae 14px -14px 0 7px;}
  75%{box-shadow:#f86 14px -14px 0 7px, #fc6 14px -14px 0 7px, #6d7 14px -14px 0 7px, #4ae 14px -14px 0 7px;}
  83.33%{box-shadow:#f86 14px 14px 0 7px, #fc6 14px -14px 0 7px, #6d7 14px 14px 0 7px, #4ae 14px 14px 0 7px;}
  91.67%{box-shadow:#f86 -14px 14px 0 7px, #fc6 14px -14px 0 7px, #6d7 14px 14px 0 7px, #4ae -14px 14px 0 7px;}
  100%{box-shadow:#f86 -14px -14px 0 7px, #fc6 14px -14px 0 7px, #6d7 14px 14px 0 7px, #4ae -14px 14px 0 7px;}
}
</style>
<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">마켓</h4>
            <span> 데이터베이스에 기록된 마켓 관련 항목을 확인합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">마켓</h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>주거래 화폐 *</strong>
                  </div>
                  <div class="col-sm-5">
                    <select class="form-control" v-model="formData.primary">
                      <option v-for="(currency, index) in currencyList" :key="index" :value="currency.name">{{ currency.name }}</option>
                    </select>
                  </div>
                  <div class="col-sm-4">
                    <span>마켓 거래시 매도,매수로 사용할 통화</span>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>부거래 화폐 *</strong>
                  </div>
                  <div class="col-sm-5">
                    <select class="form-control" v-model="formData.secondary">
                      <option v-for="(currency, index) in currencyList" :key="index" :value="currency.name">{{ currency.name }}</option>
                    </select>
                  </div>
                  <div class="col-sm-4">
                    <span>마켓 거래시 지불에 사용할 통화</span>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>거래 소수점 자리수</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="text" class="form-control" v-model="formData.minDecimal"/>
                  </div>
                  <div class="col-sm-4">
                    <span></span>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>판매 수수료</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="text" class="form-control" v-model="formData.sellFee"/>
                  </div>
                  <div class="col-sm-4">
                    <span>매도 거래시 적용되는 수수료 부통화 % 적용</span>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>구매 수수료</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="text" class="form-control" v-model="formData.buyFee"/>
                  </div>
                  <div class="col-sm-4">
                    <span>매수 거래시 적용되는 수수료중 주통화 % 적용</span>
                  </div>
                </div>
<!--                <div class="row align-items-center mb-2">-->
<!--                  <div class="col-sm-3 text-right">-->
<!--                    <strong>수수료 적용 방식</strong>-->
<!--                  </div>-->
<!--                  <div class="col-sm-5">-->
<!--                    <select class="form-control" v-model="formData.isPrecalculatedFee">-->
<!--                      <option value="0">후차감</option>-->
<!--                      <option value="1">선차감</option>-->
<!--                    </select>-->
<!--                  </div>-->
<!--                  <div class="col-sm-4">-->
<!--                    <span>수수료 적용 여부를 설정합니다.</span>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="row align-items-center mb-2">-->
<!--                  <div class="col-sm-3 text-right">-->
<!--                    <strong>거래 제한시간</strong>-->
<!--                  </div>-->
<!--                  <div class="col-sm-5">-->
<!--                    <input type="text" class="form-control" v-model="formData.openTime"/>-->
<!--                  </div>-->
<!--                  <div class="col-sm-4">-->
<!--                    <span>예) 06:00-24:00+01:00-04:00</span>-->
<!--                  </div>-->
<!--                </div>-->
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>최소 구매 수량</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="text" class="form-control" v-model="formData.minBuyAmount"/>
                  </div>
                  <div class="col-sm-4">
                    <span></span>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>최소 판매 수량</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="text" class="form-control" v-model="formData.minSellAmount"/>
                  </div>
                  <div class="col-sm-4">
                    <span></span>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>1일 최대 매도가능 수량</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="text" class="form-control" v-model="formData.maxDaySellAmount"/>
                  </div>
                  <div class="col-sm-4">
                    <span></span>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>1일 최대 매도가능 횟수</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="text" class="form-control" v-model="formData.maxDaySellCount"/>
                  </div>
                  <div class="col-sm-4">
                    <span></span>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>1회 주문시 최대 매도가능 수량</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="text" class="form-control" v-model="formData.maxOneOrderSellAmount"/>
                  </div>
                  <div class="col-sm-4">
                    <span></span>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>최소 호가 제한</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="text" class="form-control" v-model="formData.minBidPrice"/>
                  </div>
                  <div class="col-sm-4">
                    <span></span>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>최대 호가 제한</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="text" class="form-control" v-model="formData.maxAskPrice"/>
                  </div>
                  <div class="col-sm-4">
                    <span></span>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>상태 *</strong>
                  </div>
                  <div class="col-sm-5">
                    <select class="form-control" v-model="formData.status">
                      <option :value=false>비활성</option>
                      <option :value=true>활성</option>
                    </select>
                  </div>
                  <div class="col-sm-4">
                    <span></span>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>정렬순서 *</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="text" class="form-control" v-model="formData.ordering"/>
                  </div>
                  <div class="col-sm-4">
                    <span></span>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>수수료 부통화로 지정 *</strong>
                  </div>
                  <div class="col-sm-5">
                    <select class="form-control" v-model="formData.useDesignatedFee">
                      <option :value=false>비활성</option>
                      <option :value=true>활성</option>
                    </select>
                  </div>
                  <div class="col-sm-4">
                    <span></span>
                  </div>
                </div>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix text-center row">
                <div class="col-sm-3">
                </div>
                <div class="col-sm-5">
                  <button type="button" class="btn btn-primary mr-1" @click="newData()">저장</button>
                  <button type="button" class="btn btn-secondary" @click="$router.back()">목록</button>
                </div>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'MarketPairRegist',
  data() {
    return {
      //search filter
      marketPairList: [],
      currencyList: [],

      formData: {
        primary: 'eth',
        secondary: 'btc',
        minDecimal: '4',
        buyFee: '0.1',
        sellFee: '0.1',
        isPrecalculatedFee: '0', // 후차감
        openTime: '',
        minBuyAmount: '0.0001',
        minSellAmount: '0.0001',
        maxAskPrice: '0',
        maxDaySellAmount: '0',
        maxDaySellCount: '0',
        maxOneOrderSellAmount: '0',
        minBidPrice: '0',
        status : false,
        ordering: '1',
        useDesignatedFee: false,
      },
    }
  },
  created() {
    this.getFilterData(() => {})
  },
  methods: {
    getFilterData(callback) {
      this.$get(this.$MARKET_FILTER, this.$options.name + '_getFilterData', (result) => {
        this.currencyList = result.data.currency

        callback()
      }, (result) => {
        this.httpError(result)
      })
    },
    newData() {
      let formData = new FormData()
      Object.entries(this.formData).forEach((entry) => {
        let [key, value] = entry
        formData.append(key, (value+'').trim())
      })
      formData.append('marketType', 'SPOT')

      this.$post(this.$MARKET, this.$options.name + '_newData', formData, (result) => {
        this.notify('success', result.message, false)

        this.$router.push({
          name: 'MarketPairList'
        })
      }, (result) => {
        this.httpError(result)
      })
    },
  },
}
</script>

<style>
</style>

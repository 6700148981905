<template>
  <div class="m-3">
    <div class="card-header border-bottom-0">
      <h5 class="card-title">지갑 계정</h5>
    </div><!-- /.card-header -->
    <div class="row align-items-center mb-2">
      <table class="table table-bordered">
        <thead>
        <tr>
          <th>통화</th>
          <th>상세</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in items" :key="index" v-show="item.transferMethod === 'RPC' || item.transferMethod === 'RPCSUB'">
          <td>{{ item.ticker }}</td>
          <td>
            <span class="badge badge-warning mr-2">{{ item.ticker }}-{{ item.transferMethod }}</span>
            <span class="mr-2">{{ item.walletData.address }}</span>
          </td>
        </tr>
        <tr v-if="items.length === 0">
          <td colspan="2" style="text-align: center;">관련된 내역이 없습니다.</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserDetailWallet",
  props: [
    'userIdx',
  ],
  data() {
    return {
      items: [],
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$get(this.$USER_WALLET_DETAIL.replace('?', this.userIdx), this.$options.name + '_getData', (result) => {
        this.items = result.data

        for (let i = 0; i < this.items.length; i++) {
          this.items[i].walletData = JSON.parse(this.items[i].walletData)
        }
      }, (result) => {
        this.httpError(result)
      })
    },
  }
}
</script>

<style scoped>

</style>

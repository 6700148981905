<template>
  <div class="m-3">
    <div class="card-header border-bottom-0">
      <h5 class="card-title mr-3 mt-2">OTC 매도 게시글</h5>
<!--      <button class="badge btn btn-primary ml-2" @click="goMore()">더보기</button>-->
    </div><!-- /.card-header -->
    <div class="row align-items-center">
      <table class="table table-bordered">
        <thead>
        <tr>
          <th>게시글 번호</th>
          <th>거래코인</th>
          <th>제목</th>
          <th>등록일시</th>
          <th>상태</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>{{ item.idx }}</td>
          <td>{{ item.ticker.includes('UNLISTED') ? `${item.ticker.split('-')[1]}(직접거래)` : item.ticker }}</td>
          <td>{{ item.title }}</td>
          <td>{{ item.createdAt | moment('YYYY-MM-DD HH:mm:ss') }}</td>
          <td>{{ E_P2PState[item.state] }}</td>
        </tr>
        <tr v-if="items.length === 0">
          <td colspan="5" style="text-align: center;">관련된 내역이 없습니다.</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserDetailOtcSellList",
  props: [
    'userIdx',
    'userEmail'
  ],
  data() {
    return {
      items: []
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      let formData = new FormData()
      formData.append('page', 1)
      formData.append('limit', 10)
      formData.append('isBuy', false)
      formData.append('criteria', 'email')
      formData.append('keyword', this.userEmail)

      this.$post(this.$OTC_SUGGESTION, this.$options.name + '_getData', formData,(result) => {
        this.items = result.data
      }, (result) => {
        this.httpError(result)
      })
    },
  }
}
</script>

<style scoped>

</style>

import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

import Intro from "@/components/Intro"

import AdminRegist from "@/components/admin/AdminRegist"
import AdminEdit from "@/components/admin/AdminEdit"
import AdminLoginHistory from "@/components/admin/AdminLoginHistory"
import AdminManage from "@/components/admin/AdminManage"

import CurrencyEdit from "@/components/currency/CurrencyEdit"
import CurrencyRegist from "@/components/currency/CurrencyRegist"
import CurrencyList from "@/components/currency/CurrencyList"

import Dashboard from "@/components/dashboard/Dashboard"

import GoogleOTP from "@/components/etc/GoogleOTP"

import Login from "@/components/Login"

import MarketBuyHistory from "@/components/marketHistory/MarketBuyHistory"
import MarketSellHistory from "@/components/marketHistory/MarketSellHistory"
import MarketConcludeHistory from "@/components/marketHistory/MarketConcludeHistory"

import MarketPairRegist from "@/components/marketPair/MarketPairRegist"
import MarketPairEdit from "@/components/marketPair/MarketPairEdit"
import MarketPairList from "@/components/marketPair/MarketPairList"
import OtcPairRegist from "@/components/marketPair/OtcPairRegist"
import OtcPairEdit from "@/components/marketPair/OtcPairEdit"
import OtcPairList from "@/components/marketPair/OtcPairList"

import BoardContentRegist from "@/components/setting/boardManage/BoardContentRegist"
import BoardContentEdit from "@/components/setting/boardManage/BoardContentEdit"
import BoardContentList from "@/components/setting/boardManage/BoardContentList"
import BoardManageRegist from "@/components/setting/boardManage/BoardManageRegist"
import BoardManageEdit from "@/components/setting/boardManage/BoardManageEdit"
import BoardManageList from "@/components/setting/boardManage/BoardManageList"

import DataManagement from "@/components/setting/dataManage/DataManagement"
import DataMarketHistory from "@/components/setting/dataManage/DataMarketHistory"
import DataMarketUserFee from "@/components/setting/dataManage/DataMarketUserFee"
import DataOtcHistory from "@/components/setting/dataManage/DataOtcHistory"
import DataOtcUserFee from "@/components/setting/dataManage/DataOtcUserFee"
import DataUserBalance from "@/components/setting/dataManage/DataUserBalance"
import DataWalletHistory from "@/components/setting/dataManage/DataWalletHistory"

import MarketSettleCalendar from "@/components/setting/marketSettle/MarketSettleCalendar"
import MarketSettleExcel from "@/components/setting/marketSettle/MarketSettleExcel"
import MarketSettleList from "@/components/setting/marketSettle/MarketSettleList"

import SiteSetting from "@/components/setting/SiteSetting"
import SiteStatistics from "@/components/setting/SiteStatistics"

import AddressWhiteList from "@/components/user/AddressWhiteList"
import UserBlackList from "@/components/user/UserBlackList"
import UserDetail from "@/components/user/UserDetail"
import UserRegist from "@/components/user/UserRegist"
import UserIpManage from "@/components/user/UserIpManage"
import UserLoginHistory from "@/components/user/UserLoginHistory"
import UserManage from "@/components/user/UserManage"
import UserRemoveList from "@/components/user/UserRemoveList"

import WalletTransferList from "@/components/wallet/WalletTransferList"
import WalletSafeWithdrawList from "@/components/wallet/WalletSafeWithdrawList"
import OtcSuggestionList from "../components/otcSuggestion/OtcSuggestionList";
import OtcSuggestionDetail from "../components/otcSuggestion/OtcSuggestionDetail";
import MarketPairEtcSetting from "../components/marketPair/MarketPairEtcSetting";
import BotList from "@/components/bot/BotList"
import BotTradeList from "../components/bot/BotTradeList";
import BotTradeStatistics from "../components/bot/BotTradeStatistics";
import BotRegist from "../components/bot/BotRegist";
import OtcClaimList from "../components/otcHistory/OtcClaimList";
import OtcClaimDetail from "../components/otcHistory/OtcClaimDetail";
import OtcSuggestionTrading from "../components/otcSuggestion/OtcSuggestionTrading";
import OtcSuggestionChat from "../components/otcSuggestion/OtcSuggestionChat";
import BotEdit from "../components/bot/BotEdit";

export default new Router({
    routes: [
        {
            path: '/',
            name: 'Intro',
            component: Intro,
            meta: {
                hideLNBHeader: true
            },
        },
        {
            path: '/login',
            name: 'Login',
            component: Login,
            meta: {
                hideLNBHeader: true
            },
        },
        //admin
        {
            path: '/admin/regist',
            name: 'AdminRegist',
            component: AdminRegist,
        },
        {
            path: '/admin/edit/:adminIdx',
            name: 'AdminEdit',
            component: AdminEdit,
        },
        {
            path: '/admin/list',
            name: 'AdminManage',
            component: AdminManage,
        },
        {
            path: '/admin/login',
            name: 'AdminLoginHistory',
            component: AdminLoginHistory,
        },
        //bot
        {
            path: '/bot/regist',
            name: 'BotRegist',
            component: BotRegist,
        },
        {
            path: '/bot/list',
            name: 'BotList',
            component: BotList,
        },
        {
            path: '/bot/trade/list',
            name: 'BotTradeList',
            component: BotTradeList,
        },
        {
            path: '/bot/trade/statistics',
            name: 'BotTradeStatistics',
            component: BotTradeStatistics,
        },
        {
            path: '/bot/edit',
            name: 'BotEdit',
            component: BotEdit,
        },
        //currency
        {
            path: '/currency/regist',
            name: 'CurrencyRegist',
            component: CurrencyRegist,
        },
        {
            path: '/currency/edit/:currencyIdx',
            name: 'CurrencyEdit',
            component: CurrencyEdit,
        },
        {
            path: '/currency/list',
            name: 'CurrencyList',
            component: CurrencyList,
        },
        //dashboard
        {
            path: '/dashboard',
            name: 'Dashboard',
            component: Dashboard,
        },
        //etc
        {
            path: '/etc/otp',
            name: 'GoogleOTP',
            component: GoogleOTP,
        },
        // 아래 경로 정규화 필요
        //market history
        {
            path: '/market/history/buy',
            name: 'MarketBuyHistory',
            component: MarketBuyHistory,
        },
        {
            path: '/market/history/conclude',
            name: 'MarketConcludeHistory',
            component: MarketConcludeHistory,
        },
        {
            path: '/market/history/sell',
            name: 'MarketSellHistory',
            component: MarketSellHistory,
        },
        //market pair
        {
            path: '/market/pair/list',
            name: 'MarketPairList',
            component: MarketPairList,
        },
        {
            path: '/market/pair/regist',
            name: 'MarketPairRegist',
            component: MarketPairRegist,
        },
        {
            path: '/market/pair/edit/:marketIdx',
            name: 'MarketPairEdit',
            component: MarketPairEdit,
        },
        {
            path: '/market/pair/etc/setting',
            name: 'MarketPairEtcSetting',
            component: MarketPairEtcSetting,
        },
        {
            path: '/otc/pair/list',
            name: 'OtcPairList',
            component: OtcPairList,
        },
        {
            path: '/otc/pair/regist',
            name: 'OtcPairRegist',
            component: OtcPairRegist,
        },
        {
            path: '/otc/pair/edit/:otcPairIdx',
            name: 'OtcPairEdit',
            component: OtcPairEdit,
        },
        //otc suggestion
        {
            path: '/otc/suggestion/list',
            name: 'OtcSuggestionList',
            component: OtcSuggestionList,
        },
        {
            path: '/otc/suggestion/detail',
            name: 'OtcSuggestionDetail',
            component: OtcSuggestionDetail,
            children: [
                {
                    // 자식 컴포넌트 없을 경우
                    path: "/otc/suggestion/detail",
                    component: OtcSuggestionDetail,
                },
                {
                    // 거래 내역
                    path: "/otc/suggestion/detail/trading",
                    name: 'OtcSuggestionTrading',
                    component: OtcSuggestionTrading,

                },
                {
                    // 채팅 내역
                    path: "/otc/suggestion/detail/chat",
                    name: 'OtcSuggestionChat',
                    component: OtcSuggestionChat,
                },
            ]
        },
        {
            path: '/otc/suggestion/claim',
            name: 'OtcClaimList',
            component: OtcClaimList,
        },
        {
            path: '/otc/suggestion/claim/detail',
            name: 'OtcClaimDetail',
            component: OtcClaimDetail,
        },
        //setting board
        {
            path: '/setting/board/manage/list',
            name: 'BoardManageList',
            component: BoardManageList,
        },
        {
            path: '/setting/board/manage/regist',
            name: 'BoardManageRegist',
            component: BoardManageRegist,
        },
        {
            path: '/setting/board/manage/edit/:boardIdx',
            name: 'BoardManageEdit',
            component: BoardManageEdit,
        },
        {
            path: '/setting/board/content/:boardIdx/list',
            name: 'BoardContentList',
            component: BoardContentList,
        },
        {
            path: '/setting/board/content/:boardIdx/regist',
            name: 'BoardContentRegist',
            component: BoardContentRegist,
        },
        {
            path: '/setting/board/content/:boardIdx/edit/:contentIdx',
            name: 'BoardContentEdit',
            component: BoardContentEdit,
        },
        //setting data
        {
            path: '/setting/data/management',
            name: 'DataManagement',
            component: DataManagement,
        },
        {
            path: '/setting/data/market/history',
            name: 'DataMarketHistory',
            component: DataMarketHistory,
        },
        {
            path: '/setting/data/market/fee',
            name: 'DataMarketUserFee',
            component: DataMarketUserFee,
        },
        {
            path: '/setting/data/otc/history',
            name: 'DataOtcHistory',
            component: DataOtcHistory,
        },
        {
            path: '/setting/data/otc/fee',
            name: 'DataOtcUserFee',
            component: DataOtcUserFee,
        },
        {
            path: '/setting/data/balance',
            name: 'DataUserBalance',
            component: DataUserBalance,
        },
        {
            path: '/setting/data/wallet/history',
            name: 'DataWalletHistory',
            component: DataWalletHistory,
        },
        //setting market settle
        {
            path: '/setting/settle',
            name: 'MarketSettleList',
            component: MarketSettleList,
        },
        {
            path: '/setting/settle/calendar/:year/:month',
            name: 'MarketSettleCalendar',
            component: MarketSettleCalendar,
        },
        {
            path: '/setting/settle/excel/:year/:month',
            name: 'MarketSettleExcel',
            component: MarketSettleExcel,
        },
        //setting
        {
            path: '/setting/site',
            name: 'SiteSetting',
            component: SiteSetting,
        },
        {
            path: '/setting/statistics',
            name: 'SiteStatistics',
            component: SiteStatistics,
        },
        //user
        {
            path: '/address/whitelist',
            name: 'AddressWhiteList',
            component: AddressWhiteList,
        },
        {
            path: '/user/blacklist',
            name: 'UserBlackList',
            component: UserBlackList,
        },
        {
            path: '/user/detail/:userIdx',
            name: 'UserDetail',
            component: UserDetail,
        },
        {
            path: '/user/ip',
            name: 'UserIpManage',
            component: UserIpManage,
        },
        {
            path: '/user/history/login',
            name: 'UserLoginHistory',
            component: UserLoginHistory,
        },
        {
            path: '/user/management',
            name: 'UserManage',
            component: UserManage,
        },
        {
            path: '/user/regist',
            name: 'UserRegist',
            component: UserRegist,
        },
        {
            path: '/user/remove/list',
            name: 'UserRemoveList',
            component: UserRemoveList,
        },
        //wallet
        {
            path: '/wallet/transfer',
            name: 'WalletTransferList',
            component: WalletTransferList,
        },
        {
            path: '/wallet/safeWithdrawal',
            name: 'WalletSafeWithdrawList',
            component: WalletSafeWithdrawList,
        },
    ]
})

<template>
  <div class="modal fade" id="modal-UserMemoPopup">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">관리자 메모</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <textarea v-model="formData.memo" class="form-control"></textarea>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="setData()">저장</button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal" aria-label="Close">닫기</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
  <!-- /.modal -->
</template>

<script>
export default {
  name: 'UserMemoPopup',
  data() {
    return {
      userIdx: 0,
      formData: {
        memo: '',
      },
    }
  },
  methods: {
    init(idx) {
      this.userIdx = idx
      this.$get(this.$USER_MEMO.replace('?', idx), this.$options.name + '_init', (result) => {
        this.getFormatData(this.formData, result)
        window.$('#modal-UserMemoPopup').modal('show')
      }, (result) => {
        this.httpError(result)
      })
    },
    setData() {
      let formData = new FormData()
      Object.entries(this.formData).forEach((entry) => {
        let [key, value] = entry
        formData.append(key, (value+'').trim())
      })

      this.$post(this.$USER_MEMO.replace('?', this.userIdx), this.$options.name + '_setData', formData, (result) => {
        this.notify('success', result.message, false)

        window.$('#modal-UserMemoPopup').modal('hide')
      }, (result) => {
        this.httpError(result)
      })
    },
  },
}
</script>

<style>
</style>

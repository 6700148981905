<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">기타</h4>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title mr-2">Google OTP</h5>
                <small>구글 OTP 앱을 설치하고 이용해주세요.</small>
              </div><!-- /.card-header -->
              <div class="card-body text-center">
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>QRCode</strong>
                  </div>
                  <div class="col-sm-5">
                    <vue-qrcode :value="secret" :width="200" :margin="1"></vue-qrcode>
                  </div>
                  <div class="col-sm-4">
                    <span></span>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>Secret Text</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="text" class="form-control"  v-model="formData.key" readonly>
                  </div>
                  <div class="col-sm-4">
                    <span></span>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>인증코드 *</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="text" class="form-control" v-model="formData.code"/>
                  </div>
                  <div class="col-sm-4">
                    <span></span>
                  </div>
                </div>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix text-center row">
                <div class="col-sm-3">
                </div>
                <div class="col-sm-5">
                  <button type="button" class="btn btn-primary" @click="newData()">OTP 등록</button>
                </div>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>
import VueQrcode from 'vue-qrcode'

export default {
  name: 'GoogleOTP',
  components: {
    VueQrcode,
  },
  data() {
    return {
      secret: '',
      formData: {
        key: '',
        code: '',
      },
    }
  },
  created() {
    this.$root.$emit(this.$EVENT_LNB_CHANGE, 'etc', this.$options.name)

    this.getData()
  },
  methods: {
    getData() {
      this.$get(this.$AUTH_OTP, this.$options.name + '_getData', (result) => {
        this.secret = result.data.url
        this.formData.key = result.data.key
      }, (result) => {
        this.httpError(result)
      })
    },
    newData() {
      let formData = new FormData()
      Object.entries(this.formData).forEach((entry) => {
        let [key, value] = entry
        formData.append(key, (value+'').trim())
      })

      this.$post(this.$AUTH_OTP, this.$options.name + '_newData', formData, (result) => {
        this.notify('success', result.message, false)

        this.$router.push({
          name: 'Login'
        })
      }, (result) => {
        this.httpError(result)
      })
    },
  }
}
</script>

<style>
</style>

<template>
  <div class="row">
    <select class="ml-auto form-control mr-1 w-auto" v-model="txidCurrency">
      <option value="btc">Bitcoin</option>
      <option value="eth">Ethereum</option>
    </select>
    <input class="form-control mr-1 w-50" type="text" placeholder="TXID" v-model="txidCheck"/>
    <a :href="txidGet[txidCurrency]+txidCheck" target="_blank" class="btn btn-primary">TXID Check</a>
  </div>
</template>

<script>
export default {
  name: "TxidCheck",
  data() {
    return {
      txidCurrency: 'btc',
      txidGet: {
        btc: 'https://www.blockchain.com/btc/tx/',
        eth: 'https://etherscan.io/tx/'
      },
      txidCheck: '',
    }
  },
}
</script>

<style>
</style>

<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">BOT</h4>
            <span>봇 관련 항목을 확인합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">BOT 설정</h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>봇 번호 *</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="text" class="form-control" v-model="formData.idx" disabled />
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>봇 닉네임 *</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="text" class="form-control" v-model="formData.botName"/>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>마켓 *</strong>
                  </div>
                  <div class="col-sm-5">
                    <select class="form-control" v-model="selectedMarketPair">
                      <option v-for="(marketPair, index) in marketPairList" :key="index" :value="marketPair">{{ marketPair }}</option>
                    </select>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>모니터링 시간(초) *</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="number" class="form-control" v-model.number="formData.roundInterval"/>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>거래 계정 번호*</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="number" class="form-control" v-model.number="formData.botUserIdx" @change="changeBoutUserIdx(formData.botUserIdx)"/>
                  </div>
                  <div class="col-sm-4">
                    <span>봇으로 등록할 계정 번호를 입력해주세요.</span>
                  </div>
                </div>
                <div v-if="isChangeBotUserIdx" class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>거래 계정 비밀번호 *</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="text" class="form-control" v-model="formData.botUserPassword"/>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>봇 구분 *</strong>
                  </div>
                  <div class="col-sm-5">
                    <select class="form-control" v-model="formData.botType">
                      <option :value="'ORDER'">호가봇</option>
                      <option :value="'TRADE'">체결봇</option>
                    </select>
                  </div>
                </div>
                <div v-if="formData.botType === 'ORDER'" class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>거래 구분</strong>
                  </div>
                  <div class="col-sm-5">
                    <select class="form-control" v-model="formData.isBuy">
                      <option :value="false">매도</option>
                      <option :value="true">매수</option>
                    </select>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>일 체결제한 *</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="number" class="form-control" v-model.number="formData.limitDay"/>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>1회 수량 *</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="number" class="form-control" v-model.number="formData.roundAmount"/>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>등록 시세 소수점 *</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="number" class="form-control" v-model.number="formData.priceDecimal"/>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>등록 시세 *</strong>
                  </div>
                  <div class="col-sm-5">
                    <select class="form-control" v-model="formData.exchanges">
                      <option :value="'BINANCE'">BINANCE</option>
                      <option :value="'UPBIT'">UPBIT-KRW/1000</option>
                      <option :value="'BITHUMB'">BITHUMB-KRW/1000</option>
                      <option :value="'NONE'">고정시세</option>
                    </select>
                  </div>
                </div>
                <div v-if="formData.exchanges === 'UPBIT'" class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>추종 시세 *</strong>
                  </div>
                  <div class="col-sm-5">
                    <select class="form-control" v-model="formData.marketPairFollowing">
                      <option v-for="(tracking, index) in marketPairsFollowing" :key="index" :value="tracking">{{ tracking }}</option>
                    </select>
                  </div>
                </div>
                <div v-if="formData.exchanges === 'BITHUMB'" class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>추종 시세 *</strong>
                  </div>
                  <div class="col-sm-5">
                    <select class="form-control" v-model="formData.marketPairFollowing">
                      <option v-for="(tracking, index) in marketPairsFollowing" :key="index" :value="tracking">{{ tracking }}</option>
                    </select>
                  </div>
                </div>
                <div v-if="formData.exchanges === 'NONE'" class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>고정시세 입력값 *</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="number" class="form-control" v-model.number="formData.priceFixed"/>
                  </div>
                  <div class="col-sm-4">
                    <span>비상장 코인일 경우 고정시세가 사용됩니다.</span>
                  </div>
                </div>
                <div v-if="formData.exchanges !== 'NONE'" class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>마크업(단위 : %)</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="number" class="form-control" v-model.number="formData.markUp"/>
                  </div>
                  <div class="col-sm-4">
                    <span>입력하신 값은 %로 적용됩니다.</span>
                  </div>
                </div>
                <div v-if="formData.exchanges !== 'NONE'" class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>상단 범위(단위 : %)</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="number" class="form-control" v-model.number="formData.markUpper"/>
                  </div>
                  <div class="col-sm-4">
                    <span>입력하신 값은 %로 적용됩니다.</span>
                  </div>
                </div>
                <div v-if="formData.exchanges !== 'NONE'" class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>하단 범위(단위 : %)</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="number" class="form-control" v-model.number="formData.markBottom"/>
                  </div>
                  <div class="col-sm-4">
                    <span>양수만 입력해주세요. 입력하신 값은 %로 적용됩니다. 예) 0.5 -> 0.5%</span>
                  </div>
                </div>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix text-center row">
                <div class="col-sm-3">
                </div>
                <div class="col-sm-5">
                  <button type="button" class="btn btn-primary mr-1" @click="editData()">수정</button>
                  <button type="button" class="btn btn-secondary" @click="$router.back()">목록</button>
                </div>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "BotEdit",
  data() {
    return {
      formData: {
        idx: 0,
        botName: '',
        marketPair: '',
        roundInterval: 0,
        botUserIdx: 0,
        botUserPassword: '',
        botType: 'ORDER', // ORDER : 호가봇, TRADE : 체결봇
        isBuy: true,
        limitDay: 0,
        roundAmount: 0,
        priceFixed: 0,
        exchanges: 'BINANCE', // 등록시세. BINANCE, UPBIT, NONE
        markUp: 0,
        priceDecimal: 0,
        markUpper : 0,
        markBottom : 0,
        marketPairFollowing: '',
      },
      selectedMarketPair: '',
      marketPairList: [],
      marketPairsFollowing: [],

      isChangeBotUserIdx: false,
      initialBotUserIdx: 0,
    }
  },
  created() {
    this.formData.idx = this.$route.params.idx
    this.getMarketPair()
    this.getData()
  },
  methods: {
    getMarketPair() {
      this.$get(this.$BOT_MARKET_PAIR, this.$options.name + '_getMarketPair', (result) => {
        this.marketPairList = result.data
        this.formData.marketPair = this.marketPairList[0] // default 값 설정
      }, (result) => {
        this.httpError(result)
      })
    },
    getExchanges(exchange) {
      this.marketPairsFollowing = []
      this.$get(this.$BOT_FOLLOW_EXCHANGE + exchange, this.$options.name + '_getExchanges', (result) => {
        this.marketPairsFollowing = result.data
      }, (result) => {
        this.httpError(result)
      })
    },
    getData() {
      this.$get(this.$BOT_REFER.replace('?', this.formData.idx), this.$options.name + '_getData', (result) => {
        this.formData = result.data

        this.formData.markUp = this.$big(this.formData.markUp).mul(100)
        this.formData.markUpper = this.$big(this.formData.markUpper).mul(100)
        this.formData.markBottom = this.$big(this.formData.markBottom).mul(100)
        this.selectedMarketPair = result.data.marketPair
        this.initialBotUserIdx = result.data.botUserIdx
      }, (result) => {
        this.httpError(result)
      })
    },
    editData() {
      let formData = new FormData()
      this.formData.marketPair = this.selectedMarketPair
      Object.entries(this.formData).forEach((entry) => {
        let [key, value] = entry

        if(key === 'priceProvider' || key === 'historyProvider' || key === 'orderProvider' || key === 'orderMap' || key === 'runningBotMap') {
          return
        }

        if(this.formData.exchanges === 'BINANCE' && (key === 'marketPairFollowing' || key === 'priceFixed')) return
        if(this.formData.exchanges === 'UPBIT' && key === 'priceFixed') return
        if(this.formData.exchanges === 'BITHUMB' && key === 'priceFixed') return

        if(!this.isChangeBotUserIdx && key === 'botUserPassword') return

        if(key === 'markUpper' || key === 'markBottom') {
          value = this.$roundToDecimal(value, 3)
        }

        formData.append(key, (value+'').trim())
      })

      this.$post(this.$BOT_EDIT, this.$options.name + '_editData', formData, () => {
        this.notify('success', '봇을 수정했습니다.', false)
        this.isChangeBotUserIdx = false
        this.getData()
      }, (result) => {
        this.httpError(result)
        this.getData()
      })
    },
    changeBoutUserIdx(_changeBotUserIdx) {
      let condition = _changeBotUserIdx !== this.initialBotUserIdx

      if(condition) {
        this.isChangeBotUserIdx = true
        this.formData.botUserIdx = _changeBotUserIdx
      } else {
        this.isChangeBotUserIdx = false
      }
    }
  },
  watch: {
    'formData.exchanges': function (val) {
      if(val === 'UPBIT' || val === 'BITHUMB') {
        this.getExchanges(val)
      }
    }
  }
}
</script>

<style>
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>

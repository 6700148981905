<template>
  <div v-if="total > 0">
    <ul class="pagination pagination-sm m-0">
      <li class="page-item" v-bind:class="{ disabled: prevTab === 0 }">
        <router-link class="page-link" :to="{ name:route, query:getParams(prevTab) }">&laquo;</router-link>
      </li>
      <li class="page-item" v-bind:class="{ disabled: prev === 0 }">
        <router-link class="page-link" :to="{ name:route, query:getParams(prev) }">&lt;</router-link>
      </li>
      <li class="page-item" v-for="n in items" v-bind:key="n" v-bind:class="{ active: n === page }">
        <router-link class="page-link" :to="{ name:route, query:getParams(n) }">{{ n }}</router-link>
      </li>
      <li class="page-item" v-bind:class="{ disabled: next === 0 }">
        <router-link class="page-link" :to="{ name:route, query:getParams(next) }">&gt;</router-link>
      </li>
      <li class="page-item" v-bind:class="{ disabled: nextTab === 0 }">
        <router-link class="page-link" :to="{ name:route, query:getParams(nextTab) }">&raquo;</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "PaginationTrading",
  data() {
    return {
      prevTab: 0,
      prev: 0,
      page: 0,
      next: 0,
      nextTab: 0,
      total: 0,
      totalPage: 0,
      size: 0,
      tab: 0,

      items: [],

      route: '',
      params: null,
    }
  },
  methods: {
    init(route, params, page, total, size, tab = 10) {
      this.route = route
      this.params = params
      this.page = page
      this.total = total
      this.size = size
      this.tab = tab

      this.totalPage = Math.floor((this.total - 1) / this.size) + 1

      this.next = this.page + 1
      if(this.next > this.totalPage) {
        this.next = 0
      }

      this.prev = this.page - 1
      if(this.prev < 0) {
        this.prev = 0
      }

      this.nextTab = this.page + this.tab
      if(this.nextTab > this.totalPage) {
        this.nextTab = 0
      }

      this.prevTab = this.page - this.tab
      if(this.prevTab < 0) {
        this.prevTab = 0
      }

      this.items = []
      let start = Math.floor((this.page - 1) / this.size) * this.size + 1
      let end = start + this.tab - 1
      if(end > this.totalPage) {
        end = this.totalPage
      }

      for(let i = start; i <= end; i++) {
        this.items.push(i)
      }
    },
    getParams(page) {
      let params = { ...this.params }
      if(params) {
        params.tradingPage = page
        params.tradingSize = this.size
      }

      return params
    },
  },
}
</script>

<style>
</style>

<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">사용자</h4>
            <span> 데이터베이스에 기록된 사용자 목록을 확인합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">일반 사용자</h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <div class="row">
                  <div class="col-6">
                    <UserDetailInfo
                      :user-idx="userIdx"
                    />
                  </div>
                  <div class="col-6">
                    <UserDetailPassword
                      :user-idx="userIdx"
                    />
                    <UserDetailFlag
                      :user-idx="userIdx"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <UserDetailConsult
                      :user-idx="userIdx"
                    />
                  </div>
                  <div class="col-6">
                    <UserDetailLoginHistory
                      :user-idx="userIdx"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <UserDetailWallet
                      :user-idx="userIdx"
                    />
                  </div>
                  <div class="col-6">
                    <UserDetailBalance
                      :user-idx="userIdx"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <UserDetailWalletHistory
                      :user-idx="userIdx"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <UserDetailAddressWithdrawHistory
                        :user-idx="userIdx"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <UserDetailMarketSellList
                      :user-idx="userIdx"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <UserDetailMarketBuyList
                      :user-idx="userIdx"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <UserDetailMarketHistory
                      :user-idx="userIdx"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <UserDetailOtcCredit
                      :user-idx="userIdx"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <UserDetailOtcSellList
                      :user-idx="userIdx"
                      :user-email="userEmail"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <UserDetailOtcBuyList
                      :user-idx="userIdx"
                      :user-email="userEmail"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <UserDetailOtcHistory
                      :user-idx="userIdx"
                    />
                  </div>
                </div>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix">
                <div class="text-center">
                  <button type="button" class="btn btn-primary" @click="goBackList()">목록</button>
                </div>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>
import UserDetailBalance from "@/components/user/detail/UserDetailBalance"
import UserDetailConsult from "@/components/user/detail/UserDetailConsult"
import UserDetailFlag from "@/components/user/detail/UserDetailFlag"
import UserDetailInfo from "@/components/user/detail/UserDetailInfo"
import UserDetailLoginHistory from "@/components/user/detail/UserDetailLoginHistory"
import UserDetailMarketHistory from "@/components/user/detail/UserDetailMarketHistory"
import UserDetailMarketSellList from "@/components/user/detail/UserDetailMarketSellList"
import UserDetailMarketBuyList from "@/components/user/detail/UserDetailMarketBuyList"
import UserDetailOtcBuyList from "@/components/user/detail/UserDetailOtcBuyList"
import UserDetailOtcCredit from "@/components/user/detail/UserDetailOtcCredit"
import UserDetailOtcHistory from "@/components/user/detail/UserDetailOtcHistory"
import UserDetailOtcSellList from "@/components/user/detail/UserDetailOtcSellList"
import UserDetailPassword from "@/components/user/detail/UserDetailPassword"
import UserDetailWallet from "@/components/user/detail/UserDetailWallet"
import UserDetailWalletHistory from "@/components/user/detail/UserDetailWalletHistory"
import UserDetailAddressWithdrawHistory from "./detail/UserDetailAddressWithdrawHistory";

export default {
  name: 'UserDetail',
  components: {
    UserDetailAddressWithdrawHistory,
    UserDetailBalance,
    UserDetailConsult,
    UserDetailFlag,
    UserDetailInfo,
    UserDetailLoginHistory,
    UserDetailMarketHistory,
    UserDetailMarketSellList,
    UserDetailMarketBuyList,
    UserDetailOtcBuyList,
    UserDetailOtcCredit,
    UserDetailOtcHistory,
    UserDetailOtcSellList,
    UserDetailPassword,
    UserDetailWallet,
    UserDetailWalletHistory,
  },
  data() {
    return {
      userIdx: 0,
      userEmail: '',
      componentName: '',
    }
  },
  created() {
    this.userIdx = this.$route.params.userIdx
    this.userEmail = this.$route.params.userEmail
    if(this.$route.params.componentName) {
      this.componentName = this.$route.params.componentName
    }
  },
  methods: {
    goBackList() {
      if(this.componentName) {
        this.$router.push({ name: 'DataManagement' })
        return
      }
      this.$router.back()
    }
  }
}
</script>

<style>
</style>

<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">도구</h4>
            <span> 사이트를 관리하기 위한 각종 도구를 제공합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">데이터 관리 > 마켓 사용자별 거래 수수료 내역</h5>
                <div class="row float-right" id="dataTableButtons">
                </div>
              </div><!-- /.card-header -->
              <div class="card-body">
                <table id="dataTable" class="table table-bordered">
                  <thead>
                  <tr>
                    <th>회원번호</th>
                    <th>이메일</th>
<!--                    <th>일자</th>-->
                    <th v-for="(key, index) in keyList" :key="index">{{ key }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, index) in items" :key="index">
                    <td>{{ item.userIdx }}</td>
                    <td>{{ item.email }}</td>
<!--                    <td>{{ item.createdAt | moment('YYYY-MM-DD HH:mm:ss') }}</td>-->
                    <td v-for="(value, index) in item.feeMap" :key="index">{{ value }}</td>
                  </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix">
                <pagination ref="Pagination"></pagination>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>
import Pagination from "../../common/Pagination";
import Vue from 'vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
Vue.use(Loading)
import Loader from '@/components/common/Loader'

export default {
  name: 'DataMarketUserFee',
  components: {
    Pagination,
  },
  data() {
    return {
      size: '10',
      page: 1,
      total: 0,
      selectedStart: '',
      selectedEnd: '',

      //data
      items: [],
      keyList: [],
      feeMapList: [],

      loader: null,
    }
  },
  created() {
    let page = this.$route.query.page
    if(typeof(page) === 'undefined') {
      page = 1
    }

    let size = this.$route.query.size
    if(typeof(size) !== 'undefined') {
      this.size = size
    }

    this.selectedStart = new Date(this.$route.params.start).getTime()
    this.selectedEnd = new Date(this.$route.params.end).getTime()

    this.getMarketUserFee(page)
  },
  beforeDestroy() {
    this.loader.hide()
  },
  methods: {
    getMarketUserFee(page) {
      this.loader = this.$loading.show({
        backgroundColor: '#65686F',
        opacity: 0.5,
        zIndex: 999,
      }, {
        default: this.$createElement(Loader)
      })

      let params = new URLSearchParams()
      params.append('start', this.selectedStart)
      params.append('end', this.selectedEnd)
      if(typeof page === 'undefined') page = 1
      params.append('page', page)
      params.append('limit', this.size)

      this.$get(this.$TOOLS_MARKET_FEE_LOG + '?' + params.toString(), this.$options.name + '_getMarketUserFee', (result) => {
        this.loader.hide()
        this.page = page
        this.total = result.total

        try {
          this.$refs.Pagination.init(this.$options.name, {}, Number(this.page), this.total, Number(this.size))
        } catch (e) {
          console.log(e)
        }
        this.items = result.data

        result.data.map(v => this.feeMapList.push(v.feeMap))
        if(this.feeMapList.length > 0) {
          this.keyList = (Object.keys(this.feeMapList[0]))
        }
        this.$createTables()
        this.$destroyTables()
      }, (result) => {
        this.httpError(result)
      })
    },
  },
  watch: {
    'this.items'() {
      this.$createTables()
    },
    '$route.query.page'() {
      this.getMarketUserFee(this.$route.query.page)
    },
  }

}
</script>

<style>
</style>

<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">사용자</h4>
            <span> 데이터베이스에 기록된 사용자 목록을 확인합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->
        <div class="row mb-2">
          <div class="col-sm-12">
            <h5 class="mb-2">필터</h5>
            <div class="row align-items-center">
              <select class="form-control w-auto mr-1" v-model="selectedSearchType">
                <option value="address">아이피</option>
              </select>
              <input type="text" class="form-control w-auto mr-1" placeholder="검색어" v-model="searchKeyword">
              <select class="form-control w-auto mr-1" v-model="size">
                <option value="10">10줄</option>
                <option value="20">20줄</option>
                <option value="50">50줄</option>
                <option value="100">100줄</option>
              </select>
              <button class="btn btn-primary" @click="getList(page)">검색</button>
              <span class="ml-auto mr-1">차단 IP 등록</span>
              <input type="text" class="form-control w-auto mr-1" placeholder="아이피" v-model="formData.address">
              <button class="btn btn-success" @click="newData()">등록</button>
            </div>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">IP 관리</h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <table class="table table-bordered">
                  <thead>
                  <tr>
                    <th>ID</th>
                    <th>아이피</th>
                    <th>날짜</th>
                    <th>Lock</th>
                  </tr>
                  </thead>
                  <tbody v-if="items.length > 0">
                    <tr v-for="(item, index) in items" :key="index">
                      <td>{{ item.idx }}</td>
                      <td>{{ item.address }}</td>
                      <td>{{ item.createdAt | moment('YYYY-MM-DD HH:mm:ss') }}</td>
                      <td class="text-center">
                        <div class="btn-group mr-2">
                          <button class="badge btn btn-success mr-1" v-bind:class="{ disabled: item.available }" @click="setData(item.idx, '1')">허용</button>
                          <button class="badge btn btn-danger" v-bind:class="{ disabled: !item.available }" @click="setData(item.idx, '0')">차단</button>
                        </div>
                        <span class="badge btn btn-outline-danger" @click="deleteData(item.idx)">삭제</span>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="4" style="text-align: center;">관련된 내역이 없습니다.</td>
                    </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix">
                <div class="row">
                  <pagination ref="Pagination"></pagination>
                </div>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>
import Pagination from "../common/Pagination"

export default {
  name: 'UserIpManage',
  components: {
    Pagination,
  },
  data() {
    return {
      selectedSearchType: 'address',
      searchKeyword: '',
      size: '10',
      page: 0,
      total: 0,

      //data
      formData: {
        address: '',
      },
      items: []
    }
  },
  created() {
    this.$root.$emit(this.$EVENT_LNB_CHANGE, 'user', this.$options.name)

    let page = this.$route.query.page
    if(typeof(page) === 'undefined') {
      page = 1
    }

    let size = this.$route.query.size
    if(typeof(size) !== 'undefined') {
      this.size = size
    }

    let st = this.$route.query.st
    if(typeof(st) !== 'undefined') {
      this.selectedSearchType = st
    }

    let sk = this.$route.query.sk
    if(typeof(sk) !== 'undefined') {
      this.searchKeyword = sk
    }

    this.getList(page)
  },
  methods: {
    getList(page) {
      let params = new URLSearchParams()
      if(typeof page === 'undefined') page = 1
      params.append('page', page)
      params.append('limit', this.size)
      params.append('criteria', this.selectedSearchType)
      params.append('keyword', this.searchKeyword)

      this.$get(this.$USER_ADDRESS_BLOCKED + '?' + params.toString(), this.$options.name + '_getList', (result) => {
        this.page = page
        this.total = result.total

        try {
          this.$refs.Pagination.init(this.$options.name, {
            st: this.selectedSearchType,
            sk: this.searchKeyword,
          }, Number(this.page), this.total, Number(this.size))
        } catch (e) {
          console.log(e)
        }

        this.items = result.data
      }, (result) => {
        this.httpError(result)
      })
    },
    newData() {
      let formData = new FormData()
      Object.entries(this.formData).forEach((entry) => {
        let [key, value] = entry
        formData.append(key, (value+'').trim())
      })

      this.$post(this.$USER_ADDRESS_BLOCKED, this.$options.name + '_newData', formData, (result) => {
        this.getList(this.page)

        this.notify('success', result.message, false)
      }, (result) => {
        this.httpError(result)
      })
    },
    setData(idx, state) {
      let formData = new FormData()
      formData.append('idx', idx)
      formData.append('available', state)

      this.$put(this.$USER_ADDRESS_BLOCKED, this.$options.name + '_setData', formData, (result) => {
        this.getList(this.page)
        this.notify('success', result.message, false)
      }, (result) => {
        this.httpError(result)
      })
    },
    deleteData(idx) {
      this.$delete(this.$USER_ADDRESS_BLOCKED + '?idx=' + idx, this.$options.name + '_deleteData', (result) => {
        this.getList(this.page)

        this.notify('success', result.message, false)
      }, (result) => {
        this.httpError(result)
      })
    },
  },
  watch: {
    '$route.query.page'() {
      this.getList(this.$route.query.page)
    }
  },
}
</script>

<style>
</style>

<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">사용자</h4>
            <span> 거래이상자</span>
          </div><!-- /.col -->
        </div><!-- /.row -->
        <div class="row mb-2">
          <div class="col-sm-12">
            <h5 class="mb-2">필터</h5>
            <div class="row">
              <select class="form-control w-auto mr-1" v-model="selectedSearchType">
                <option value="email">이메일</option>
                <option value="phone">휴대폰</option>
                <option value="nickname">닉네임</option>
              </select>
              <input type="text" class="form-control w-auto mr-1" placeholder="검색어" v-model="searchKeyword">
              <select class="form-control w-auto mr-1" v-model="size">
                <option value="10">10줄</option>
                <option value="20">20줄</option>
                <option value="50">50줄</option>
                <option value="100">100줄</option>
              </select>
              <button class="btn btn-primary" @click="getList(page)">검색</button>
              <input type="text" class="form-control w-auto ml-auto mr-1" placeholder="아이디" v-model="formData.email">
              <button class="btn btn-success" @click="newData()">등록</button>
            </div>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">거래이상자(블랙리스트)</h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <table class="table table-bordered">
                  <thead>
                  <tr>
                    <th>회원번호</th>
                    <th>사용자</th>
                    <th>이메일</th>
                    <th>휴대폰</th>
                    <th>등록일자</th>
                    <th>자산현황</th>
                    <th>액션</th>
                  </tr>
                  </thead>
                  <tbody v-if="items.length > 0">
                    <tr v-for="(item, index) in items" :key="index">
                      <td>{{ item.idx }}</td>
                      <td>
                        <span class="badge btn btn-success" @click="goDetailPage(item.idx)">{{ item.nickName }}</span>
                      </td>
                      <td>{{ item.email }}</td>
                      <td>{{ item.phone }}</td>
                      <td>{{ item.createdAt | moment('YYYY-MM-DD HH:mm:ss') }}</td>
                      <td class="text-center">
                        <span class="badge btn btn-outline-warning" data-toggle="modal" data-target="#modal-UserAssetPopup" @click="goAssetPopup(item.idx)">상세보기</span>
                      </td>
                      <td class="text-center">
                        <span class="badge btn btn-outline-danger" @click="deleteData(item.idx)">삭제</span>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="7" style="text-align: center;">관련된 내역이 없습니다.</td>
                    </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix">
                <pagination ref="Pagination"></pagination>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
    <userAssetPopup ref="UserAssetPopup"></userAssetPopup>
  </div>
</template>

<script>
import UserAssetPopup from "@/components/user/userSub/UserAssetPopup"
import Pagination from "../common/Pagination"

export default {
  name: 'UserBlackList',
  components: {
    Pagination,
    UserAssetPopup,
  },
  data() {
    return {
      selectedSearchType: 'email',
      searchKeyword: '',
      size: '10',
      page: 0,
      total: 0,

      //data
      items: [],
      formData: {
        email: '',
      },
    }
  },
  created() {
    this.$root.$emit(this.$EVENT_LNB_CHANGE, 'user', this.$options.name)

    let page = this.$route.query.page
    if(typeof(page) === 'undefined') {
      page = 1
    }

    let size = this.$route.query.size
    if(typeof(size) !== 'undefined') {
      this.size = size
    }

    let st = this.$route.query.st
    if(typeof(st) !== 'undefined') {
      this.selectedSearchType = st
    }

    let sk = this.$route.query.sk
    if(typeof(sk) !== 'undefined') {
      this.searchKeyword = sk
    }

    this.getList(page)
  },
  methods: {
    getList(page) {
      let params = new URLSearchParams()
      if(typeof page === 'undefined') page = 1
      params.append('page', page)
      params.append('limit', this.size)
      params.append('criteria', this.selectedSearchType)
      params.append('keyword', this.searchKeyword)

      this.$get(this.$USER_TRANSFER_BLACK + '?' + params.toString(), this.$options.name + '_getList', (result) => {
        this.page = page
        this.total = result.total

        try {
          this.$refs.Pagination.init(this.$options.name, {
            st: this.selectedSearchType,
            sk: this.searchKeyword,
          }, Number(this.page), this.total, Number(this.size))
        } catch (e) {
          console.log(e)
        }

        this.items = result.data
      }, (result) => {
        this.httpError(result)
      })
    },
    goAssetPopup(idx) {
      this.$refs.UserAssetPopup.init(idx)
    },
    goDetailPage(idx) {
      this.$router.push({
        name: 'UserDetail',
        params: {
          userIdx: idx
        }
      })
    },
    newData() {
      let formData = new FormData()
      formData.append('email', this.formData.email)

      this.$put(this.$USER_TRANSFER_BLACK, this.$options.name + '_newData', formData, (result) => {
        this.getList(this.page)

        this.notify('success', result.message, false)
      }, (result) => {
        this.httpError(result)
      })
    },
    deleteData(idx) {
      let formData = new FormData()
      formData.append('withdraw', '1')
      formData.append('trade', '1')
      formData.append('login', '1')
      formData.append('total', '1')

      this.$put(this.$USER_STATUS.replace('?', idx), this.$options.name + '_deleteD,ata', formData, (result) => {
        this.getList(this.page)

        this.notify('success', result.message, false)
      }, (result) => {
        this.httpError(result)
      })
    },
  },
  watch: {
    '$route.query.page'() {
      this.getList(this.$route.query.page)
    }
  },
}
</script>

<style>
</style>

<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">도구</h4>
            <span>사이트를 관리하기 위한 각종 도구를 제공합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">데이터 관리 > 회원 잔액보유량</h5>
                <div class="row float-right" id="dataTableButtons">
                </div>
              </div><!-- /.card-header -->
              <div class="card-body">
                <table id="dataTable" class="table table-bordered">
                  <thead>
                    <tr>
                      <th>관리번호</th>
                      <th>아이디</th>
                      <th>통화</th>
                      <th>지갑주소</th>
                      <th>잔고</th>
                      <th>생성일자</th>
                      <th>액션</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in items" :key="index">
                      <td>{{ item.userIdx }}</td>
                      <td>{{ item.email }}</td>
                      <td>{{ item.ticker }}</td>
                      <td>{{ item.address ? item.address : '-' }}</td>
                      <td>{{ item.total }}</td>
                      <td v-if="item.createdAt">{{ item.createdAt | moment('YYYY-MM-DD HH:mm:ss') }}</td>
                      <td v-else>-</td>
                      <td class="text-center">
                        <span class="badge btn btn-outline-warning" @click="goDetail(item.userIdx)">상세정보</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>

export default {
  name: 'DataUserBalance',
  data() {
    return {
      selectedSearchType: '',
      searchKeyword: '',

      //data
      items: [],
    }
  },
  created() {
    this.selectedSearchType = this.$route.params.criteria
    this.searchKeyword = this.$route.params.keyword

    this.getUserBalance()
  },
  methods: {
    goDetail(_userNo) {
      this.$router.push({
        name: 'UserDetail',
        params: {
          userIdx: _userNo,
          componentName: this.$options.name
        }})
    },
    getUserBalance() { // 검색 옵션
      let params = new URLSearchParams()
      params.append('criteria', this.selectedSearchType)
      params.append('keyword', this.searchKeyword)

      this.$get(this.$TOOLS_BALANCE + '?' + params.toString(), this.$options.name + '_getUserBalance', (result) => {
        this.items = result.data
        this.$createTables()
        this.$destroyTables()
      }, (result) => {
        this.httpError(result)
      })
    },
  },

  watch: {
    'this.items'() {
      this.$createTables()
    },
  }
}
</script>

<style>
</style>

import { render, staticRenderFns } from "./BoardContentRegist.vue?vue&type=template&id=c55ada40&"
import script from "./BoardContentRegist.vue?vue&type=script&lang=js&"
export * from "./BoardContentRegist.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
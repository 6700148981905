<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">입출금</h4>
            <span> 데이터베이스에 기록된 입출금 내역을 확인합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->
        <div class="row mb-2">
          <div class="col-sm-12">
            <h5 class="mb-2">필터</h5>
            <div class="row">
              <select class="form-control w-auto mr-1" v-model="selectedCurrency">
                <option value="">전체</option>
                <option v-for="(currency, index) in this.currencyList" :key="index" :value="currency.ticker">{{ currency.name }}</option>
              </select>
              <select class="form-control w-auto mr-1" v-model="selectedIsDeposit">
                <option value="">입/출금</option>
                <option value="1">입금</option>
                <option value="0">출금</option>
              </select>
              <select class="form-control w-auto mr-1" v-model="selectedProcessState">
                <option value="">승인상태</option>
                <option v-for="(processState, index) in this.E_ProcessState" :key="index" :value="index">{{ processState }}</option>
              </select>
              <select class="form-control w-auto mr-1" v-model="selectedSearchType">
                <option value="email">이메일</option>
                <option value="phone">휴대폰</option>
                <option value="nickname">닉네임</option>
                <option value="walletaddress">출금 목적지 주소</option>
                <option value="user_idx">회원번호</option>
              </select>
              <input
                :type="selectedSearchType === 'user_idx'
                ? 'number'
                : 'text'"
                class="form-control w-auto mr-1"
                placeholder="검색어"
                v-model="searchKeyword">
              <select class="form-control w-auto mr-1" v-model="size">
                <option value="10">10줄</option>
                <option value="20">20줄</option>
                <option value="50">50줄</option>
                <option value="100">100줄</option>
              </select>
              <button class="btn btn-primary" @click="getList(page)">검색</button>
            </div>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header row">
                <h5 class="card-title col-4">코인 입금</h5>
                <div class="col-8">
                  <txid-check></txid-check>
                </div>
              </div><!-- /.card-header -->
              <div class="card-body">
                <table class="table table-bordered">
                  <thead>
                  <tr>
                    <th>관리번호</th>
                    <th>통화</th>
                    <th>신청일자</th>
                    <th>트랜잭션 넘버</th>
                    <th>타입</th>
                    <th>사용자</th>
                    <th>거래자</th>
                    <th>수량</th>
                    <th>수수료</th>
                    <th>액션</th>
                  </tr>
                  </thead>
                  <tbody v-if="items.length > 0">
                    <tr v-for="(item, index) in items" :key="index">
                      <td>{{ item.idx }}</td>
                      <td>{{ item.tickerName }}</td>
                      <td>{{ item.createdAt | moment('YYYY-MM-DD HH:mm:ss') }}</td>
                      <td>{{ item.tx }}</td>
                      <td>{{ E_TransferType[item.type] }}</td>
                      <td>{{ item.owner }}</td>
                      <td>{{ item.opposite ? item.opposite : '-' }}</td>
                      <td>{{ item.amount }}</td>
                      <td>{{ item.feeAmount }}</td>
                      <td>{{ E_ProcessState[item.processState] }}</td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="10" style="text-align: center;">관련된 내역이 없습니다.</td>
                    </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix">
                <pagination ref="Pagination"></pagination>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>

import Pagination from "../common/Pagination"
import TxidCheck from "../common/TxidCheck"

export default {
  name: 'WalletTransferList',
  components: {
    Pagination,
    TxidCheck,
  },
  data() {
    return {
      currencyList: [],

      selectedIsDeposit: '',
      selectedCurrency: '',
      selectedProcessState: '',
      selectedSearchType: 'email',
      searchKeyword: '',
      size: '10',
      page: 0,
      total: 0,

      //data
      items: []
    }
  },
  created() {
    this.$root.$emit(this.$EVENT_LNB_CHANGE, 'wallet', this.$options.name)

    let page = this.$route.query.page
    if(typeof(page) === 'undefined') {
      page = 1
    }

    let size = this.$route.query.size
    if(typeof(size) !== 'undefined') {
      this.size = size
    }

    let sc = this.$route.query.sc
    if(typeof(sc) !== 'undefined') {
      this.selectedCurrency = sc
    }

    let sd = this.$route.query.sd
    if(typeof(sd) !== 'undefined') {
      this.selectedIsDeposit = sd
    }

    let ps = this.$route.query.ps
    if(typeof(ps) !== 'undefined') {
      this.selectedProcessState = ps
    }

    let st = this.$route.query.st
    if(typeof(st) !== 'undefined') {
      this.selectedSearchType = st
    }

    let sk = this.$route.query.sk
    if(typeof(sk) !== 'undefined') {
      this.searchKeyword = sk
    }

    let deposit = this.$route.params.isDeposit
    if(typeof(deposit) !== 'undefined') {
      this.selectedIsDeposit = deposit
    }

    this.getFilterData(() => {
      this.getList(page)
    })
  },
  methods: {
    getFilterData(callback) {
      this.$get(this.$TRANSFER_FILTER, this.$options.name + '_getFilterData', (result) => {
        this.currencyList = result.data.currency

        callback()
      }, (result) => {
        this.httpError(result)
      })
    },
    getList(page) {
      let params = new URLSearchParams()
      if(typeof page === 'undefined') page = 1
      params.append('page', page)
      params.append('limit', this.size)
      params.append('ticker', this.selectedCurrency)
      params.append('isDeposit', this.selectedIsDeposit)
      params.append('processState', this.selectedProcessState)
      params.append('criteria', this.selectedSearchType)
      params.append('keyword', this.searchKeyword)

      this.$get(this.$TRANSFER + '?' + params.toString(), this.$options.name + '_getList', (result) => {
        this.page = page
        this.total = result.total

        try {
          this.$refs.Pagination.init(this.$options.name, {
            sc: this.selectedCurrency,
            sd: this.selectedIsDeposit,
            ps: this.selectedProcessState,
            st: this.selectedSearchType,
            sk: this.searchKeyword,
          }, Number(this.page), this.total, Number(this.size))
        } catch (e) {
          console.log(e)
        }

        this.items = result.data
      }, (result) => {
        this.httpError(result)
      })
    },
  },
  watch: {
    '$route.query.page'() {
      this.getList(this.$route.query.page)
    },
  },
}
</script>

<style>
</style>

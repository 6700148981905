<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">OTC 제안</h4>
            <span>데이터베이스에 기록된 OTC 제안 목록을 확인합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>제목 *</strong>
                  </div>
                  <div class="col-sm-6">
                    <label type="text" class="form-control">{{ items.title }}</label>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>희망가격 *</strong>
                  </div>
                  <div class="col-sm-6">
                    <label type="text" class="form-control">{{ items.exchanges === 'NONE' ? '협상가' : `${items.exchanges}의 ${items.markUp}%` }}</label>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>거래 방법 *</strong>
                  </div>
                  <div class="col-sm-6">
                    <label type="text" class="form-control">{{ items.tradeMethod === 'safe' ? '안전 거래' : '직접 거래' }}</label>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>상태 *</strong>
                  </div>
                  <div class="col-sm-6">
                    <label type="text" class="form-control">{{ E_P2PState[items.state] }}</label>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-sm-3 text-right">
                    <strong>내용 *</strong>
                  </div>
                  <div class="col-sm-6">
                    <textarea type="text" class="form-control" v-model="items.content" rows="5" disabled></textarea>
                  </div>
                </div>
                <otcSuggestionTrading />
                <otcSuggestionChat />
              </div><!-- ./card-body -->
              <div class="card-footer clearfix text-center row">
                <div class="col-sm-3">
                </div>
                <div class="col-sm-6">
                  <button type="button" class="btn btn-primary" @click="$router.push({ name: 'OtcSuggestionList' })">목록</button>
                </div>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>
import OtcSuggestionTrading from "./OtcSuggestionTrading";
import OtcSuggestionChat from "./OtcSuggestionChat";

export default {
  name: "OtcSuggestionDetail",
  components: {
    OtcSuggestionChat,
    OtcSuggestionTrading
  },
  data() {
    return {
      idx: 0,
      items : [],
    }
  },
  created() {
    this.idx = this.$route.params.idx
    this.getData()
  },
  methods: {
    getData() { // 상단의 데이터 받아오기
      this.$get(this.$OTC_SUGGESTION_DETAIL.replace('?', this.idx), this.$options.name + '_getData', (result) => {
        this.items = result.data
      }, (result) => {
        this.httpError(result)
      })
    },
  },
}
</script>

<style>
</style>

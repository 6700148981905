<template>
  <div class="m-3">
    <div class="card-header border-bottom-0">
      <h5 class="card-title">상담 내역</h5>
    </div><!-- /.card-header -->
    <div class="row align-items-center mb-2">
      <table class="table table-bordered">
        <thead>
        <tr>
          <th>상담자</th>
          <th>상담내용</th>
          <th>등록일자</th>
          <th>액션</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(consult, idx) in consultList" :key="idx">
          <td>{{ consult.consultant }}</td>
          <td>{{ consult.contents }}</td>
          <td>{{ consult.createdAt | moment('YYYY-MM-DD HH:mm:ss') }}</td>
          <td><span class="badge btn btn-outline-danger" @click="deleteConsult(idx)">삭제</span></td>
        </tr>
        <tr v-if="consultList.length === 0">
          <td colspan="4" style="text-align: center;">관련된 내역이 없습니다.</td>
        </tr>
        </tbody>
      </table>
      <textarea class="form-control" rows="2" v-model="content"></textarea>
    </div>
    <div class="row align-items-center mb-2">
      <div class="col-sm-3 text-right">
      </div>
      <div class="col-sm-6">
        <button type="button" class="btn btn-primary" @click="registConsult()">상담내용 등록</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserDetailConsult",

  props: [
    'userIdx',
  ],

  data() {
    return {
      content: '',
      adminList: [],
      consultList: [],
    }
  },

  created() {
    this.getConsultList()
  },

  methods: {
    deleteConsult(idx) {
      let selectedConsult = this.consultList[idx]

      let formData = new FormData()
      formData.append('isAppend', false)
      formData.append('consultIdx', selectedConsult.consultIdx)
      formData.append('userIdx', this.userIdx)
      formData.append('consultant', selectedConsult.consultant)
      formData.append('contents', selectedConsult.contents)
      formData.append('createdAt', selectedConsult.createdAt)

      this.$post(this.$USER_CONSULT, this.$options.name + '_deleteUserConsult', formData, () => {
        this.getConsultList()
      }, (result) => {
        console.log(result)
      })
    },

    registConsult() {
      let formData = new FormData()
      formData.append('isAppend', true)
      formData.append('userIdx', this.userIdx)
      formData.append('contents', this.content)

      this.$post(this.$USER_CONSULT, this.$options.name + '_postUserConsult', formData, true, () => {
        this.content = ''
        this.getConsultList()
      }, (result) => {
        console.log(result)
      })
    },

    getConsultList() {
      this.$get(`${this.$USER_CONSULT}/${this.userIdx}`, this.$options.name + '_getUserConsult', (result) => {
        this.consultList = result.data
      }, (result) => {
        this.httpError(result)
      })
    },
  }
}
</script>

<style scoped>

</style>

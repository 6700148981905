<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">도구</h4>
            <span> 사이트를 관리하기 위한 각종 도구를 제공합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">게시판 관리</h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>게시판 코드 *</strong>
                  </div>
                  <div class="col-sm-6">
                    <input type="text" class="form-control" v-model="formData.boardType.boardCode"/>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>게시판명 *</strong>
                  </div>
                  <div class="col-sm-6">
                    <input type="text" class="form-control" v-model="formData.boardType.boardTitle"/>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>언어선택 *</strong>
                  </div>
                  <div class="col-sm-6">
                    <select class="form-control" v-model="formData.boardType.boardLanguage">
                      <option value="KO-KR">국문</option>
                    </select>
                  </div>
                </div>

                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>고객센터 검색 대상 여부 *</strong>
                  </div>
                  <div class="col-sm-6">
                    <select class="form-control" v-model="formData.boardType.isProtected">
                      <option :value="false">검색 대상</option>
                      <option :value="true">검색 비대상</option>
                    </select>
                  </div>
                </div>

                <div class="row mb-1">
                  <div class="col-sm-3 text-right">
                    <strong>필수항목</strong>
                  </div>
                  <div class="col-sm-6">
                    <div class="input-group mb-1">
                      <input type="text" class="form-control" value="title" readonly disabled/>
                      <select v-model="formData.title.type" class="form-control title-require-type" disabled>
                        <option value="text">텍스트</option>
                        <option value="image">파일</option>
                      </select>
                      <input type="text" class="form-control" v-model="formData.title.length" placeholder="col" disabled/>
                      <select v-model="formData.title.require" class="form-control title-require-type" disabled>
                        <option value="true">필수입력</option>
                        <option value="false">빈값허용</option>
                      </select>
                    </div>
                    <div class="input-group mb-1">
                      <input type="text" class="form-control" value="contents" readonly disabled/>
                      <select v-model="formData.contents.type" class="form-control title-require-type" disabled>
                        <option value="text">텍스트</option>
                        <option value="image">파일</option>
                      </select>
                      <input type="text" class="form-control" v-model="formData.contents.length" placeholder="col" disabled/>
                      <select v-model="formData.contents.require" class="form-control title-require-type" disabled>
                        <option value="true">필수입력</option>
                        <option value="false">빈값허용</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row mb-1">
                  <div class="col-sm-3 text-right">
                    <button class="btn btn-danger mr-1" @click="addExtra()">&plus;</button>
                    <strong>추가항목</strong>
                  </div>
                  <div class="col-sm-6">
                    <div v-for="(item, index) in items" :key="index" class="input-group mb-1">
                      <input type="text" class="form-control" v-model="item.name"/>
                      <select v-model="item.type" class="form-control">
                        <option value="text">텍스트</option>
                        <option value="multipart">파일</option>
                      </select>
                      <input type="text" class="form-control" v-model="item.length" placeholder="col"/>
                      <select v-model="item.require" class="form-control">
                        <option value="true">필수입력</option>
                        <option value="false">빈값허용</option>
                      </select>
                      <div class="input-group-append">
                        <button class="btn btn-danger" @click="removeExtra(index)">&times;</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix text-center row">
                <div class="col-sm-3">
                </div>
                <div class="col-sm-6">
                  <button type="button" class="btn btn-primary mr-1" @click="newData()">저장</button>
                  <button type="button" class="btn btn-secondary" @click="$router.back()">목록</button>
                </div>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'BoardManageRegist',
  data() {
    return {
      colType: {
        text: '텍스트',
        multipart: '파일',
      },
      formData: {
        boardType: {
          boardTitle: '',
          boardCode: '',
          boardLanguage: 'KO-KR',
          isProtected: false,
        },
        title: {
          type: 'text',
          length: 255,
          require: true
        },
        contents: {
          type: 'text',
          length: 1024,
          require: true
        },
      },
      items: []
    }
  },
  methods: {
    newData() {
      let data = JSON.parse(JSON.stringify(this.formData))
      for (let i = 0; i < this.items.length; i++) {
        data[this.items[i].name] = {
          type: this.items[i].type,
          length: this.items[i].length,
          require: this.items[i].require
        }
      }
      let json = JSON.stringify(data)

      this.$post(this.$TOOLS_BOARD, this.$options.name + '_newData', json, (result) => {
        this.notify('success', result.message, false)

        this.$router.push({
          name: 'BoardManageList'
        })
      }, (result) => {
        this.httpError(result)
      })
    },
    addExtra() {
      this.items.push({
        name: '',
        type: 'text',
        length: 255,
        require: false
      })
    },
    removeExtra(_index) {
      this.items.splice(_index, 1)
    },
  }
}
</script>

<style>
.title-require-type {
  -webkit-appearance:none; /* for chrome */
  -moz-appearance:none; /*for firefox*/
  appearance:none;
}

.title-require-type::-ms-expand{
  display:none;/*for IE10,11*/
}
</style>

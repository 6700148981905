<template>
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <router-link :to="{ name: 'Dashboard' }" class="brand-link">
      <img src="dist/img/AdminLTELogo.png" alt="WIKIEX Logo" class="brand-image img-circle elevation-3" style="opacity: .8">
      <span class="brand-text font-weight-bold">UBIT </span>
      <span class="brand-text font-weight-light">관리자</span>
    </router-link>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar Menu -->
      <nav class="mt-2 pt-3">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <!-- Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library -->
          <li v-for="(navItem, index) in lnb" :key="index" class="nav-item" v-bind:class="{ 'menu-open' : selectedCode === navItem.code }">
            <a class="nav-link" v-bind:class="{ active : selectedCode === navItem.code}">
              <i class="nav-icon" v-bind:class="navItem.icon"></i>
              <p>
                {{ navItem.title }}
                <i class="right fas fa-angle-left"></i>
              </p>
            </a>
            <ul class="nav nav-treeview" v-show="selectedCode === navItem.code">
              <li v-for="(subItem, subIndex) in navItem.sub" :key="subIndex" class="nav-item">
                <router-link :to="{ name: subItem.link }" class="nav-link" v-bind:class="{ active : selectedSubCode === subItem.subCode}">
                  <p>{{ subItem.subTitle }}</p>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
export default {
  data() {
    return {
      selectedCode: null,
      selectedSubCode: null,
      lnb: [
        {
          'icon':'fas fa-user',
          'title':'사용자',
          'code':'user',
          'sub':[
            {
              'subTitle':'회원관리',
              'subCode':'UserManage',
              'link':'UserManage',
            },
            {
              'subTitle':'코인 출금 화이트리스트',
              'subCode':'AddressWhiteList',
              'link':'AddressWhiteList',
            },
            {
              'subTitle':'삭제 일반사용자',
              'subCode':'UserRemoveList',
              'link':'UserRemoveList',
            },
            {
              'subTitle':'거래이상자(블랙리스트)',
              'subCode':'UserBlackList',
              'link':'UserBlackList',
            },
            {
              'subTitle':'로그인 내역',
              'subCode':'UserLoginHistory',
              'link':'UserLoginHistory',
            },
            {
              'subTitle':'IP 관리',
              'subCode':'UserIpManage',
              'link':'UserIpManage',
            },
          ]
        },
        {
          'icon':'fas fa-crown',
          'title':'관리자',
          'code':'admin',
          'sub':[
            {
              'subTitle':'관리자 관리',
              'subCode':'AdminManage',
              'link':'AdminManage',
            },
            {
              'subTitle':'로그인 내역',
              'subCode':'AdminLoginHistory',
              'link':'AdminLoginHistory',
            },
          ]
        },
        {
          'icon':'fas fa-tasks',
          'title':'마켓 거래내역',
          'code':'marketHistory',
          'sub':[
            {
              'subTitle':'거래-매도',
              'subCode':'MarketSellHistory',
              'link':'MarketSellHistory',
            },
            {
              'subTitle':'거래-매수',
              'subCode':'MarketBuyHistory',
              'link':'MarketBuyHistory',
            },
            {
              'subTitle':'체결내역',
              'subCode':'MarketConcludeHistory',
              'link':'MarketConcludeHistory',
            },
          ]
        },
        {
          'icon':'fas fa-clipboard-list',
          'title':'OTC',
          'code':'otcSuggestion',
          'sub':[
            {
              'subTitle':'거래내역',
              'subCode':'OtcSuggestionList',
              'link':'OtcSuggestionList',
            },
            {
              'subTitle':'클레임내역',
              'subCode':'OtcClaimList',
              'link':'OtcClaimList',
            },
          ]
        },
        {
          'icon':'fas fa-wallet',
          'title':'입출금',
          'code':'wallet',
          'sub':[
            {
              'subTitle':'코인 입/출금',
              'subCode':'WalletTransferList',
              'link':'WalletTransferList',
            },
            {
              'subTitle':'안전 출금',
              'subCode':'WalletSafeWithdrawList',
              'link':'WalletSafeWithdrawList',
            },
          ]
        },
        {
          'icon':'fab fa-btc',
          'title':'코인',
          'code':'currency',
          'sub':[
            {
              'subTitle':'통화',
              'subCode':'CurrencyList',
              'link':'CurrencyList',
            },
          ]
        },
        {
          'icon':'fab fa-btc',
          'title':'마켓',
          'code':'marketPair',
          'sub':[
            {
              'subTitle':'마켓',
              'subCode':'MarketPairList',
              'link':'MarketPairList',
            },
            {
              'subTitle':'OTC 마켓',
              'subCode':'OtcPairList',
              'link':'OtcPairList',
            },
            {
              'subTitle':'기타 설정',
              'subCode':'MarketPairEtcSetting',
              'link':'MarketPairEtcSetting',
            },
          ]
        },
        {
          'icon':'fas fa-robot',
          'title':'봇',
          'code':'bot',
          'sub':[
            {
              'subTitle':'봇 리스트',
              'subCode':'BotList',
              'link':'BotList',
            },
            {
              'subTitle':'거래내역',
              'subCode':'BotTradeList',
              'link':'BotTradeList',
            },
            {
              'subTitle':'거래 통계',
              'subCode':'BotTradeStatistics',
              'link':'BotTradeStatistics',
            },
          ]
        },
        {
          'icon':'fas fa-cog',
          'title':'도구',
          'code':'setting',
          'sub':[
            {
              'subTitle':'마켓 정산',
              'subCode':'MarketSettleList',
              'link':'MarketSettleList',
            },
            {
              'subTitle':'사이트 통계',
              'subCode':'SiteStatistics',
              'link':'SiteStatistics',
            },
            {
              'subTitle':'사이트 설정',
              'subCode':'SiteSetting',
              'link':'SiteSetting',
            },
            {
              'subTitle':'데이터 관리',
              'subCode':'DataManagement',
              'link':'DataManagement',
            },
            {
              'subTitle':'게시판 관리',
              'subCode':'BoardManageList',
              'link':'BoardManageList',
            },
          ]
        },
        {
          'icon':'fas fa-align-left',
          'title':'기타',
          'code':'etc',
          'sub':[
            {
              'subTitle':'Google OTP',
              'subCode':'GoogleOTP',
              'link':'GoogleOTP',
            },
            {
              'subTitle':'로그아웃',
              'subCode':'Login',
              'link':'Login',
            },
          ]
        },
      ],
    }
  },
  created() {
    this.$root.$on(this.$EVENT_LNB_CHANGE, (_code, _subCode)=>{
      this.selectedCode = _code
      this.selectedSubCode = _subCode
    })
  }
}
</script>

<style>
</style>

<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">도구</h4>
            <span>사이트를 관리하기 위한 각종 도구를 제공합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">데이터 관리 > 마켓 거래내역</h5>
                <div class="row float-right" id="dataTableButtons">
                </div>
              </div><!-- /.card-header -->
              <div class="card-body">
                <table id="dataTable" class="table table-bordered">
                  <thead>
                  <tr>
                    <th>관리번호</th>
                    <th>주문식별번호</th>
                    <th>마켓</th>
                    <th>이메일</th>
                    <th>구분</th>
                    <th>체결가</th>
                    <th>수량</th>
                    <th>수수료</th>
                    <th>상태</th>
                    <th>일자</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, index) in items" :key="index">
                    <td>{{ item.tradeHistoryIdx }}</td>
                    <td>{{ item.exid }}</td>
                    <td>{{ item.primary }} ⇄ {{ item.secondary }}</td>
                    <td>{{ item.email }}</td>
                    <td>{{ item.isBuy ? '매수' : '매도' }}</td>
                    <td>{{ item.price }} {{ item.secondary }}</td>
                    <td>{{ item.amount }} {{ item.primary }}</td>
                    <td>{{ item.feeAmount }}</td>
                    <td>{{ item.status | marketStatusFilter }}</td>
                    <td>{{ item.createdAt | moment('YYYY-MM-DD HH:mm:ss')  }}</td>
                  </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix">
                <pagination ref="Pagination"></pagination>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>
import Pagination from "../../common/Pagination";

export default {
  name: 'DataMarketHistory',
  components: {
    Pagination,
  },
  data() {
    return {
      size: '10',
      page: 1,
      total: 0,
      selectedStart: '',
      selectedEnd: '',

      //data
      items: [],
    }
  },
  created() {
    let page = this.$route.query.page
    if(typeof(page) === 'undefined') {
      page = 1
    }

    let size = this.$route.query.size
    if(typeof(size) !== 'undefined') {
      this.size = size
    }

    this.selectedStart = new Date(this.$route.params.start).getTime()
    this.selectedEnd = new Date(this.$route.params.end).getTime()

    this.getMarketHistory(page)
  },
  methods: {
    getMarketHistory(page) {
      let params = new URLSearchParams()
      params.append('start', this.selectedStart)
      params.append('end', this.selectedEnd)
      if(typeof page === 'undefined') page = 1
      params.append('page', page)
      params.append('limit', this.size)

      this.$get(this.$TOOLS_MARKET_TRADE_LOG + '?' + params.toString(), this.$options.name + '_getMarketHistory', (result) => {
        this.page = page
        this.total = result.total

        try {
          this.$refs.Pagination.init(this.$options.name, {}, Number(this.page), this.total, Number(this.size))
        } catch (e) {
          console.log(e)
        }
        this.items = result.data

        this.$createTables()
        this.$destroyTables()
      }, (result) => {
        this.httpError(result)
      })
    },
  },

  filters: {
    marketStatusFilter: function(value) {
      if(value === 0) {
        return '미체결'
      } else if(value === 1) {
        return '미체결'
      } else if(value === 2) {
        return '체결 완료'
      } else if(value === 3) {
        return '취소'
      }
      return '거래중'
    }
  },

  watch: {
    'this.items'() {
      this.$createTables()
    },
    '$route.query.page'() {
      this.getMarketHistory(this.$route.query.page)
    },
  }

}
</script>

<style>
</style>

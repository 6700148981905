<template>
  <div class="m-3">
    <div class="card-header border-bottom-0">
      <h5 class="card-title mr-3 mt-2">출금 주소 내역</h5>
    </div><!-- /.card-header -->
    <div class="row align-items-center mb-2">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>통화</th>
            <th>주소 제목</th>
            <th>주소</th>
          </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>{{ item.ticker }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.address }}</td>
        </tr>
        <tr v-if="items.length === 0">
          <td colspan="3" style="text-align: center;">관련된 내역이 없습니다.</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  name: "UserDetailAddressWithdrawHistory",
  props: [
    'userIdx',
  ],
  data() {
    return {
      items: []
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$get(this.$ADDRESS_WITHDRAW.replace('?', this.userIdx), this.$options.name + '_getData', (result) => {
        this.items = result.data
      }, (result) => {
        this.httpError(result)
      })
    },
  }
}
</script>

<style>
</style>

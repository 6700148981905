<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">입출금</h4>
            <span> 화이트 리스트</span>
          </div><!-- /.col -->
        </div><!-- /.row -->
        <div class="row mb-2">
          <div class="col-sm-12">
            <h5 class="mb-2">필터</h5>
            <div class="row">
              <select class="form-control w-auto mr-1" v-model="selectedCurrency">
                <option value="">전체</option>
                <option v-for="(currency, index) in currencies" :key="index" :value="currency.ticker">{{ currency.name }}</option>
              </select>
              <select class="form-control w-auto mr-1" v-model="size">
                <option value="10">10줄</option>
                <option value="20">20줄</option>
                <option value="50">50줄</option>
                <option value="100">100줄</option>
              </select>
              <button class="btn btn-primary" @click="getList(page)">검색</button>
            </div>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">코인 출금 화이트리스트</h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <table class="table table-bordered">
                  <thead>
                  <tr>
                    <th>회원번호</th>
                    <th>사용자</th>
                    <th>통화</th>
                    <th>주소</th>
                    <th>출금횟수</th>
                    <th>액션</th>
                  </tr>
                  </thead>
                  <tbody v-if="items.length > 0">
                    <tr v-for="(item, index) in items" :key="index">
                      <td>{{ item.idx }}</td>
                      <td>{{ item.email }}</td>
                      <td>{{ item.currency }}</td>
                      <td>{{ item.address }}</td>
                      <td>{{ item.count }}</td>
                      <td class="text-center">
                        <div class="btn-group mr-2">
                          <button class="badge btn btn-success mr-1" v-bind:class="{ disabled: item.status }" @click="setData(item.idx, '1')">승인</button>
                          <button class="badge btn btn-danger" v-bind:class="{ disabled: !item.status }" @click="setData(item.idx, '0')">미승인</button>
                        </div>
                        <span class="badge btn btn-outline-danger" @click="deleteData(item.idx)">삭제</span>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="6" style="text-align: center;">관련된 내역이 없습니다.</td>
                    </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix">
                <pagination ref="Pagination"></pagination>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>
import Pagination from "../common/Pagination"

export default {
  name: 'AddressWhiteList',
  components: {
    Pagination,
  },
  data() {
    return {
      currencies: [],

      selectedCurrency: '',
      size: '10',
      page: 0,
      total: 0,

      //data
      items: []
    }
  },
  created() {
    this.$root.$emit(this.$EVENT_LNB_CHANGE, 'user', this.$options.name)

    let page = this.$route.query.page
    if(typeof(page) === 'undefined') {
      page = 1
    }

    let size = this.$route.query.size
    if(typeof(size) !== 'undefined') {
      this.size = size
    }

    let cy = this.$route.query.cy
    if(typeof(cy) !== 'undefined') {
      this.selectedCurrency = cy
    }

    this.getFilterData(() => {
      this.getList(page)
    })
  },
  methods: {
    getFilterData(callback) {
      this.$get(this.$USER_FILTER, this.$options.name + '_getFilterData', (result) => {
        this.currencies = result.data.currency

        callback()
      }, (result) => {
        this.httpError(result)
      })
    },
    getList(page) {
      let params = new URLSearchParams()
      if(typeof page === 'undefined') page = 1
      params.append('page', page)
      params.append('limit', this.size)
      params.append('currency', this.selectedCurrency)

      this.$get(this.$USER_TRANSFER_WHITE + '?' + params.toString(), this.$options.name + '_getList', (result) => {
        this.page = page
        this.total = result.total

        try {
          this.$refs.Pagination.init(this.$options.name, {
            cy: this.selectedCurrency,
          }, Number(this.page), this.total, Number(this.size))
        } catch (e) {
          console.log(e)
        }

        this.items = result.data
      }, (result) => {
        this.httpError(result)
      })
    },
    setData(idx, state) {
      let formData = new FormData()
      formData.append('transferIdx', idx)
      formData.append('status', state)

      this.$put(this.$USER_TRANSFER_WHITE, this.$options.name + '_setData', formData, (result) => {
        this.getList(this.page)
        this.notify('success', result.message, false)
      }, (result) => {
        this.httpError(result)
      })
    },
    deleteData(idx) {
      this.$delete(this.$USER_TRANSFER_WHITE + '?transferIdx=' + idx, this.$options.name + '_deleteData', (result) => {
        this.getList(this.page)

        this.notify('success', result.message, false)
      }, (result) => {
        this.httpError(result)
      })
    },
  },
  watch: {
    '$route.query.page'() {
      this.getList(this.$route.query.page)
    }
  },
}
</script>

<style>
</style>

<template>
  <div class="modal fade" id="modal-UserAssetPopup">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">자산현황</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <table class="table table-bordered mb-0">
            <thead>
              <tr>
                <th>통화</th>
                <th>라벨</th>
                <th>보유 수량</th>
                <th>주문가능</th>
                <th>주문대기</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in items" :key="index">
                <th>{{ item.currency }}</th>
                <td>{{ item.ticker }}</td>
                <td class="text-right">{{ item.balance }}</td>
                <td class="text-right">{{ item.available }}</td>
                <td class="text-right">{{ item.wait }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal" aria-label="Close">닫기</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
  <!-- /.modal -->
</template>

<script>
export default {
  name: 'UserAssetPopup',
  data() {
    return {
      items: [],
    }
  },
  methods: {
    init(idx) {
      this.$get(this.$USER_ASSET.replace('?', idx), this.$options.name + '_init', (result) => {
        this.items = result.data
        window.$('#modal-UserAssetPopup').modal('show')
      }, (result) => {
        this.httpError(result)
      })
    },
  },
}
</script>

<style>
</style>

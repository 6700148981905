<template>
  <div class="m-3">
    <div class="card-header border-bottom-0">
      <h5 class="card-title">개인정보 수정</h5>
    </div><!-- /.card-header -->
    <div class="row align-items-center mb-2">
      <div class="col-sm-3 text-right">
        <strong>No. *</strong>
      </div>
      <div class="col-sm-6">
        <input type="text" class="form-control disabled" disabled v-model="userIdx" />
      </div>
    </div>
    <div class="row align-items-center mb-2">
      <div class="col-sm-3 text-right">
        <strong>이메일 *</strong>
      </div>
      <div class="col-sm-6">
        <input type="text" class="form-control" disabled v-model="data.email" />
      </div>
    </div>
    <div class="row align-items-center mb-2">
      <div class="col-sm-3 text-right">
        <strong>닉네임 *</strong>
      </div>
      <div class="col-sm-6">
        <input type="text" class="form-control" v-model="formData.nickName" />
      </div>
    </div>
    <div class="row align-items-center mb-2">
      <div class="col-sm-3 text-right">
        <strong>휴대폰 *</strong>
      </div>
      <div class="col-sm-6">
        <input type="text" class="form-control" v-model="formData.phone" />
      </div>
    </div>
    <div class="row align-items-center mb-2">
      <div class="col-sm-3 text-right">
        <strong>핀번호 *</strong>
      </div>
      <div class="col-sm-6">
        <input type="text" class="form-control" v-model="formData.pinNumber" />
      </div>
    </div>
    <div class="row align-items-center mb-2">
      <div class="col-sm-3 text-right">
        <strong>인증방법 *</strong>
      </div>
      <div class="col-sm-6">
        <select class="form-control" v-model="formData.authMethod">
          <option value="PHONE">PHONE</option>
          <option value="PIN" v-show="formData.pin">PIN</option>
          <option value="OTP" v-show="formData.otp">OTP</option>
        </select>
      </div>
    </div>
    <div class="row align-items-center mb-2">
      <div class="col-sm-3 text-right">
        <strong>인증등급 *</strong>
      </div>
      <div class="col-sm-6">
        <select class="form-control authLevel" v-model="formData.authLevel">
          <option v-for="(authLevel, index) in authLevelList" :key="index" :value="authLevel">Lv.{{ authLevel }}</option>
        </select>
        <span v-show="idCardUrl" type="button" class="btn-default border-0" @click="downloadIdImg()">
          [신분증 다운로드]
        </span>
      </div>
    </div>
    <div class="row align-items-center mb-2">
      <div class="col-sm-3 text-right">
        <strong>대리점 코드</strong>
      </div>
      <div class="col-sm-6">
        <select class="form-control" v-model="formData.recruiter">
          <option value="">없음</option>
          <option v-for="(agency, index) in agencyList" :key="index" :value="agency.recruitingCode">{{ agency.nickName }} ({{ agency.recruitingCode }})</option>
        </select>
      </div>
    </div>

    <div class="row align-items-center mb-2">
      <div class="col-sm-3 text-right">
        <strong>인증(Trust)회원 여부</strong>
      </div>
      <div class="col-sm-6">
        <select class="form-control" v-model="formData.stateTrust">
          <option :value="true">인증됨</option>
          <option :value="false">인증되지 않음</option>
        </select>
      </div>
    </div>

    <div class="row align-items-center mb-2">
      <div class="col-sm-3 text-right">
        <strong>OTP 사용여부</strong>
      </div>
      <div class="col-sm-6">
        <span v-if="data.otp">사용</span>
        <span v-else>미사용</span>
      </div>
    </div>
    <div class="row align-items-center mb-2">
      <div class="col-sm-3 text-right">
        <strong>메모</strong>
      </div>
      <div class="col-sm-6">
        <textarea class="form-control" rows="2" v-model="formData.memo"></textarea>
      </div>
    </div>
    <div class="row align-items-center mb-2">
      <div class="col-sm-3 text-right">
      </div>
      <div class="col-sm-6">
        <button type="button" class="btn btn-primary" @click="setData()">개인정보 수정</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "UserDetailInfo",
  props: [
      'userIdx',
  ],
  data() {
    return {
      agencyList: [],
      authLevelList: [],
      authMethodList: [],
      authList: [],
      data: {
        email: '',
        otp: false,
      },
      formData: {
        authMethod: '',
        memo: '',
        nickName: '',
        phone: '',
        recruiter: '',
        authLevel: '',
        otp: false,
        pin: false,
        stateTrust: false,
        pinNumber: ''
      },
      idCardUrl: '',
    }
  },
  created() {
    this.getFilterData(() => {
      this.getData()
    })
  },
  methods: {
    getFilterData(callback) {
      this.$get(this.$USER_FILTER, this.$options.name + '_getFilterData', (result) => {
        this.agencyList = result.data.agency
        this.authLevelList = result.data.authLevel
        this.authMethodList = result.data.authMethod
        if(this.authMethodList.includes('ID')) {
          let idx = this.authMethodList.indexOf('ID')
          this.authMethodList.splice(idx, 1)
        }
        this.authList = result.data.status

        callback()
      }, (result) => {
        this.httpError(result)
      })
    },
    getData() {
      this.$get(this.$USER_DETAIL.replace('?', this.userIdx), this.$options.name + '_getData', (result) => {
        this.getFormatData(this.data, result)
        this.getFormatData(this.formData, result)
        this.idCardUrl = result.data.imageKeyIdCard

        // this.authListUser = []
        // for (let i=0; i<this.authList.length; i++) {
        //   if(!this.authListUser[this.authList[i].level]) {
        //     this.authListUser[this.authList[i].level] = []
        //   }
        //
        //   if(!this.authList[i].twoFactor) {
        //     continue
        //   }
        //
        //   if(!this.data[this.authList[i].authMethod.toLowerCase()] && !this.formData[this.authList[i].authMethod.toLowerCase()]) {
        //     continue
        //   }
        //   this.authListUser[this.authList[i].level].push({
        //     'method': this.authList[i].authMethod,
        //     'twoFa': this.authList[i].twoFactor,
        //   })
        //
        // }
      }, (result) => {
        this.httpError(result)
      })
    },
    setData() {
      let formData = new FormData()
      Object.entries(this.formData).forEach((entry) => {
        let [key, value] = entry
        formData.append(key === 'authLevel' ? 'secureLevel' : key, (value+'').trim())
      })

      this.$put(this.$USER_DETAIL.replace('?', this.userIdx), this.$options.name + '_setData', formData, (result) => {
        this.notify('success', result.message, false)
      }, (result) => {
        this.httpError(result)
      })
    },
    downloadIdImg() {
      axios({
        url : `${this.$IMG_DOMAIN}?key=${this.idCardUrl}`,
        method : 'GET',
        responseType : 'blob',
      }).then((response) => {
        let fileURL = window.URL.createObjectURL(new Blob([response.data]))
        let fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute('download', `${this.userIdx}_idImg.jpeg`)
        document.body.appendChild(fileLink)

        fileLink.click()
      })

    }
  },
}
</script>

<style scoped>
/*.authLevel {*/
/*  -webkit-appearance:none; !* for chrome *!*/
/*  -moz-appearance:none; !*for firefox*!*/
/*  appearance:none;*/
/*}*/

/*.authLevel::-ms-expand{*/
/*  display:none;!*for IE10,11*!*/
/*}*/
</style>

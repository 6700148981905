<template>
  <div class="m-3">
    <div class="card-header border-bottom-0">
      <h5 class="card-title mr-3 mt-2">OTC 거래기록</h5>
<!--      <button class="badge btn btn-primary ml-2" @click="goMore()">더보기</button>-->
    </div><!-- /.card-header -->
    <div class="row align-items-center">
      <table class="table table-bordered text-center">
        <thead>
        <tr>
          <th>거래번호</th>
          <th>구분</th>
          <th>거래코인</th>
          <th>등록자</th>
          <th>거래자</th>
          <th>거래수량</th>
          <th>수수료</th>
          <th>거래가격</th>
          <th>거래일시</th>
          <th>상태</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>{{ item.idx }}</td>
          <td>{{ item.tradeIsBuy ? '매도' : '매수' }}</td>
          <td>{{ item.primary.includes('UNLISTED') ? `${item.primary.split('-')[1]}(직접거래)` : item.primary }}</td>
          <td>{{ item.email }}</td>
          <td>{{ item.opposite }}</td>
          <td>{{ item.tradeAmount }}</td>
          <td>{{ item.tradeFee }}</td>
          <td>{{ item.price }}</td>
          <td>{{ item.createdAt | moment('YYYY-MM-DD HH:mm:ss') }}</td>
          <td>{{ E_P2PState[item.state] }}</td>
        </tr>
        <tr v-if="items.length === 0">
          <td colspan="10" style="text-align: center;">관련된 내역이 없습니다.</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserDetailOtcHistory",
  props: [
    'userIdx',
  ],
  data() {
    return {
      items: []
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$get(this.$USER_OTC_TRADE.replace('?', this.userIdx), this.$options.name + '_getData', (result) => {
        this.items = result.data
      }, (result) => {
        this.httpError(result)
      })
    },
  }
}
</script>

<style scoped>

</style>

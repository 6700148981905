<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">OTC 게시글</h4>
            <span>데이터베이스에 기록된 OTC 게시글 목록을 확인합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->
        <div class="row mb-2">
          <div class="col-sm-12">
            <h5 class="mb-2">필터</h5>
            <div class="row">
              <select class="form-control w-auto mr-1" v-model="selectedPrimary">
                <option value="">전체</option>
                <option v-for="(primary, index) in primaryList" :key="index" :value="primary">{{ primary.includes('UNLISTED') ? `${primary.split('-')[1]}(직접거래)` : primary }}</option>
              </select>
              <select class="form-control w-auto mr-1" v-model="selectedOtcTradeState">
                <option value="">전체</option>
                <option :value=false>매도</option>
                <option :value=true>매수</option>
              </select>
              <select class="form-control w-auto mr-1" v-model="selectedSearchType">
                <option value="email">이메일</option>
                <option value="nickname">닉네임</option>
              </select>
              <input type="text" class="form-control w-auto mr-1" placeholder="검색어" v-model="searchKeyword">
              <select class="form-control w-auto mr-1" v-model="size">
                <option value="10">10줄</option>
                <option value="20">20줄</option>
                <option value="50">50줄</option>
                <option value="100">100줄</option>
              </select>
              <button class="btn btn-primary" @click="getList(page)">검색</button>
            </div>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>게시글 번호</th>
                      <th>구분</th>
                      <th>코인</th>
                      <th>이메일</th>
                      <th>제목</th>
                      <th>작성일자</th>
                      <th>상태</th>
                      <th>노출 여부</th>
                      <th>노출 액션</th>
                      <th>상세정보</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in items" :key="index">
                      <td>{{ item.idx }}</td>
                      <td>{{ item.boardIsBuy ? '매수' : '매도' }}</td>
                      <td>{{ item.ticker.includes('UNLISTED') ? `${item.ticker.split('-')[1]}(직접거래)` : item.ticker }}</td>
                      <td>{{ item.id }}</td>
                      <td>{{ item.title }}</td>
                      <td>{{ item.createdAt | moment('YYYY-MM-DD HH:mm:ss') }}</td>
                      <td>{{ E_P2PState[item.state] }}</td>
                      <td>{{ item.isShow ? 'O' : 'X' }}</td>
                      <td class="text-center">
                        <span v-if="!item.isShow" class="badge btn btn-outline-primary" @click="dealDate(item.idx, true)">보이기</span>
                        <span v-else class="badge btn btn-outline-danger" @click="dealDate(item.idx, false)">감추기</span>
                      </td>
                      <td class="text-center">
                        <span class="badge btn btn-outline-warning mr-2" @click="goDetailPage(item.idx)">상세보기</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix">
                <pagination ref="Pagination"></pagination>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>
import Pagination from "../common/Pagination";

export default {
  name: "OtcSuggestionList",
  components: {
    Pagination,
  },
  data() {
    return {
      boardIdx: 0,
      primaryList: [],

      selectedPrimary: '',
      selectedSearchType: 'email',
      searchKeyword: '',
      selectedOtcTradeState : '',

      size: '10',
      page: 0,
      total: 0,

      //data
      items : [],
    }
  },
  created() {
    this.$root.$emit(this.$EVENT_LNB_CHANGE, 'otcSuggestion', this.$options.name)

    let page = Number(this.$route.query.page)
    if(typeof(page) === 'undefined' || isNaN(page)) {
      page = 1
    }

    let size = this.$route.query.size
    if(typeof(size) !== 'undefined') {
      this.size = size
    }

    let pc = this.$route.query.pc
    if(typeof(pc) !== 'undefined') {
      this.selectedPrimary = pc
    }

    let ts = this.$route.query.ts
    if(typeof(ts) !== 'undefined') {
      this.selectedOtcTradeState = ts
    }

    let st = this.$route.query.st
    if(typeof(st) !== 'undefined') {
      this.selectedSearchType = st
    }

    let sk = this.$route.query.sk
    if(typeof(sk) !== 'undefined') {
      this.searchKeyword = sk
    }

    this.getFilterData(() => {
      this.getList(page)
    })

  },
  methods: {
    getFilterData(callback) {
      this.$get(`${this.$MARKET_FILTER}?isOtc=true`, this.$options.name + '_getFilterData', (result) => {
        result.data.currency.map(c => this.primaryList.push(c.ticker))
        callback()
      }, (result) => {
        this.httpError(result)
      })
    },
    getList(page) {
      let formData = new FormData()
      if(typeof page === 'undefined') page = 1
      formData.append('page', page)
      formData.append('limit', this.size)
      formData.append('primary', this.selectedPrimary)
      formData.append('isBuy', this.selectedOtcTradeState)
      formData.append('criteria', this.selectedSearchType)
      formData.append('keyword', this.searchKeyword)

      this.$post(this.$OTC_SUGGESTION, this.$options.name + '_getList', formData,(result) => {
        this.page = page
        this.total = result.total

        try {
          this.$refs.Pagination.init(this.$options.name, {
            pc: this.selectedPrimary,
            ts: this.selectedOtcTradeState,
            st: this.selectedSearchType,
            sk: this.searchKeyword,
          }, Number(this.page), this.total, Number(this.size))
        } catch (e) {
          console.log(e)
        }

        this.items = result.data
      }, (result) => {
        this.httpError(result)
      })
    },

    goDetailPage(_idx) {
      this.$router.push({ name: 'OtcSuggestionDetail', params: { idx: _idx }})
    },
    dealDate(_idx, _isShow) {
      let formDate = new FormData()
      formDate.append('idx', _idx)
      formDate.append('isShow', _isShow)

      this.$post(this.$OTC_SUGGESTION + '/' + _idx, this.$options.name + '_restoreData', formDate, (result) => {
        console.log(result)
        this.getList(this.page)
      }, (result) => {
        this.httpError(result)
      })
    }
  },
  watch: {
    '$route.query.page'() {
      this.getList(this.$route.query.page)
    },
  },
}
</script>

<style scoped>

</style>
<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">관리자</h4>
            <span> 데이터베이스에 기록된 관리자 목록을 확인합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">관리자 등록</h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>이메일 *</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="text" class="form-control" v-model="formData.email"/>
                  </div>
                  <div class="col-sm-4">
                    <span>관리자 로그인시 사용되는 이메일 주소입니다.</span>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>비밀번호 *</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="password" class="form-control" v-model="formData.password"/>
                  </div>
                  <div class="col-sm-4">
                    <span>관리자 로그인시 사용되는 비밀번호입니다.</span>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>비밀번호 확인 *</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="password" class="form-control" v-model="formData.passwordConfirm"/>
                  </div>
                  <div class="col-sm-4">
                    <span>비밀번호 확인을 위해 한번 더 입력하세요.</span>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>등급 *</strong>
                  </div>
                  <div class="col-sm-5">
                    <select class="form-control" v-model="formData.adminLevel">
                      <option value="1" v-if="myInfo.adminLevel > 1">LV1</option>
                      <option value="2" v-if="myInfo.adminLevel > 2">LV2</option>
                      <option value="3" v-if="myInfo.adminLevel > 3">LV3</option>
                      <option value="4" v-if="myInfo.adminLevel > 4">LV4</option>
                      <option value="5" v-if="myInfo.adminLevel > 5">LV5</option>
                      <option value="6" v-if="myInfo.adminLevel > 6">LV6</option>
                      <option value="7" v-if="myInfo.adminLevel > 7">LV7</option>
                    </select>
                  </div>
                  <div class="col-sm-4">
                    <span>접근권한 관리를 위한 등급을 지정하세요.</span>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>닉네임</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="text" class="form-control" v-model="formData.nickName"/>
                  </div>
                  <div class="col-sm-4">
                    <span>관리자의 닉네임입니다.</span>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>휴대폰 번호 *</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="text" class="form-control" v-model="formData.phone"/>
                  </div>
                  <div class="col-sm-4">
                    <span>관리자 로그인시 사용되는 휴대폰 번호입니다.</span>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>추천인 코드</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="text" class="form-control" v-model="formData.recruitingCode"/>
                  </div>
                  <div class="col-sm-4">
                    <span>일반회원 가입시 추천받을 코드를 입력하세요.</span>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>적용 수수료</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="text" class="form-control" v-model="formData.recruitingFee"/>
                  </div>
                  <div class="col-sm-4">
                    <span>추천인 회원 거래시 적용되는 수수료 요율(%)을 설정하세요.</span>
                  </div>
                </div>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix text-center row">
                <div class="col-sm-3">
                </div>
                <div class="col-sm-5">
                  <button type="button" class="btn btn-primary mr-1" @click="newData()">저장</button>
                  <button type="button" class="btn btn-secondary" @click="$router.back()">목록</button>
                </div>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'AdminRegist',
  data() {
    return {
      formData: {
        email: '',
        password: '',
        passwordConfirm: '',
        adminLevel: '1',
        nickName: '',
        phone: '',
        recruitingCode: '',
        recruitingFee: '',
      },
      myInfo: {
        adminLevel: '1',
        email: '',
        idx: 0,
        nickName: '',
        registerDate: '',
      },
    }
  },
  created() {
    this.myInfo = JSON.parse(sessionStorage.getItem('my_info'))
  },
  methods: {
    newData() {
      let formData = new FormData()
      Object.entries(this.formData).forEach((entry) => {
        let [key, value] = entry
        formData.append(key, (value+'').trim())
      })

      this.$post(this.$MANAGER, this.$options.name + '_newData', formData, (result) => {
        this.notify('success', result.message, false)

        this.$router.push({
          name: 'AdminManage'
        })
      }, (result) => {
        this.httpError(result)
      })
    },
  }
}
</script>

<style>
</style>

import axios from 'axios'

let requestOption = () => {
    return {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        },
        timeout: 60000
    }
}
let requestOptionWithToken = () => {
    return {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        },
        timeout: 60000
    }
}

let requestOptionForDownload = () => {
    return {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        timeout: 60000,
        responseType: 'blob',
    }
}
let requestOptionWithTokenForDownload = () => {
    return {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        },
        timeout: 60000,
        responseType: 'blob',
    }
}

let pending_get = {}
let pending_post = {}
let pending_put = {}
let pending_delete = {}

export default Object.freeze  ({
    install(Vue) {
        Vue.prototype.$DOMAIN = process.env.VUE_APP_DOMAIN + "/api/v1/admin"
        Vue.prototype.$IMG_DOMAIN = process.env.VUE_APP_DOMAIN + "/api/v1/image"

        Vue.prototype.$REFRESHTOKEN = "/auth/token"

        Vue.prototype.$AUTH_OTP = "/auth/otp"
        Vue.prototype.$AUTH_LOGIN = "/auth/login"
        Vue.prototype.$AUTH_LOGIN_PHONE_SEND = "/auth/login/phone/send"
        Vue.prototype.$AUTH_LOGIN_PHONE_CERT = "/auth/login/phone/cert"
        Vue.prototype.$AUTH_LOGIN_OTP_CERT = "/auth/login/otp/cert"

        Vue.prototype.$MANAGER = "/manager"
        Vue.prototype.$MANAGER_DETAIL = "/manager/detail/?"
        Vue.prototype.$MANAGER_HISTORY_LOGIN = "/manager/history/login"

        Vue.prototype.$BOT_LIST = "/bot/list" // 봇 리스트
        Vue.prototype.$BOT_ADD = "/bot/add" // 봇 등록
        Vue.prototype.$BOT_MARKET_PAIR = "/bot/marketpair" // 마켓페어 리스트
        Vue.prototype.$BOT_RUN = "/bot/run" // 봇 일부 실행or중지
        Vue.prototype.$BOT_RUN_ALL = "/bot/run/all" // 봇 전체 실행or중지
        Vue.prototype.$BOT_REFER = "/bot/?" // 봇 조회
        Vue.prototype.$BOT_EDIT = "/bot/edit" // 봇 수정
        Vue.prototype.$BOT_DELETE = "/bot/delete/?" // 봇 삭제
        Vue.prototype.$BOT_TRADE_LOG = "/bot/log/trade" // 봇 거래내역
        Vue.prototype.$BOT_LOG_STATS = "/bot/log/stats" // 봇 거래통계
        Vue.prototype.$BOT_FOLLOW_EXCHANGE = "/bot/follow/" // 추종시세 조회

        Vue.prototype.$ADDRESS_WITHDRAW = "/user/address/withdraw/?" // 사용자 출금 주소록

        Vue.prototype.$USER = "/user"
        Vue.prototype.$USER_FILTER = "/user/filter"
        Vue.prototype.$USER_DETAIL = "/user/detail/?"
        Vue.prototype.$USER_DETAIL_PASSWORD = "/user/detail/?/password"
        Vue.prototype.$USER_STATUS = "/user/status/?"
        Vue.prototype.$USER_MEMO = "/user/memo/?"
        Vue.prototype.$USER_ASSET = "/user/asset/?"
        Vue.prototype.$USER_SMS = "/user/sms"
        Vue.prototype.$USER_TRANSFER_WHITE = "/user/transfer/white"
        Vue.prototype.$USER_TRANSFER_BLACK = "/user/transfer/black"
        Vue.prototype.$USER_HISTORY_LOGIN = "/user/history/login"
        Vue.prototype.$USER_ADDRESS_BLOCKED = "/user/address/blocked"
        Vue.prototype.$USER_WALLET_DETAIL = "/user/wallet/detail/?"
        Vue.prototype.$USER_CREDIT = "/user/credit/?"
        Vue.prototype.$USER_OTC_TRADE = "/user/otc/trade/?"
        Vue.prototype.$USER_CONSULT = "/user/consult"
        Vue.prototype.$USER_DELETE = "/user/delete"

        Vue.prototype.$MARKET_FILTER = "/market/filter"
        Vue.prototype.$MARKET_TRADE = "/market/trade"
        Vue.prototype.$MARKET_TRADE_HISTORY = "/market/trade/history"
        Vue.prototype.$MARKET_TRADE_DETAIL = "/market/trade/detail/?"
        Vue.prototype.$MARKET = "/market"
        Vue.prototype.$MARKET_DETAIL = "/market/detail/?"
        Vue.prototype.$MARKET_DISPLAY = "/market/etc/display" // 스팟거래소 화면 표시 부통화 조회, 설정
        Vue.prototype.$MARKET_DIRECTLY_TRADING_COINS = "/market/etc/unlisted" // OTC 직접거래 코인 설정
        Vue.prototype.$MARKET_CANCEL = "/market/trade/cancel" // 거래 취소

        Vue.prototype.$OTC_SUGGESTION = "/otc/temp" // OTC 제안 리스트(POST)
        Vue.prototype.$OTC_SUGGESTION_DETAIL = "/otc/temp/detail/?" // OTC 제안 상세보기, 상세보기-저장
        Vue.prototype.$OTC_SUGGESTION_DETAIL_TRADING_LIST = "/otc/temp/detail/?/tradings" // OTC 제안 상세 보기- 거래 내역
        Vue.prototype.$OTC_SUGGESTION_DETAIL_CHAT_LIST = "/otc/temp/detail/?/chatroom" // OTC 제안 상세보기- 채팅 내역
        Vue.prototype.$OTC_SUGGESTION_DETAIL_CHAT_LOG = "/otc/temp/chatroom/?" // OTC 제안 상세보기- 채팅 상세
        Vue.prototype.$OTC_SUGGESTION_DETAIL_CHAT_IMAGE = "/otc/" // OTC 제안 상세보기의 채팅 이미지

        Vue.prototype.$OTC_CLAIM_LIST = "/otc/temp/claim/list" // 클레임 리스트
        Vue.prototype.$OTC_CLAIM_DETAIL = "/otc/temp/claim/?" // 클레임 상세보기
        Vue.prototype.$OTC_CLAIM_ACTION = "/otc/temp/claim/action" // 클레임 액션

        Vue.prototype.$COIN = "/coin"
        Vue.prototype.$COIN_DETAIL = "/coin/detail/?"
        Vue.prototype.$COIN_BALANCE = "/coin/balance/?"

        Vue.prototype.$TRANSFER = "/transfer"
        Vue.prototype.$TRANSFER_FILTER = "/transfer/filter"
        Vue.prototype.$TRANSFER_SAFE = "/transfer/safe"

        Vue.prototype.$PROFIT = "/profit"
        Vue.prototype.$PROFIT_FILTER = "/profit/filter"
        Vue.prototype.$PROFIT_MONTH = "/profit/month"

        Vue.prototype.$TOOLS_BOARD = "/tools/board"
        Vue.prototype.$TOOLS_BOARD_DETAIL = "/tools/board/detail/?"
        Vue.prototype.$TOOLS_BOARD_CONTENTS = "/tools/board/contents/?"
        Vue.prototype.$TOOLS_BOARD_CONTENTS_DETAIL = "/tools/board/contents/?/?"
        Vue.prototype.$TOOLS_BOARD_CONTENTS_COMMENT = "/tools/board/contents/?/?/comment"
        Vue.prototype.$TOOLS_BOARD_CONTENTS_COMMENT_DETAIL = "/tools/board/contents/?/?/comment/?"
        Vue.prototype.$TOOLS_USER = "/tools/user" // 엑셀파일을 통한 유저일괄 등록
        Vue.prototype.$TOOLS_EXAMPLE = "/tools/user/example" // 사용자 등록 엑셀 예제 파일 다운로드

        Vue.prototype.$SETTING_FILTER = "/setting/filter"
        Vue.prototype.$SETTING_METHOD = "/setting/?"
        Vue.prototype.$SETTING_SNSID = "/setting/sns" // 거래소 SNS 계정 조회, 저장

        Vue.prototype.$STATS_FILTER = "/stats/filter"
        Vue.prototype.$STATS_SITE = "/stats/site"

        Vue.prototype.$DASHBOARD = "/dashBoard" // 대시보드

        Vue.prototype.$TOOLS_OPTIONS_SEARCH = "/tools/options/search" // 도구-데이터관리 검색옵션 목록 조회
        Vue.prototype.$TOOLS_BALANCE = "/tools/balance" // 도구-데이터관리 회원 잔액보유량
        Vue.prototype.$TOOLS_MARKET_TRADE_LOG = "/tools/market/tradeLog" // 도구-데이터관리 마켓 거래내역
        Vue.prototype.$TOOLS_OTC_TRADE_LOG = "/tools/otc/tradeLog" // 도구-데이터관리 OTC 거래내역
        Vue.prototype.$TOOLS_MARKET_FEE_LOG = "/tools/market/feeLog" // 도구-데이터관리 마켓 사용자별 수수료내역
        Vue.prototype.$TOOLS_WALLET_LOG = "/tools/wallet/log" // 도구-데이터관리 지갑 거래내역
        Vue.prototype.$TOOLS_OTC_FEE_LOG = "/tools/otc/feeLog" // 도구-데이터관리 OTC 사용자별 수수료내역

        Vue.prototype.$post = function (callUrl, caller, postData, success, fail) {
            if (pending_post[arguments[0] + caller]) {
                console.log('request post fail : ' + arguments[0] + caller)
                return
            }
            pending_post[arguments[0] + caller] = true
            let _requestOption = requestOption()
            let token = sessionStorage.getItem('token')
            if(token && token.length > 0) {
                _requestOption = requestOptionWithToken()
            }

            axios.post(this.$DOMAIN + callUrl, postData, _requestOption).then((response) => {
                pending_post[arguments[0] + caller] = false
                if (response.data.result === true) {
                    success(response.data)
                } else {
                    fail(response)
                }
            }).catch((e) => {
                pending_post[arguments[0] + caller] = false
                fail(e.response)
            })
        }

        Vue.prototype.$put = function (callUrl, caller, postData, success, fail) {
            if (pending_put[arguments[0] + caller]) {
                console.log('request put fail : ' + arguments[0] + caller)
                return
            }
            pending_put[arguments[0] + caller] = true
            let _requestOption = requestOption()
            let token = sessionStorage.getItem('token')
            if(token && token.length > 0) {
                _requestOption = requestOptionWithToken()
            }

            axios.put(this.$DOMAIN + callUrl, postData, _requestOption).then((response) => {
                pending_put[arguments[0] + caller] = false
                if (response.data.result === true) {
                    success(response.data)
                } else {
                    fail(response)
                }
            }).catch((e) => {
                pending_put[arguments[0] + caller] = false
                fail(e.response)
            })
        }

        Vue.prototype.$delete = function (callUrl, caller, success, fail) {
            if (pending_delete[arguments[0] + caller]) {
                console.log('request delete fail : ' + arguments[0] + caller)
                return
            }
            pending_delete[arguments[0] + caller] = true
            let _requestOption = requestOption()
            let token = sessionStorage.getItem('token')
            if(token && token.length > 0) {
                _requestOption = requestOptionWithToken()
            }

            axios.delete(this.$DOMAIN + callUrl, _requestOption).then((response) => {
                pending_delete[arguments[0] + caller] = false
                if (response.data.result === true) {
                    success(response.data)
                } else {
                    fail(response)
                }
            }).catch((e) => {
                pending_delete[arguments[0] + caller] = false
                fail(e.response)
            })
        }

        Vue.prototype.$get = function (callUrl, caller, success, fail) {
            if (pending_get[arguments[0] + caller]) {
                console.log('request get fail : ' + arguments[0] + caller)
                return
            }
            pending_get[arguments[0] + caller] = true
            let _requestOption = requestOption()
            let token = sessionStorage.getItem('token')
            if(token && token.length > 0) {
                _requestOption = requestOptionWithToken()
            }

            axios.get(this.$DOMAIN + callUrl, _requestOption).then((response) => {
                pending_get[arguments[0] + caller] = false
                if (response.data.result === true) {
                    success(response.data)
                } else {
                    fail(response)
                }
            }).catch(e => {
                pending_get[arguments[0] + caller] = false
                fail(e.response)
            })
        }

        Vue.prototype.$download_get = function (callUrl, caller, img, others, fail){
            console.log(arguments[0])
            if (pending_post[arguments[0] + caller]) {
                return
            }
            pending_post[arguments[0] + caller] = true
            let _requestOption = requestOptionForDownload()
            let token = sessionStorage.getItem('token')
            if(token && token.length > 0) {
                _requestOption = requestOptionWithTokenForDownload
            }

            axios.get(this.$IMG_DOMAIN + callUrl, _requestOption).then((response) => {
                pending_post[arguments[0] + caller] = false
                if (response.headers['content-type'] === 'image/jpeg' || response.headers['content-type'] === 'image/png') {
                    img(response.request.responseURL)
                // } else if(response.headers['content-type'] === 'text/plain') {
                //     others(response.data)
                // } else {
                //     fail(response.data)
                } else {
                    others(response)
                }
            }).catch(e => {
                pending_get[arguments[0] + caller] = false
                fail(e.response)
                if(e.response.data.status === 403) {
                    this.$router.replace({name: 'Login'})
                    sessionStorage.removeItem('token')
                    // this.$store.state.isLoggedIn = false
                }
            })
        },

        Vue.prototype.$download = function (callUrl, caller, useToken, success, fail){
            console.log(arguments[0])
            if (pending_delete[arguments[0] + caller]) {
                return
            }
            pending_delete[arguments[0] + caller] = true
            let _requestOption = requestOptionForDownload()
            if (useToken) {
                let token = sessionStorage.getItem('token')
                if (token && token.length > 0) {
                    _requestOption = requestOptionWithTokenForDownload()
                } else {
                    this.$router.replace({name: 'Login'})
                }
            }
            axios.get(this.$IMG_DOMAIN + callUrl, _requestOption).then(response => {
                pending_get[arguments[0] + caller] = false
                if (response.headers['content-type'] === 'application/octet-stream') {
                    success(response)
                } else {
                    fail(response.data)
                }
            }).catch(e => {
                pending_get[arguments[0] + caller] = false
                fail(e.response)
                if(e.response.data.status === 403) {
                    this.$router.replace({name: 'Login'})
                    sessionStorage.removeItem('token')
                    this.$store.state.isLoggedIn = false
                }
            })
        }
    }
})

<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">도구</h4>
            <span> 사이트를 관리하기 위한 각종 도구를 제공합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <div class="row">
                  <select class="form-control w-auto ml-auto mr-1" v-model="selectedCurrency">
                    <option v-for="(currency, index) in currencyList" :key="index" :value="currency.ticker">{{ currency.name }}</option>
                  </select>
                  <select class="form-control w-auto mr-1" v-model="selectedYear">
                    <option v-for="(year, index) in yearList" :key="index" :value="year">{{ year }}</option>
                  </select>
                  <select class="form-control w-auto mr-1" v-model="selectedMonth">
                    <option v-for="(month, index) in months" :key="index" :value="month">{{ month }}</option>
                  </select>
                  <select class="form-control w-auto mr-1" v-model="selectedAgency">
                    <option value="">대리점별 보기</option>
                    <option v-for="(agency, index) in agencyList" :key="index" :value="agency.recruitingCode">{{ agency.nickName }} ({{ agency.recruitingCode }})</option>
                  </select>
                  <button class="mr-auto btn btn-primary" @click="getData()">검색</button>
                </div>
              </div><!-- /.card-header -->
              <div class="card-body">
                <!-- THE CALENDAR -->
                <div id="calendar"></div>
              </div><!-- ./card-body -->
              <div class="card-footer text-center">
                <button type="button" class="btn btn-primary" @click="$router.back()">목록</button>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'MarketSettleCalendar',
  data() {
    return {
      //search filter
      agencyList: [],
      currencyList: [],
      yearList: [],

      selectedAgency: '',
      selectedCurrency: 'BTC',
      selectedYear: '2021',
      selectedMonth: '1',

      //data
      months: ['1','2','3','4','5','6','7','8','9','10','11','12',],
      items: [],
      calendar: null,

    }
  },
  created() {
    this.selectedCurrency = this.$route.params.currency
    this.selectedYear = this.$route.params.year + ''
    this.selectedMonth = this.$route.params.month + ''

    this.getFilterData(() => {
      this.getData()
    })
  },
  mounted() {
    this.initCalendar()
  },
  methods: {
    getFilterData(callback) {
      let startYear = 2021
      for (let i = startYear; i <= (new Date()).getFullYear(); i++) {
        this.yearList.push(i)
      }

      this.$get(this.$PROFIT_FILTER, this.$options.name + '_getFilterData', (result) => {
        this.agencyList = result.data.agency
        this.currencyList = result.data.currency

        callback()
      }, (result) => {
        this.httpError(result)
      })
    },
    initCalendar() {
      let Calendar = window.FullCalendar.Calendar
      let calendarEl = document.getElementById('calendar')
      this.calendar = new Calendar(calendarEl, {
        headerToolbar: {
          start: 'title',
          center: '',
          end: '',
        },
        themeSystem: 'bootstrap',
        eventContent: (arg) => {
          let event = arg.event
          return {
            html: event.title
          }
        },
        height: 800,
        editable  : false,
        droppable : false, // this allows things to be dropped onto the calendar !!!
        datesSet: () => {
          this.calendar.removeAllEventSources()
        },
        initialDate: new Date(Number(this.selectedYear), Number(this.selectedMonth) - 1, 1)
      })

      this.calendar.render()
    },
    renderData() {
      if(this.calendar) {
        this.calendar.destroy()
      }

      this.initCalendar()

      let data = []
      for (const [key, value] of Object.entries(this.items)) {
        data.push({
          title          : '<span class="float-left">매수</span><strong class="float-right">' + value.buy + '</strong>',
          start          : new Date(Number(this.selectedYear), Number(this.selectedMonth) - 1, Number(key)),
          allDay         : true,
          backgroundColor: '#00000000',
          borderColor    : '#00000000'
        })
        data.push({
          title          : '<span class="float-left">매도</span><strong class="float-right">' + value.sell + '</strong>',
          start          : new Date(Number(this.selectedYear), Number(this.selectedMonth) - 1, Number(key)),
          allDay         : true,
          backgroundColor: '#00000000',
          borderColor    : '#00000000'
        })
        data.push({
          title          : '<span class="float-left">출금</span><strong class="float-right">' + value.withdraw + '</strong>',
          start          : new Date(Number(this.selectedYear), Number(this.selectedMonth) - 1, Number(key)),
          allDay         : true,
          backgroundColor: '#00000000',
          borderColor    : '#00000000'
        })
      }
      this.calendar.addEventSource(data)
    },
    getData() {
      let formData = new FormData()
      formData.append('recruitingCode', this.selectedAgency)
      formData.append('ticker', this.selectedCurrency)
      formData.append('year', this.selectedYear)
      formData.append('month', this.selectedMonth)

      this.$post(this.$PROFIT_MONTH, this.$options.name + '_getList', formData, (result) => {
        this.items = result.data

        this.renderData()
      }, (result) => {
        this.httpError(result)
      })
    },
    goDetailPage(_year, _month) {
      this.$router.push({name:'MarketSettleCalendar', params:{year: _year, month: _month}})
    },
  }
}
</script>

<style>
</style>

<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">도구</h4>
            <span> 사이트를 관리하기 위한 각종 도구를 제공합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->
        <div class="row mb-2">
          <div class="col-sm-12">
            <div class="row">
              <select class="form-control w-auto mr-1" v-model="size">
                <option value="10">10줄</option>
                <option value="20">20줄</option>
                <option value="50">50줄</option>
                <option value="100">100줄</option>
              </select>
              <button class="btn btn-primary" @click="getList(page)">적용</button>
              <div class="ml-auto">
                <button class="btn btn-outline-info mr-1" @click="$router.back()">게시판 관리</button>
                <button class="btn btn-success" @click="goRegist()">{{ data.boardType.boardTitle }} 등록</button>
              </div>
            </div>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">{{ data.boardType.boardTitle }}</h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <table class="table table-bordered">
                  <thead>
                  <tr>
                    <th>ID</th>
                    <th>아이디</th>
                    <th>제목</th>
                    <th>작성일자</th>
                    <th>조회수</th>
                    <th>액션</th>
                  </tr>
                  </thead>
                  <tbody v-if="items.length > 0">
                    <tr v-for="(item, index) in items" :key="index">
                      <td>{{ item.idx }}</td>
                      <td>{{ item.email }}</td>
                      <td>{{ JSON.parse(item.content).title }}</td>
                      <td>{{ item.createdAt | moment('YYYY-MM-DD HH:mm:ss') }}</td>
                      <td>{{ item.views }}</td>
                      <td class="text-center">
                        <span class="badge btn btn-primary mr-1" @click="goEdit(item.idx)">수정</span>
                        <span class="badge btn btn-outline-danger" @click="deleteData(item.idx)">삭제</span>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="6" style="text-align: center;">관련된 내역이 없습니다.</td>
                    </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix">
                <pagination ref="Pagination"></pagination>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>

import Pagination from "../../common/Pagination"

export default {
  name: 'BoardContentList',
  components: {
    Pagination,
  },
  data() {
    return {
      boardIdx: 0,

      size: '10',
      page: 0,
      total: 0,

      data: {
        boardType: {
          boardTitle: '',
          boardCode: '',
          boardLanguage: 'KO-KR'
        },
        title: {
          type: 'text',
          length: 255,
          require: true
        },
        contents: {
          type: 'text',
          length: 1024,
          require: true
        },
      },

      //data
      items: []
    }
  },
  created() {
    this.boardIdx = this.$route.params.boardIdx

    let page = this.$route.query.page
    if(typeof(page) === 'undefined') {
      page = 1
    }

    let size = this.$route.query.size
    if(typeof(size) !== 'undefined') {
      this.size = size
    }

    this.getData()
    this.getList(page)
  },
  methods: {
    getData() {
      this.$get(this.$TOOLS_BOARD_DETAIL.replace('?', this.boardIdx), this.$options.name + '_getData', (result) => {
        this.getFormatData(this.data, result)
      }, (result) => {
        this.httpError(result)
      })
    },
    getList(page) {
      let params = new URLSearchParams()
      if(typeof page === 'undefined') page = 1
      params.append('page', page)
      params.append('limit', this.size)

      this.$get(this.$TOOLS_BOARD_CONTENTS.replace('?', this.boardIdx) + '?' + params.toString(), this.$options.name + '_getList', (result) => {
        this.page = page
        this.total = result.total

        try {
          this.$refs.Pagination.init(this.$options.name, {
          }, Number(this.page), this.total, Number(this.size))
        } catch (e) {
          console.log(e)
        }

        this.items = result.data
      }, (result) => {
        this.httpError(result)
      })
    },
    goEdit(_item) {
      this.$router.push({name: 'BoardContentEdit', params: {boardIdx: this.boardIdx, contentIdx:_item}})
    },
    goRegist() {
      this.$router.push({name: 'BoardContentRegist', params: {boardIdx: this.boardIdx}})
    },
    deleteData(_item) {
      this.$delete(this.$TOOLS_BOARD_CONTENTS_DETAIL.replace('?', this.boardIdx).replace('?', _item), this.$options.name + '_deleteData', (result) => {
        this.notify('success', result.message, false)

        this.getList(this.page)
      }, (result) => {
        this.httpError(result)
      })
    },
  },
  watch: {
    '$route.query.page'() {
      this.getList(this.$route.query.page)
    },
  }
}
</script>

<style>
</style>

<template>
  <div class="m-3">
    <div class="card-header border-bottom-0">
      <h5 class="card-title">로그인 내역</h5>
      <button class="badge btn btn-primary ml-2" @click="goMore()">더보기</button>
    </div><!-- /.card-header -->
    <div class="row align-items-center mb-2">
      <table class="table table-bordered">
        <thead>
        <tr>
          <th>관리번호</th>
          <th>IP</th>
          <th>Browser</th>
          <th>날짜</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>{{ item.idx }}</td>
          <td>{{ item.address }}</td>
          <td>{{ item.browser }}</td>
          <td>{{ item.createdAt | moment('YYYY-MM-DD HH:mm:ss') }}</td>
        </tr>
        <tr v-if="items.length === 0">
          <td colspan="4" style="text-align: center;">관련된 내역이 없습니다.</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserDetailLoginHistory",
  props: [
    'userIdx',
  ],
  data() {
    return {
      items: [],
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      let params = new URLSearchParams()
      params.append('page', "1")
      params.append('limit', "10")
      params.append('criteria', "user_idx")
      params.append('keyword', this.userIdx)

      this.$get(this.$USER_HISTORY_LOGIN + '?' + params.toString(), this.$options.name + '_getList', (result) => {
        this.items = result.data
      }, (result) => {
        this.httpError(result)
      })
    },
    goMore() {
      this.$router.push({
        name: 'UserLoginHistory',
        query: {
          st: 'user_idx',
          sk: this.userIdx
        }
      })
    },
  }
}
</script>

<style scoped>

</style>

<template>
  <div class="modal fade" id="modal-MarketSettleWithdrawPopup">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">출금</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <span>출금 받을 주소와 수량을 입력하세요.</span>
          <div class="row align-items-center mt-3">
            <div class="col-sm-3 text-right">
              <strong>주소</strong>
            </div>
            <div class="col-sm-9">
              <input type="text" class="form-control" v-model="addressTo"/>
            </div>
          </div>
          <div class="row align-items-center mt-2">
            <div class="col-sm-3 text-right">
              <strong>수량</strong>
            </div>
            <div class="col-sm-9">
              <input type="text" class="form-control" v-model="amount" disabled/>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-primary ml-auto" @click="goWithdraw()">출금</button>
          <button type="button" class="btn btn-outline-info ml-1" data-dismiss="modal" aria-label="Close">닫기</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
  <!-- /.modal -->
</template>

<script>
export default {
  name: 'MarketSettleWithdrawPopup',
  props: [
    'amount',
    'ticker',
    'year',
    'month',
    'recruitingCode'
  ],
  data() {
    return {
      addressTo: '',
    }
  },
  mounted() {
    console.log('selected : ' + this.user)
  },
  methods: {
    goWithdraw() {
      let formData = new FormData()
      formData.append('ticker', this.ticker)
      formData.append('year', this.year)
      formData.append('month', this.month)
      formData.append('addressTo', this.addressTo)
      formData.append('recruitingCode', this.recruitingCode)

      this.$post(this.$PROFIT, this.$options.name + '_goWithdraw', formData, (result) => {
        this.notify('success', result.message, false)
      }, (result) => {
        this.httpError(result)
      })
      this.$emit('close')
    }
  }
}
</script>

<style>
</style>

<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">클레임내역</h4>
            <span>데이터베이스에 기록된 클레임내역을 확인합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">클레임내역</h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>클레임번호</th>
                      <th>거래번호</th>
                      <th>글 작성자</th>
                      <th>클레임 신청자</th>
                      <th>클레임 발생일</th>
                      <th>클레임 해결 여부</th>
                      <th>클레임 해결일</th>
                      <th>클레임 상세</th>
                      <th>거래내역</th>
                    </tr>
                  </thead>
                  <tbody v-if="claimList.length > 0">
                    <tr v-for="(claim, index) in claimList" :key="index">
                      <td>{{ claim.claimIdx }}</td>
                      <td>{{ claim.tradeIdx }}</td>
                      <td>{{ claim.writerEmail }}</td>
                      <td>{{ claim.claimUserEmail }}</td>
                      <td>{{ claim.createdAt | moment('YYYY-MM-DD HH:mm:ss') }}</td>
                      <td>{{ claim.isResolved === true ? '해결됨' : '처리중' }}</td>
                      <td v-if="claim.isResolved === true">{{ claim.resolvedDate | moment('YYYY-MM-DD HH:mm:ss') }}</td>
                      <td v-else>{{ `-` }}</td>
                      <td class="text-center">
                        <span class="badge btn btn-outline-warning" @click="goClaimDetail(claim.claimIdx)">상세보기</span>
                      </td>
                      <td class="text-center">
                        <span class="badge btn btn-outline-warning" @click="goTradeDetailPopup(claim.tradeIdx)">상세보기</span>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <td colspan="9" class="text-center">클레임 내역이 없습니다.</td>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix">
                <pagination ref="Pagination"></pagination>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
    <otcDetailTradePopup ref="OtcDetailTradePopup" :trade-list="tradeList"></otcDetailTradePopup>
  </div>
</template>

<script>
import Pagination from "../common/Pagination"
import OtcDetailTradePopup from "./otcHistorySub/OtcDetailTradePopup";

export default {
  name: "OtcClaimList",
  components: {
    Pagination,
    OtcDetailTradePopup,
  },
  data() {
    return {
      size: '10',
      page: 0,
      total: 0,
      claimList: [],
      tradeList: [],
    }
  },
  created() {
    this.$root.$emit(this.$EVENT_LNB_CHANGE, 'otcSuggestion', this.$options.name)

    let page = this.$route.query.page
    if(typeof(page) === 'undefined') {
      page = 1
    }

    let size = this.$route.query.size
    if(typeof(size) !== 'undefined') {
      this.size = size
    }

    this.getList(page)

  },
  methods: {
    getList(page) {
      let formData = new FormData()
      if(typeof page === 'undefined') page = 1
      formData.append('page', page)
      formData.append('limit', this.size)

      this.$post(this.$OTC_CLAIM_LIST, this.$options.name + '_getList', formData,(result) => {
        this.page = page
        this.total = result.total

        try {
          this.$refs.Pagination.init(this.$options.name, {}, Number(this.page), this.total, Number(this.size))
        } catch (e) {
          console.log(e)
        }

        this.claimList = result.data
        this.tradeList = result.data
      }, (result) => {
        this.httpError(result)
      })
    },
    goClaimDetail(idx) {
      this.$router.push({ name: 'OtcClaimDetail', params: { idx: idx }})
    },
    goTradeDetailPopup(idx) {
      this.$refs.OtcDetailTradePopup.init(idx)
    },
    setAction(idx, isForceTrade) {
      let formData = new FormData()
      formData.append('claimIdx', idx)
      formData.append('isForceTrade', isForceTrade)

      this.$post(this.$OTC_CLAIM_ACTION, this.$options.name + '_setAction', formData,() => {
        this.notify('success', '클레임을 처리했습니다.', false)
        this.getList(this.page)
        }, () => {
        this.notify('error', '클레임을 처리하지 못 했습니다.', false)
      })
    },
  },
  watch: {
    '$route.query.page'() {
      this.getList(this.$route.query.page)
    },
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="m-3">
    <div class="card-header border-bottom-0">
      <h5 class="card-title">비밀번호 재발급</h5>
    </div><!-- /.card-header -->
    <div class="row align-items-center mb-2">
      <div class="col-sm-3 text-right">
        <strong>새 비밀번호</strong>
      </div>
      <div class="col-sm-6">
        <input type="password" class="form-control" v-model="formData.password" />
      </div>
    </div>
    <div class="row align-items-center mb-2">
      <div class="col-sm-3 text-right">
        <strong>새 비밀번호 확인</strong>
      </div>
      <div class="col-sm-6">
        <input type="password" class="form-control" v-model="formData.passwordConfirm" />
      </div>
    </div>
    <div class="row align-items-center mb-2">
      <div class="col-sm-3 text-right">
      </div>
      <div class="col-sm-6">
        <button type="button" class="btn btn-primary" @click="setData()">비밀번호 재발급</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserDetailPassword",
  props: [
      'userIdx',
  ],
  data() {
    return {
      formData: {
        password: '',
        passwordConfirm : '',
      }
    }
  },
  methods: {
    setData() {
      let formData = new FormData()
      Object.entries(this.formData).forEach((entry) => {
        let [key, value] = entry
        formData.append(key, (value+'').trim())
      })

      this.$put(this.$USER_DETAIL_PASSWORD.replace('?', this.userIdx), this.$options.name + '_setData', formData, (result) => {
        this.formData.password = ''
        this.formData.passwordConfirm = ''

        this.notify('success', result.message, false)
      }, (result) => {
        this.httpError(result)
      })
    },
  },
}
</script>

<style scoped>

</style>

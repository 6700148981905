<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-lg-12">
            <h4 class="m-0">도구</h4>
            <span> 사이트를 관리하기 위한 각종 도구를 제공합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12">

            <div class="card"> <!-- 인증 레벨별 한도 설정 -->
              <div class="card-header">
                <h5 class="card-title">인증 레벨별 한도 설정(무제한 : 0)</h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <table class="table table-bordered">
                  <thead>
                  <tr>
                    <th>인증 레벨</th>
                    <th>구매한도 -USD($)</th>
                    <th>판매한도 -USD($)</th>
                    <th>1회 최대 출금한도 -USD($)</th>
                    <th>1일 최대 출금한도 -USD($)</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, index) in securelevelData" :key="index">
                    <td>
                      <input type="text" class="form-control" v-model="item.level" disabled/>
                    </td>
                    <td>
                      <div class="row align-items-center">
                        <input type="text" class="form-control" v-model="item.maxBuy"/>
                      </div>
                    </td>
                    <td>
                      <div class="row align-items-center">
                        <input type="text" class="form-control" v-model="item.maxSell"/>
                      </div>
                    </td>
                    <td>
                      <div class="row align-items-center">
                        <input type="text" class="form-control" v-model="item.maxOnceWithdraw"/>
                      </div>
                    </td>
                    <td>
                      <div class="row align-items-center">
                        <input type="text" class="form-control" v-model="item.maxDayWithdraw"/>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix">
                <button class="btn btn-primary float-right" @click="setSecureLevel()">저장</button>
              </div>
            </div><!-- /.card -->

            <div class="card">
              <div class="card-header">
                <h5 class="card-title">회원가입시 기본 값설정</h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <table class="table table-bordered">
                  <thead>
                  <tr>
                    <th>출금 기능</th>
                    <th>거래 기능</th>
                    <th>로그인 기능</th>
                    <th>전체 기능</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td class="align-middle">
                      <div class="d-flex flex-row justify-content-center">
                        <div class="d-flex flex-row justify-content-center align-items-center align-content-center">
                          <input type="radio" id="stateWithdraw-accept" value="1" v-model="defaultData.stateWithdraw">
                          <label for="stateWithdraw-accept" class="ml-1">허용</label>
                        </div>
                        <div class="d-flex flex-row justify-content-center align-items-center align-content-center">
                          <input type="radio" id="stateWithdraw-limit" value="0" v-model="defaultData.stateWithdraw" class="ml-4">
                          <label for="stateWithdraw-limit" class="ml-1">제한</label>
                        </div>
                      </div>
                    </td>
                    <td class="align-middle">
                      <div class="d-flex flex-row justify-content-center">
                        <div class="d-flex flex-row justify-content-center align-items-center align-content-center">
                          <input type="radio" id="stateTrading-accept" value="1" v-model="defaultData.stateTrading">
                          <label for="stateTrading-accept" class="ml-1">허용</label>
                        </div>
                        <div class="d-flex flex-row justify-content-center align-items-center align-content-center">
                          <input type="radio" id="stateTrading-limit" value="0" v-model="defaultData.stateTrading" class="ml-4">
                          <label for="stateTrading-limit" class="ml-1">제한</label>
                        </div>
                      </div>
                    </td>
                    <td class="align-middle">
                      <div class="d-flex flex-row justify-content-center">
                        <div class="d-flex flex-row justify-content-center align-items-center align-content-center">
                          <input type="radio" id="stateLogin-accept" value="1" v-model="defaultData.stateLogin">
                          <label for="stateLogin-accept" class="ml-1">허용</label>
                        </div>
                        <div class="d-flex flex-row justify-content-center align-items-center align-content-center">
                          <input type="radio" id="stateLogin-limit" value="0" v-model="defaultData.stateLogin" class="ml-4">
                          <label for="stateLogin-limit" class="ml-1">제한</label>
                        </div>
                      </div>
                    </td>
                    <td class="align-middle">
                      <div class="d-flex flex-row justify-content-center">
                        <div class="d-flex flex-row justify-content-center align-items-center align-content-center">
                          <input type="radio" id="stateAvailable-accept" value="1" v-model="defaultData.stateAvailable">
                          <label for="stateAvailable-accept" class="ml-1">허용</label>
                        </div>
                        <div class="d-flex flex-row justify-content-center align-items-center align-content-center">
                          <input type="radio" id="stateAvailable-limit" value="0" v-model="defaultData.stateAvailable" class="ml-4">
                          <label for="stateAvailable-limit" class="ml-1">제한</label>
                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix">
                <button class="btn btn-primary float-right" @click="setDefault()">저장</button>
              </div>
            </div><!-- /.card -->
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">기준 통화 설정</h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <table class="table table-bordered">
                  <tbody>
                  <tr>
                    <td class="align-middle text-center">
                      <span>기준 통화</span>
                    </td>
                    <td>
                      <input type="text" class="form-control" v-model="tickerData"/>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix">
                <button class="btn btn-primary float-right" @click="setTicker()">저장</button>
              </div>
            </div><!-- /.card -->
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">SNS 아이디 설정</h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <table class="table table-bordered">
                  <tbody>
                    <tr>
                      <td class="align-middle text-center">
                        <span>텔레그램 - 영어</span>
                      </td>
                      <td>
                        <input type="text" class="form-control" v-model="SNSData.telegramEn"/>
                      </td>
                    </tr>
                    <tr>
                      <td class="align-middle text-center">
                        <span>텔레그램 - 중국어</span>
                      </td>
                      <td>
                        <input type="text" class="form-control" v-model="SNSData.telegramCn"/>
                      </td>
                    </tr>
                    <tr>
                      <td class="align-middle text-center">
                        <span>페이스북</span>
                      </td>
                      <td>
                        <input type="text" class="form-control" v-model="SNSData.facebook"/>
                      </td>
                    </tr>
                    <tr>
                      <td class="align-middle text-center">
                        <span>트위터</span>
                      </td>
                      <td>
                        <input type="text" class="form-control" v-model="SNSData.twitter"/>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix">
                <button class="btn btn-primary float-right" @click="setSNSId()">저장</button>
              </div>
            </div><!-- /.card -->

          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'SiteSetting',
  data() {
    return {
      bnwgData: {
        bnwg: '0',
      },
      defaultData: {
        stateWithdraw: '0',
        stateTrading: '0',
        stateLogin: '0',
        stateAvailable: '0',
      },
      purchaseData: {
        email: '',
      },
      securelevelData: [],
      tickerData: '',
      footerData: {},
      SNSData: {
        telegramEn: '',
        telegramCn: '',
        facebook: '',
        twitter: '',
      },
    }
  },
  created() {
    this.$root.$emit(this.$EVENT_LNB_CHANGE, 'setting', this.$options.name)
    this.getFilterData()
    this.getSNSId()
  },
  methods: {
    getFilterData() {
      this.$get(this.$SETTING_FILTER, this.$options.name + '_getFilterData', (result) => {
        for (const [key, value] of Object.entries(result.data)) {
          if(Array.isArray(value)) {
            this[key + 'Data'] = value
          } else if(typeof value === 'string') {
            this[key + 'Data'] = value
          } else {
            this.getFormatData(this[key + 'Data'], {data: value})
          }
        }
      }, (result) => {
        this.httpError(result)
      })
    },
    setSecureLevel() {
      this.securelevelData.map(el => {
        if(!!el.maxBuy === false) el.maxBuy = '0'
        if(!!el.maxSell === false) el.maxSell = '0'
        if(!!el.maxOnceWithdraw === false) el.maxOnceWithdraw = '0'
        if(!!el.maxOnceWithdraw === false) el.maxDayWithdraw = '0'
      })

      let json = JSON.stringify({list: this.securelevelData})

      this.$post(this.$SETTING_METHOD.replace('?', 'securelevel'), this.$options.name + '_setSecureLevel', json, () => {
        this.notify('success', '변경에 성공했습니다.', false)
      }, (result) => {
        this.httpError(result)
      })
    },
    setDefault() {
      let formData = new FormData()
      Object.entries(this.defaultData).forEach((entry) => {
        let [key, value] = entry
        formData.append(key, (value+'').trim())
      })

      this.$post(this.$SETTING_METHOD.replace('?', 'default'), this.$options.name + '_setData', formData, () => {
        if(this.defaultData.stateAvailable === '1' && this.defaultData.stateWithdraw === '0' && this.defaultData.stateTrading === '0' && this.defaultData.stateLogin === '0') {
          this.notify('error', '전체 기능이 제한된 상태입니다. 다시 확인해주세요.', false)
          this.getFilterData()
          return
        }
        this.notify('success', '변경에 성공했습니다.', false)
        this.getFilterData()
      }, (result) => {
        this.httpError(result)
      })
    },
    setTicker() {
      let formData = new FormData()
      formData.append('ticker', this.tickerData.trim())

      this.$post(this.$SETTING_METHOD.replace('?', 'ticker'), this.$options.name + '_setData', formData, () => {
        this.notify('success', '변경에 성공했습니다.', false)
      }, (result) => {
        this.httpError(result)
      })
    },
    setBnwg() {
      let formData = new FormData()
      Object.entries(this.bnwgData).forEach((entry) => {
        let [key, value] = entry
        formData.append(key, (value+'').trim())
      })

      this.$post(this.$SETTING_METHOD.replace('?', 'bnwg'), this.$options.name + '_setData', formData, () => {
        this.notify('success', '변경에 성공했습니다.', false)
      }, (result) => {
        this.httpError(result)
      })
    },
    setPurchase() {
      let formData = new FormData()
      Object.entries(this.purchaseData).forEach((entry) => {
        let [key, value] = entry
        formData.append(key, (value+'').trim())
      })

      this.$post(this.$SETTING_METHOD.replace('?', 'purchase'), this.$options.name + '_setData', formData, () => {
        this.notify('success', '변경에 성공했습니다.', false)
      }, (result) => {
        this.httpError(result)
      })
    },
    getSNSId() {
      this.$get(this.$SETTING_SNSID, this.$options.name + '_getSNSId', (result) => {
        this.SNSData = result.data
      }, (result) => {
        this.httpError(result)
      })
    },
    setSNSId() {
      let formData = new FormData()
      Object.entries(this.SNSData).forEach((entry) => {
        let [key, value] = entry
        formData.append(key, (value+'').trim())
      })

      this.$post(this.$SETTING_SNSID, this.$options.name + '_setSNSId', formData, () => {
        this.notify('success', '변경에 성공했습니다.', false)
        this.getSNSId()
      }, (result) => {
        this.httpError(result)
      })
    },
  }
}
</script>

<style>
label {
  margin-bottom: 0px;
}
</style>

<template>
  <nav class="main-header navbar navbar-expand navbar-dark">
    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">
      <li class="nav-item d-none d-sm-inline-block">
        <router-link :to="{ name: 'Login' }" class="nav-link text-orange">
          <p><i class="fas fa-sign-out-alt"></i> 로그아웃</p>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  data() {
    return {

    }
  },
}
</script>

<style>
</style>

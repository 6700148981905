<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">도구</h4>
            <span> 사이트를 관리하기 위한 각종 도구를 제공합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-4">
                    <div class="m-3">
                      <div class="card-header border-bottom-0">
                        <h5 class="card-title mr-3 mt-2">총 방문자</h5>
                      </div><!-- /.card-header -->
                      <div class="row ml-3">
                        <span>{{ totalVisit }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="m-3">
                      <div class="card-header border-bottom-0">
                        <h5 class="card-title mr-3 mt-2">오늘 방문자</h5>
                      </div><!-- /.card-header -->
                      <div class="row ml-3">
                        <span>{{ todayVisit }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="m-3">
                      <div class="card-header border-bottom-0">
                        <h5 class="card-title mr-3 mt-2">브라우저 통계</h5>
                      </div><!-- /.card-header -->
                      <div class="row align-items-center">
                        <table class="table table-bordered">
                          <thead>
                          <tr>
                            <th>Browser</th>
                            <th>횟수</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(item, index) in browserList" :key="index">
                            <td>{{ index }}</td>
                            <td>{{ item }}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="m-3">
                      <div class="card-header border-bottom-0">
                        <h5 class="card-title mr-3 mt-2">사이트 통계</h5>
                        <select class="form-control w-auto" v-model="size">
                          <option value="10">10줄</option>
                          <option value="20">20줄</option>
                          <option value="50">50줄</option>
                          <option value="100">100줄</option>
                        </select>
                      </div><!-- /.card-header -->
                      <div class="row align-items-center">
                        <table class="table table-bordered">
                          <thead>
                          <tr>
                            <th>관리번호</th>
                            <th>아이디</th>
                            <th>Browser</th>
                            <th>횟수</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(item, index) in items" :key="index">
                            <td>{{ item.idx }}</td>
                            <td>{{ item.email }}</td>
                            <td>{{ item.browser }}</td>
                            <td>{{ item.count }}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <pagination ref="Pagination"></pagination>
                </div>
              </div><!-- ./card-body -->
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>

import Pagination from "../common/Pagination"

export default {
  name: 'SiteStatistics',
  components: {
    Pagination,
  },
  data() {
    return {
      browserList: [],
      totalVisit: 0,
      todayVisit: 0,

      size: '10',
      page: 0,
      total: 0,

      items: []
    }
  },
  created() {
    this.$root.$emit(this.$EVENT_LNB_CHANGE, 'setting', this.$options.name)

    let page = this.$route.query.page
    if(typeof(page) === 'undefined') {
      page = 1
    }

    let size = this.$route.query.size
    if(typeof(size) !== 'undefined') {
      this.size = size
    }

    this.getFilterData(() => {
      this.getList(page)
    })
  },
  methods: {
    getFilterData(callback) {
      let params = new URLSearchParams()
      params.append('value', '0')
      params.append('dateUnit', 'all')

      this.$get(this.$STATS_FILTER + '?' + params.toString(), this.$options.name + '_getFilterData', (result) => {
        this.browserList = result.data.browser
        this.todayVisit = result.data.today
        this.totalVisit = result.data.total

        callback()
      }, (result) => {
        this.httpError(result)
      })
    },
    getList(page) {
      let params = new URLSearchParams()
      if(typeof page === 'undefined') page = 1
      params.append('page', page)
      params.append('limit', this.size)

      this.$get(this.$STATS_SITE + '?' + params.toString(), this.$options.name + '_getList', (result) => {
        this.page = page
        this.total = result.total

        try {
          this.$refs.Pagination.init(this.$options.name, {
          }, Number(this.page), this.total, Number(this.size))
        } catch (e) {
          console.log(e)
        }

        this.items = result.data
      }, (result) => {
        this.httpError(result)
      })
    },
  },
  watch: {
    '$route.query.page'() {
      this.getList(this.$route.query.page)
    },
    'size'() {
      let page = this.$route.query.page
      if(typeof(page) === 'undefined') {
        page = 1
      }
      this.getFilterData(() => {
        this.getList(page)
      })
    },
  }
}
</script>

<style>
</style>

<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">거래내역</h4>
            <span> 데이터베이스에 기록된 거래내역을 확인합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->
        <div class="row mb-2">
          <div class="col-sm-12">
            <h5 class="mb-2">필터</h5>
            <div class="row">
              <select class="form-control w-auto mr-1" v-model="selectedPrimary">
                <option value="">전체</option>
                <option v-for="(primary, index) in primaryList" :key="index" :value="primary">{{ primary }}</option>
              </select>
              <select class="form-control w-auto mr-1" v-model="selectedSecondary">
                <option value="">--</option>
                <option v-for="(secondary, index) in secondaryList" :key="index" :value="secondary">{{ secondary }}</option>
              </select>
              <select class="form-control w-auto mr-1" v-model="selectedIsBuy">
                <option value="">매도/매수</option>
                <option value="0">매도</option>
                <option value="1">매수</option>
              </select>
              <select class="form-control w-auto mr-1" v-model="selectedSearchType">
                <option value="buyer">구매자</option>
                <option value="seller">판매자</option>
                <option value="user_idx">회원번호</option>
              </select>
              <input
                :type="selectedSearchType === 'user_idx'
                ? 'number'
                : 'text'"
                class="form-control w-auto mr-1"
                placeholder="검색어"
                v-model="searchKeyword">
              <select class="form-control w-auto mr-1" v-model="size">
                <option value="10">10줄</option>
                <option value="20">20줄</option>
                <option value="50">50줄</option>
                <option value="100">100줄</option>
              </select>
              <input type="date" class="form-control w-auto" v-model="selectedStart"/>
              <span class="m-1">~</span>
              <input type="date" class="form-control w-auto mr-1" v-model="selectedEnd" :min="selectedMin" :max="selectedMax"/>
              <button class="btn btn-primary" @click="getList(page)">검색</button>
            </div>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">거래 - 히스토리</h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <table class="table table-bordered">
                  <thead>
                  <tr>
                    <th>거래번호</th>
                    <th>날짜</th>
                    <th>마켓</th>
                    <th>거래</th>
                    <th>구매자</th>
                    <th>판매자</th>
                    <th>신청가격</th>
                    <th>체결수량</th>
                    <th>수수료</th>
                  </tr>
                  </thead>
                  <tbody v-if="items.length > 0">
                    <tr v-for="(item, index) in items" :key="index">
                      <td v-if="item.idx !== null">{{ item.idx }}</td>
                      <td v-else>-</td>
                      <td>{{ item.createdAt | moment('YYYY-MM-DD HH:mm:ss') }}</td>
                      <td>{{ item.primaryTicker }} ⇄ {{ item.secondaryTicker }}</td>
                      <td>{{ item.buy ? '매수':'매도' }}</td>
                      <td>{{ item.oppositeEmail }}</td>
                      <td>{{ item.userEmail }}</td>
                      <td>{{ item.price }} {{ item.secondaryTicker }}</td>
                      <td>{{ item.amount }} {{ item.primaryTicker }}</td>
                      <td>{{ item.feeRate * 100 }}%({{ item.feeAmount }} {{ item.feeTicker }})</td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="9" style="text-align: center;">관련된 내역이 없습니다.</td>
                    </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix">
                <pagination ref="Pagination"></pagination>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>
import Pagination from "../common/Pagination"
import Vue from 'vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
Vue.use(Loading)
import Loader from '@/components/common/Loader'

export default {
  name: 'MarketConcludeHistory',
  components: {
    Pagination,
  },
  data() {
    return {
      //search filter
      marketPairList: [],
      currencyList: [],

      primaryList: [],
      secondaryList: [],

      selectedIsBuy: '',
      selectedPrimary: '',
      selectedSecondary: '',
      selectedSearchType: 'buyer',
      searchKeyword: '',
      selectedStart: '',
      selectedTmpStart: '',
      selectedEnd: '',
      selectedTmpEnd: '',
      selectedMin: '',
      selectedMax: '',
      size: '10',
      page: 0,
      total: 0,

      //data
      items: [],

      loader: null,
    }
  },
  created() {
    this.$root.$emit(this.$EVENT_LNB_CHANGE, 'marketHistory', this.$options.name)
    this.getTodayDates()

    let page = this.$route.query.page
    if(typeof(page) === 'undefined') {
      page = 1
    }

    let size = this.$route.query.size
    if(typeof(size) !== 'undefined') {
      this.size = size
    }

    let sb = this.$route.query.sb
    if(typeof(sb) !== 'undefined') {
      this.selectedIsBuy = sb
    }

    let pc = this.$route.query.pc
    if(typeof(pc) !== 'undefined') {
      this.selectedPrimary = pc
    }

    let sc = this.$route.query.sc
    if(typeof(sc) !== 'undefined') {
      this.selectedSecondary = sc
    }

    let st = this.$route.query.st
    if(typeof(st) !== 'undefined') {
      this.selectedSearchType = st
    }

    let sk = this.$route.query.sk
    if(typeof(sk) !== 'undefined') {
      this.searchKeyword = sk
    }

    let start = this.$route.query.start
    if(typeof(start) !== 'undefined') {
      this.selectedStart = start
    }

    let end = this.$route.query.end
    if(typeof(end) !== 'undefined') {
      this.selectedEnd = end
    }

    this.getFilterData(() => {
      this.getList(page)
    })
  },

  beforeDestroy() {
    this.loader.hide()
  },

  methods: {
    getFilterData(callback) {
      this.$get(this.$MARKET_FILTER, this.$options.name + '_getFilterData', (result) => {
        this.marketPairList = result.data.marketPair
        this.currencyList = result.data.currency

        this.primaryList = []
        for(let i=0; i<this.marketPairList.length; i++) {
          if(this.primaryList.lastIndexOf(this.marketPairList[i].primary) !== -1) {
            continue
          }
          this.primaryList.push(this.marketPairList[i].primary)
        }

        callback()
      }, (result) => {
        this.httpError(result)
      })
    },
    getList(page) {
      this.loader = this.$loading.show({
        backgroundColor: '#65686F',
        opacity: 0.5,
        zIndex: 999,
      }, {
        default: this.$createElement(Loader)
      })

      let params = new URLSearchParams()
      if(typeof page === 'undefined') page = 1
      params.append('page', page)
      params.append('limit', this.size)
      params.append('isBuy', this.selectedIsBuy)
      params.append('primary', this.selectedPrimary)
      params.append('secondary', this.selectedSecondary)
      if(this.searchKeyword !== '') {
        params.append('criteria', this.selectedSearchType)
        params.append('keyword', this.searchKeyword)
      }
      params.append('start', this.selectedTmpStart)
      params.append('end', this.selectedTmpEnd)

      this.$get(this.$MARKET_TRADE_HISTORY + '?' + params.toString(), this.$options.name + '_getList', (result) => {
        this.loader.hide()
        this.page = page
        this.total = result.total

        try {
          this.$refs.Pagination.init(this.$options.name, {
            sb: this.selectedIsBuy,
            pc: this.selectedPrimary,
            sc: this.selectedSecondary,
            st: this.selectedSearchType,
            sk: this.searchKeyword,
            start: this.selectedStart,
            end: this.selectedEnd,
          }, Number(this.page), this.total, Number(this.size))
        } catch (e) {
          console.log(e)
        }

        this.items = result.data
      }, (result) => {
        this.httpError(result)
      })
    },
    getMarketConcludedHistoryCountDates(_startDate) { // 마켓 거래 내역(매수) - 날짜 제한
      let startDate = new Date(_startDate)

      let maxDate = this.$moment(startDate).add(3, 'month').format('YYYY-MM-DD')
      let minDate = this.$moment(startDate).format('YYYY-MM-DD')

      this.selectedMax = maxDate
      this.selectedMin = minDate
    },
    getTodayDates() {
      let timestamp = new Date().getTime()
      this.selectedStart = this.$moment(timestamp).subtract(7, 'day').format('YYYY-MM-DD')
      this.getMarketConcludedHistoryCountDates(this.selectedStart)
      this.selectedEnd = this.$moment(timestamp).format('YYYY-MM-DD')
    },

  },
  watch: {
    '$route.query.page'() {
      this.getList(this.$route.query.page)
    },
    'selectedPrimary'() {
      this.selectedSecondary = ''
      this.secondaryList = []
      if(this.selectedPrimary === '') {
        return
      }
      for(let i=0; i<this.marketPairList.length; i++) {
        if(this.marketPairList[i].primary !== this.selectedPrimary) {
          continue
        }
        if(this.secondaryList.indexOf(this.marketPairList[i].secondary) === -1) {
          this.secondaryList.push(this.marketPairList[i].secondary)
        }
      }
    },
    'selectedStart'() {
      if(this.selectedStart) {
        let date = new Date(this.selectedStart)
        date.setHours(0)
        date.setMinutes(0)
        date.setSeconds(0)
        this.selectedTmpStart = +date
      } else {
        this.selectedTmpStart = ''
      }
      this.getMarketConcludedHistoryCountDates(this.selectedStart)
    },
    'selectedEnd'() {
      if(this.selectedEnd) {
        let date = new Date(this.selectedEnd)
        date.setHours(23)
        date.setMinutes(59)
        date.setSeconds(59)
        this.selectedTmpEnd = +date
      } else {
        this.selectedTmpEnd = ''
      }
    },
  },
}
</script>

<style>
</style>

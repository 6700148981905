<template>
  <div class="modal fade" id="modal-MarketDetailHistoryPopup">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">거래내역</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <table class="table table-bordered mb-0">
            <thead>
            <tr>
              <th>관리번호</th>
              <th>날짜</th>
              <th v-if="items.buy">매도자</th>
              <th v-else>매수자</th>
              <th>신청가격</th>
              <th>체결수량</th>
              <th>수수료</th>
            </tr>
            </thead>
            <tbody>
              <td>{{ items.idx }}</td>
              <td>{{ items.createdAt | moment('YYYY-MM-DD HH:mm:ss')  }}</td>
              <td>{{ items.oppositeEmail }}</td>
              <td>{{ items.price }} {{ items.secondaryTicker }}</td>
              <td>{{ items.amount}} {{ items.primaryTicker }}</td>
              <td>{{ items.feeRate * 100 }}%({{ items.feeAmount }} {{ items.secondaryTicker }})</td>
            </tbody>
          </table>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
  <!-- /.modal -->
</template>

<script>
export default {
  name: 'MarketDetailHistoryPopup',
  data() {
    return {
      idx: 0,
      items: [{
        idx: 0,
        exid: '',
        marketType: '',
        primaryTicker: '',
        secondaryTicker: '',
        userEmail: '',
        price: 0,
        amount: 0,
        oppositeEmail: '',
        code: '',
        date: '',
        priceForLimit: '',
        tickerForLimit: '',
        feeAmount: 0,
        feeRate: 0,
        createdAt: 0,
        buy: false
      }],
    }
  },
  methods: {
    init(idx) {
      this.idx = idx
      this.$get(this.$MARKET_TRADE_DETAIL.replace('?', this.idx), this.$options.name + '_init', (result) => {
        this.items = result.data
        this.openPopup()
      }, (result) => {
        this.httpError(result)
      })
    },
    openPopup() {
      window.$('#modal-MarketDetailHistoryPopup').modal('show')
    },
    closePopup() {
      window.$('#modal-MarketDetailHistoryPopup').modal('hide')
    },
  },
}
</script>

<style>
</style>

<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">입출금</h4>
            <span> 데이터베이스에 기록된 입출금 내역을 확인합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->
        <div class="row mb-2">
          <div class="col-sm-12">
            <h5 class="mb-2">필터</h5>
            <div class="row">
              <select class="form-control w-auto mr-1" v-model="selectedCurrency">
                <option value="">전체</option>
                <option v-for="(currency, index) in this.currencyList" :key="index" :value="currency.ticker">{{ currency.name }}</option>
              </select>
              <select class="form-control w-auto mr-1" v-model="selectedProcessState">
                <option value="">승인상태</option>
                <option v-for="(processState, index) in this.E_ProcessState" :key="index" :value="index">{{ processState }}</option>
              </select>
              <select class="form-control w-auto mr-1" v-model="selectedSearchType">
                <option value="email">이메일</option>
                <option value="phone">휴대폰</option>
                <option value="nickname">닉네임</option>
                <option value="walletaddress">출금 목적지 주소</option>
              </select>
              <input type="text" class="form-control w-auto mr-1" placeholder="검색어" v-model="searchKeyword">
              <select class="form-control w-auto mr-1" v-model="size">
                <option value="10">10줄</option>
                <option value="20">20줄</option>
                <option value="50">50줄</option>
                <option value="100">100줄</option>
              </select>
              <button class="btn btn-primary" @click="getList(page)">검색</button>
            </div>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">안전 출금</h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <table class="table table-bordered">
                  <thead>
                  <tr>
                    <th>관리번호</th>
                    <th>통화</th>
                    <th>신청일자</th>
                    <th>트랜잭션 넘버</th>
                    <th>타입</th>
                    <th>사용자</th>
                    <th>거래자</th>
                    <th>수량</th>
                    <th>수수료</th>
                    <th>상태</th>
                  </tr>
                  </thead>
                  <tbody v-if="items.length > 0">
                    <tr v-for="(item, index) in items" :key="index">
                      <td>{{ item.idx }}</td>
                      <td>{{ item.tickerName }}</td>
                      <td>{{ item.createdAt | moment('YYYY-MM-DD HH:mm:ss') }}</td>
                      <td>{{ item.tx }}</td>
                      <td>{{ E_TransferType[item.type] }}</td>
                      <td>{{ item.owner }}</td>
                      <td>{{ item.opposite ? item.opposite : '-' }}</td>
                      <td>{{ item.amount }}</td>
                      <td>{{ item.feeAmount }}</td>
                      <td>
                        <span v-if="E_ProcessState[item.processState] !== '대기'">{{ E_ProcessState[item.processState] }}</span>
                        <div v-else>
                          <button class="badge btn btn-success mr-1" @click="setData(item.idx, '1')">출금 승인</button>
                          <button class="badge btn btn-danger" @click="setData(item.idx, '0')">출금 거절</button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="10" style="text-align: center;">관련된 내역이 없습니다.</td>
                    </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix">
                <pagination ref="Pagination"></pagination>
                <Confirm
                  ref="Confirm"
                >
                </Confirm>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>

import Pagination from "../common/Pagination"
import Confirm from "../common/Confirm";

export default {
  name: 'WalletSafeWithdrawList',
  components: {
    Confirm,
    Pagination,
  },
  data() {
    return {
      currencyList: [],

      selectedCurrency: '',
      selectedProcessState: '',
      selectedSearchType: 'email',
      searchKeyword: '',
      size: '10',
      page: 0,
      total: 0,

      //data
      items: [],

      isShowModal : false,
      msg: '',
      isConfirm: false,
    }
  },
  created() {
    this.$root.$emit(this.$EVENT_LNB_CHANGE, 'wallet', this.$options.name)

    let page = this.$route.query.page
    if(typeof(page) === 'undefined') {
      page = 1
    }

    let size = this.$route.query.size
    if(typeof(size) !== 'undefined') {
      this.size = size
    }

    let sc = this.$route.query.sc
    if(typeof(sc) !== 'undefined') {
      this.selectedCurrency = sc
    }

    let ps = this.$route.query.ps
    if(typeof(ps) !== 'undefined') {
      this.selectedProcessState = ps
    }

    let st = this.$route.query.st
    if(typeof(st) !== 'undefined') {
      this.selectedSearchType = st
    }

    let sk = this.$route.query.sk
    if(typeof(sk) !== 'undefined') {
      this.searchKeyword = sk
    }

    this.getFilterData(() => {
      this.getList(page)
    })
  },
  methods: {
    getFilterData(callback) {
      this.$get(this.$TRANSFER_FILTER, this.$options.name + '_getFilterData', (result) => {
        this.currencyList = result.data.currency

        callback()
      }, (result) => {
        this.httpError(result)
      })
    },
    getList(page) {
      let params = new URLSearchParams()
      if(typeof page === 'undefined') page = 1
      params.append('page', page)
      params.append('limit', this.size)
      params.append('ticker', this.selectedCurrency)
      params.append('isDeposit', '0')
      params.append('processState', this.selectedProcessState)
      params.append('criteria', this.selectedSearchType)
      params.append('keyword', this.searchKeyword)

      this.$get(this.$TRANSFER_SAFE + '?' + params.toString(), this.$options.name + '_getList', (result) => {
        this.page = page
        this.total = result.total

        try {
          this.$refs.Pagination.init(this.$options.name, {
            sc: this.selectedCurrency,
            ps: this.selectedProcessState,
            st: this.selectedSearchType,
            sk: this.searchKeyword,
          }, Number(this.page), this.total, Number(this.size))
        } catch (e) {
          console.log(e)
        }

        this.items = result.data
      }, (result) => {
        this.httpError(result)
      })
    },
    setData(idx, status) {
      // this.isShowModal = true

      if(status === '1') {
        this.msg = '정말로 승인하시겠습니까?'
      } else {
        this.msg = '정말로 거절하시겠습니까?'
      }

      let formData = new FormData()
      formData.append('idx', idx)
      formData.append('accept', status)

      this.$refs.Confirm.showDialog(this.msg, () => {
        this.$post(this.$TRANSFER_SAFE, this.$options.name + '_setData', formData, () => {
          this.getList(this.page)
        }, (result) => {
          this.httpError(result)
        })
      })

      // if(status === '1') { // 출금 승인일 때
      //   this.msg = '정말로 승인하시겠습니까?'
      //   if(this.isConfirm) {
      //     let formData = new FormData()
      //     formData.append('idx', idx)
      //     formData.append('accept', status)
      //
      //     this.$post(this.$TRANSFER_SAFE, this.$options.name + '_setData', formData, () => {
      //       this.getList(this.page)
      //     }, (result) => {
      //       this.httpError(result)
      //     })
      //   }
      // } else { // 출금 거절일 때
      //   this.msg = '정말로 거절하시겠습니까?'
      //
      //   if(this.isConfirm) {
      //     let formData = new FormData()
      //     formData.append('idx', idx)
      //     formData.append('accept', status)
      //
      //     this.$post(this.$TRANSFER_SAFE, this.$options.name + '_setData', formData, () => {
      //       this.getList(this.page)
      //     }, (result) => {
      //       this.httpError(result)
      //     })
      //   }
      // }
    },
    // setConfirm(confirm) {
    //   this.isConfirm = confirm
    //   this.isShowModal = false
    // },
    // setClose() {
    //   this.isConfirm = false
    //   this.isShowModal = false
    // }
  },
  watch: {
    '$route.query.page'() {
      this.getList(this.$route.query.page)
    },
  },
}
</script>

<style>
</style>

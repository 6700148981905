<template>
  <div class="m-3">
    <div class="card-header border-bottom-0">
      <h5 class="card-title">지갑 잔액</h5>
    </div><!-- /.card-header -->
    <div class="row align-items-center mb-2">
      <table class="table table-bordered">
        <thead>
        <tr>
          <th>통화</th>
          <th>잔액</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>{{ item.ticker }} ({{ item.currency }})</td>
          <td>{{ item.balance }}</td>
        </tr>
        <tr v-if="items.length === 0">
          <td colspan="2" style="text-align: center;">관련된 내역이 없습니다.</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserDetailBalance",
  props: [
    'userIdx',
  ],
  data() {
    return {
      items: [],
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$get(this.$USER_ASSET.replace('?', this.userIdx), this.$options.name + '_getData', (result) => {
        this.items = result.data
      }, (result) => {
        this.httpError(result)
      })
    },
  }
}
</script>

<style scoped>

</style>

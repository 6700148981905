import { render, staticRenderFns } from "./UserDetailWallet.vue?vue&type=template&id=be44e042&scoped=true&"
import script from "./UserDetailWallet.vue?vue&type=script&lang=js&"
export * from "./UserDetailWallet.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be44e042",
  null
  
)

export default component.exports
<template>
  <div class="modal fade" id="modal-UserSendSmsPopup">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Send SMS</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div v-if="selectedUser.length > 0" class="row">
            <div class="col-sm-5 overflow-auto" style="max-height: 300px;">
              <div class="border-bottom d-flex border-secondary" v-for="(user, index) in selectedUser" :key="index">
                <span>{{ user.nickName }} ({{ user.phone }})</span>
                <button class="btn btn-danger badge ml-auto m-1" @click="removeUser(index)">&times;</button>
              </div>
            </div>
            <div class="col-sm-7">
              <input type="text" class="form-control mb-3" placeholder="제목" v-model="formData.title"/>
              <textarea class="form-control" rows="5" placeholder="내용" v-model="formData.content"></textarea>
            </div>
          </div>
          <div v-else class="row">
            <div class="overflow-auto text-center" style="max-height: 300px; width: 100%">선택된 회원이 없습니다. 먼저 회원을 선택해주세요.</div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="setData()">전송</button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal" aria-label="Close">닫기</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
  <!-- /.modal -->
</template>

<script>
export default {
  name: 'UserSendSmsPopup',
  data() {
    return {
      selectedUser: [],
      formData: {
        title: '',
        content: '',
      },
    }
  },
  methods: {
    init(users) {
      this.selectedUser = users
      window.$('#modal-UserSendSmsPopup').modal('show')
    },
    setData() {
      let formData = new FormData()
      Object.entries(this.formData).forEach((entry) => {
        let [key, value] = entry
        formData.append(key, (value+'').trim())
      })

      for(let i=0;i<this.selectedUser.length;i++) {
        formData.append('userIdx', this.selectedUser[i].idx)
      }

      this.$post(this.$USER_SMS, this.$options.name + '_setData', formData, (result) => {
        this.notify('success', result.message, false)

        window.$('#modal-UserSendSmsPopup').modal('hide')
      }, (result) => {
        this.httpError(result)
      })
    },
    removeUser(_index) {
      this.selectedUser.splice(_index, 1)
    },
  }
}
</script>

<style>
</style>

<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">마켓</h4>
            <span> 데이터베이스에 기록된 마켓 관련 항목을 확인합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">스팟 거래소 화면 표시 부통화 설정</h5>
        </div><!-- /.card-header -->
        <div class="card-body">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th rowspan="2">순번</th>
                <th rowspan="2">코인</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="align-middle text-center">
                  <span>1</span>
                </td>
                <td colspan="3">
                  <select class="form-control mr-1" v-model="selectedPrimary[0]">
                    <option :value="primary" v-for="(primary, index) in primaryList" :key="index">{{ primary }}</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td class="align-middle text-center">
                  <span>2</span>
                </td>
                <td colspan="3">
                  <select class="form-control mr-1" v-model="selectedPrimary[1]">
                    <option :value="primary" v-for="(primary, index) in primaryList" :key="index">{{ primary }}</option>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        </div><!-- ./card-body -->
        <div class="card-footer clearfix">
          <button class="btn btn-primary float-right" @click="setPrimary()">저장</button>
        </div>
      </div><!-- /.card -->

      <!-- OTC 직접거래 코인 설정 -->
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">OTC 직접거래 코인 설정</h5>
        </div><!-- /.card-header -->
        <div class="card-body">
          <table class="table table-bordered">
            <tbody>
            <tr>
              <td class="align-middle text-center">
                <span>코인</span>
              </td>
              <td>
                <input type="text" class="form-control" v-model="directlyTradingCoins"/>
              </td>
            </tr>
            </tbody>
          </table>
        </div><!-- ./card-body -->
        <div class="card-footer clearfix">
          <button class="btn btn-primary float-right" @click="setDirectlyTradingCoins()">저장</button>
        </div>
      </div><!-- /.card -->
    </div><!-- /.col -->
  </div>
</template>

<script>
export default {
  name: "MarketPairEtcSetting",
  data() {
    return {
      //data
      items: [],

      marketPairList: [],
      primaryList: [],
      selectedPrimary : [],
      directlyTradingCoins: [],
    }
  },
  created() {
    this.$root.$emit(this.$EVENT_LNB_CHANGE, 'marketPair', this.$options.name)

    this.getPrimaryList()
    this.getInitialPrimaryList()
    this.getDirectlyTradingCoins()
  },
  methods: {
    getPrimaryList() {
      this.$get(this.$MARKET_FILTER, this.$options.name + '_getPrimaryList', (result) => {
        this.marketPairList = result.data.marketPair
        this.currencyList = result.data.currency

        this.primaryList = []
        for(let i=0; i<this.currencyList.length; i++) {
          if(this.primaryList.lastIndexOf(this.currencyList[i].ticker) !== -1) {
            continue
          }
          this.primaryList.push(this.currencyList[i].ticker)
        }
      }, (result) => {
        this.httpError(result)
      })
    },
    getInitialPrimaryList() {
      this.$get(this.$MARKET_DISPLAY, this.$options.name + '_getInitialPrimaryList', (result) => {
        for(let i=0; i<result.data.length; i++) {
          this.selectedPrimary.push(result.data[i])
        }

        // this.primaryList = []
        // for(let i=0; i<this.marketPairList.length; i++) {
        //   if(this.primaryList.lastIndexOf(this.marketPairList[i].primary) !== -1) {
        //     continue
        //   }
        //   this.primaryList.push(this.marketPairList[i].primary)
        // }

      }, (result) => {
        this.httpError(result)
      })
    },
    setPrimary() {
      if (this.selectedPrimary === '') {
        this.notify('error', '부통화를 선택해주세요.', false)
        return
      }

      let formData = new FormData()
      this.selectedPrimary.map(v => formData.append('ticker', v))

      this.$post(this.$MARKET_DISPLAY, this.$options.name + '_setPrimary', formData, () => {
        this.notify('success', '부통화 저장에 성공했습니다.', false)
        this.getPrimaryList()
      }, (result) => {
        this.httpError(result)
      })
    },
    getDirectlyTradingCoins() {
      this.$get(this.$MARKET_DIRECTLY_TRADING_COINS, this.$options.name + '_getDirectlyTradingCoins', (result) => {
        this.directlyTradingCoins = result.data
      }, (result) => {
        this.httpError(result)
      })
    },
    setDirectlyTradingCoins() {
      if (this.directlyTradingCoins.trim() === '' || this.directlyTradingCoins.length <= 0) {
        this.notify('error', '코인을 입력하세요.', false)
        return
      }

      let formData = new FormData()
      this.directlyTradingCoins
        .split(',')
        .map(coin => coin ? formData.append('ticker', coin.toUpperCase().trim()) : '')

      this.$post(this.$MARKET_DIRECTLY_TRADING_COINS, this.$options.name + '_setDirectlyTradingCoins', formData, () => {
        this.notify('success', '코인 저장에 성공했습니다.', false)
        this.getDirectlyTradingCoins()
      }, (result) => {
        this.httpError(result)
      })
    }

  },
  watch: {
    '$route.query.page'() {
      this.getList(this.$route.query.page)
    },
  }
}
</script>

<style>
</style>
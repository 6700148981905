<template>
  <div class="m-3">
    <div class="card-header border-bottom-0">
      <h5 class="card-title mr-3 mt-2">거래 신용도</h5>
    </div><!-- /.card-header -->
    <div class="row align-items-center">
      <table class="table table-bordered text-center">
        <thead>
        <tr>
          <th colspan="2">거래시작건수</th>
          <th colspan="2">거래완료건수</th>
          <th colspan="2">취소거래건수</th>
          <th>거래신용도</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>매도</td>
          <td>매수</td>
          <td>매도</td>
          <td>매수</td>
          <td>매도</td>
          <td>매수</td>
          <td rowspan="2">{{ data.creditRating }} %</td>
        </tr>
        <tr>
          <td>{{ data.allSellCount }} 건</td>
          <td>{{ data.allBuyCount }} 건</td>
          <td>{{ data.completeSellCount }} 건</td>
          <td>{{ data.completeBuyCount }} 건</td>
          <td>{{ data.cancelSellCount }} 건</td>
          <td>{{ data.cancelBuyCount }} 건</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserDetailOtcCredit",
  props: [
    'userIdx',
  ],
  data() {
    return {
      data: {
        allBuyCount: '0',
        allSellCount: '0',
        cancelBuyCount: '0',
        cancelSellCount: '0',
        completeBuyCount: '0',
        completeSellCount: '0',
        creditRating: '0',
      },
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$get(this.$USER_CREDIT.replace('?', this.userIdx), this.$options.name + '_getData', (result) => {
        this.getFormatData(this.data, result)
      }, (result) => {
        this.httpError(result)
      })
    },
  }
}
</script>

<style scoped>

</style>

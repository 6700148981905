<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">클레임내역</h4>
            <span>데이터베이스에 기록된 클레임내역을 확인합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>클레임번호</strong>
                  </div>
                  <div class="col-sm-6">
                    <label type="text" class="form-control">{{ claimList.claimIdx }}</label>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>클레임 해결 여부</strong>
                  </div>
                  <div class="col-sm-6">
                    <label type="text" class="form-control">{{ claimList.isResolved === true ? '해결됨' : '처리중' }}</label>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>클레임 해결일</strong>
                  </div>
                  <div class="col-sm-6">
                    <label v-if="claimList.isResolved === true" type="text" class="form-control">{{ claimList.resolvedDate | moment('YYYY-MM-DD HH:mm:ss') }}</label>
                    <label v-else type="text" class="form-control">{{ `-` }}</label>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>클레임 내용</strong>
                  </div>
                  <div class="col-sm-6">
                    <textarea type="text" class="form-control" v-model="claimList.contents" rows="5" disabled></textarea>
                  </div>
                </div>
                <div v-if="claimList.imgUrl" class="row mb-3">
                  <div class="col-sm-3 text-right">
                    <strong>클레임 첨부파일</strong>
                  </div>
                  <div class="col-sm-6">
                    <img :src="claimList.imgUrl"/>
                  </div>
                </div>
              </div><!-- ./card-body -->
              <div class="card-footer d-flex justify-content-center">
                <div v-if="claimList.isResolved === false">
                  <button type="button" class="btn btn-success mr-1" @click="setAction(claimList.claimIdx, true)">강제 체결</button>
                  <button type="button" class="btn btn-danger mr-3" @click="setAction(claimList.claimIdx, false)">강제 취소</button>
                </div>
                <button type="button" class="btn btn-primary" @click="$router.back()">목록</button>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "OtcClaimDetail",
  data() {
    return {
      idx: 0,
      claimList: [],
    }
  },
  created() {
    this.idx = this.$route.params.idx
    this.getClaimDetail()
  },
  methods: {
    getClaimDetail() {
      this.$get(this.$OTC_CLAIM_DETAIL.replace('?', this.idx), this.$options.name + '_getClaimDetail', (result) => {
        this.claimList = result.data
        this.getClaimImg()
      }, (result) => {
        this.httpError(result)
      })
    },

    getClaimImg() {
      if(this.claimList.fileHash === null) {
        return
      }

      this.$download_get(`?key=${this.claimList.fileHash}`, this.$options.name + '_getClaimImg', (result) => {
        this.claimList.imgUrl = result
        this.$forceUpdate()
      }, (result) => {
        this.httpError(result)
      })
    },
    setAction(idx, isForceTrade) {
      let formData = new FormData()
      formData.append('claimIdx', idx)
      formData.append('isForceTrade', isForceTrade)

      this.$post(this.$OTC_CLAIM_ACTION, this.$options.name + '_setAction', formData,() => {
        this.notify('success', '클레임을 처리했습니다.', false)
        this.getClaimDetail()
      }, () => {
        this.notify('error', '클레임을 처리하지 못 했습니다.', false)
      })
    },


  },
}
</script>

<style>
.dark-mode .table td {
  word-break: break-all;
}

img {
  max-width: 200px;
}
</style>
